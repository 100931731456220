import React, { useState, useEffect } from "react";

import AmountDepositFrom from "./AmountDepositForm";

const AmountDepositView = () => {
    
    return (
        <div className="contents">
            <div className="amount">
                <AmountDepositFrom />
            </div>
        </div>
    )
}

export default AmountDepositView