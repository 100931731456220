import { useDispatch } from "react-redux";

import { showSpinner, hideSpinner, toggleSpinner } from "../redux/reducers/spinnerReducer";

const useSpinner = () => {
    const dispatch = useDispatch();

    const spinnerVisible = (flag: boolean = false) => {
        if(flag){
            dispatch(showSpinner());
        }else{
            dispatch(hideSpinner());
        }
    };

    const spinnerToggle = () => {
        dispatch(toggleSpinner());
    }

    return { spinnerVisible, spinnerToggle };
}

export default useSpinner;