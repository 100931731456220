import React, { useState, useEffect } from "react";
import { useFieldArray, Control, UseFormWatch, UseFieldArrayUpdate, UseFieldArrayReturn, FieldErrors } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    FormGroup,
    Button,
} from 'reactstrap';

import InputControl from '../common/InputControl';
import RadioControlBox from '../common/RadioArrayControlBox';
import useModal from "../../hooks/GlobalModals";
import ConfigAPI from "../../modules/ConfigAPI";

const InputPhones = (props: { control: Control<any>, controlUpdate: UseFieldArrayUpdate<any, any>, index: number, field: Record<'id', string>, dialinOptions: any[]}) => {
    const { control, controlUpdate, index, field, dialinOptions } = props;
    /*
    const value = useWatch({
        control,
        name: 'phones'
    });
    */

    const typeOptions = [
        {value: 'home', label: '집'},
        {value: 'cell', label: '휴대폰'},
        {value: 'work', label: '직장'},
        {value: 'fax', label: '팩스'},
        {value: 'private', label: '개인'},
        {value: 'public', label: '공용'},
        {value: 'other', label: '기타'}
    ]

    return (
        <Col>
            <RadioControlBox
                control={control}
                controlUpdate={controlUpdate}
                name={`phones.${index}.default`}
            >
                <Row>
                    <Col className="pe-1" md="3">
                        <InputControl 
                            control={control}
                            type="select"
                            name={`phones.${index}.type`}
                            options={typeOptions}
                        />
                    </Col>
                    <Col className="px-1" md="2">
                        <InputControl 
                            control={control}
                            type="select"
                            name={`phones.${index}.dialin`}
                            options={dialinOptions}
                        />
                    </Col>
                    <Col className="ps-1" md="7">
                        <InputControl 
                            control={control}
                            name={`phones.${index}.number`}
                            placeholder="번호"
                        />
                    </Col>
                </Row>
            </RadioControlBox>
        </Col>
    )
};

const PhonesForm = (props: { control: Control<any, any>, watch: UseFormWatch<any>, errors: FieldErrors<any>, phoneFields: UseFieldArrayReturn<any, "phones", "id"> }) => {
    let { control, watch, errors, phoneFields } = props;

    //const [errorLeastone, setErrorLeastone] = useState('');
    const [dialinOptions, setDialinOptions] = React.useState<{value: any, label: any}[]>([]);

    const { modalAlert } = useModal();

    let phonesDefaultValues = {
        default: false,
        type: 'cell',
        dialin: '82',
        number: ''
    };

    const watchPhoneFields = watch('phones');

    const fieldAppend = () => {
        if(watchPhoneFields.length <= 0)
            phonesDefaultValues = { ...phonesDefaultValues, default: true }

        if(watchPhoneFields.length < 5)
            phoneFields.append(phonesDefaultValues);
    }

    const fieldRemove = (index: number) => {                    
        phoneFields.remove(index);
    }

    useEffect(() => {
        console.log('watchPhoneFields', watchPhoneFields)
        /*
        if(watchPhoneFields.length <= 0)
            phoneFields.append(phonesDefaultValues);
        */
    }, [watchPhoneFields])

    useEffect(() => {
        ConfigAPI.getCountries().then(( res ) => {
            const { total, countries } = res.data.message;

            if(total > 0){
                let filterDialinOptions = [];

                for(var country of countries){
                    filterDialinOptions.push({
                        value: country.dialin,
                        label: '+' + country.dialin
                    })
                }

                setDialinOptions(filterDialinOptions);
            }else{
                modalAlert({
                    component: <>Unable to Initialize Application.</>,
                    title: 'Warning'
                });
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: 'Accounts'
            });
        });
    }, []);

    /*
    useEffect(() => {
        if(
            errors && 
            errors.phones && 
            Array.isArray(errors.phones) && 
            errors.phones[0].number && 
            errors.phones[0].number.type == 'leastone'
        ){
            setErrorLeastone(errors.phones[0].number.message);
        }
    },  [errors])
    */

    return (
        <Card className="card-form">
            <CardHeader>
                <CardTitle tag="h5">연락처 설정</CardTitle>
            </CardHeader>
            <CardBody>
                {phoneFields.fields.map(( field, index ) => {
                    return(
                        <React.Fragment key={index}>
                            {/*
                                errorLeastone && 
                                <div className="form-feedback-custom invalid mb-2">
                                    {errorLeastone}
                                </div>
                            */}
                            <Row className="align-items-center mb-3">
                                <InputPhones key={field.id} control={control} controlUpdate={phoneFields.update} index={index} field={field} dialinOptions={dialinOptions} />
                                <Col className="ps-1" md="2">
                                    <Button className="d-block w-100 btn-round" onClick={() => { fieldRemove(index) }}>
                                        <i className="fa fa-times"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                })}

                {phoneFields.fields.length <= 0 &&
                    <Row>
                        <Col className="text-center py-5">
                            "연락처 추가" 버튼으로 연락처를 추가할 수 있습니다.
                        </Col>
                    </Row>
                }

                <Row>
                    <Col className="text-center py-2">
                        <Button
                            className="btn-round"
                            color="secondary"
                            type="button"
                            onClick={fieldAppend}
                        >
                            <i className="fa fa-plus"></i> 연락처 추가
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default PhonesForm