import { IAlertModalProps, IConfirmModalProps,/*, IFormModalProps*/ 
IFormModalProps} from "../../interfaces/GlobalModals";
import { TGlobalModals, TGlobalModalsEnum } from "../../types/GlobalModals"

const SET_MODAL = 'modal/SET_MODAL' as const;
const SET_ALERT = 'modal/SET_ALERT' as const;
const SET_CONFIRM = 'modal/SET_CONFIRM' as const;
const SET_FORM = 'modal/SET_FORM' as const;
const SHOW_MODAL = 'modal/SHOW_MODAL' as const;
const HIDE_MODAL = 'modal/HIDE_MODAL' as const;
const TOGGLE_MODAL = 'modal/TOGGLE_MODAL' as const;

export const setModal = (modal: TGlobalModals) => ({ type: SET_MODAL, modal });
export const setAlert = (modalVisible: boolean, modalProps: IAlertModalProps) => ({ type: SET_ALERT, modalVisible, modalType: TGlobalModalsEnum.ALERT, modalProps });
export const setConfirm = (modalVisible: boolean, modalProps: IConfirmModalProps) => ({ type: SET_CONFIRM, modalVisible,  modalType: TGlobalModalsEnum.CONFIRM, modalProps });
export const setForm = (modalVisible: boolean, modalProps: IFormModalProps) => ({ type: SET_FORM, modalVisible,  modalType: TGlobalModalsEnum.FORM, modalProps });
export const showModal = () => ({ type: SHOW_MODAL });
export const hideModal = () => ({ type: HIDE_MODAL });
export const toggleModal = () => ({ type: TOGGLE_MODAL });

type ModalAction =
    | ReturnType<typeof setModal>
    | ReturnType<typeof setAlert>
    | ReturnType<typeof setConfirm>
    | ReturnType<typeof setForm>
    | ReturnType<typeof showModal>
    | ReturnType<typeof hideModal>
    | ReturnType<typeof toggleModal>;

const defaultState: TGlobalModals = {
    modalVisible: false,
    modalType: TGlobalModalsEnum.ALERT,
    modalProps: {
        component: null
    }
}

const modalReducer = (state: TGlobalModals = defaultState, action: ModalAction): TGlobalModals => {
    switch(action.type){
        case SET_MODAL:
            return {
                ...state,
                ...action.modal
            };
        case SET_ALERT:
        case SET_CONFIRM:
        case SET_FORM:
            return {
                ...state,
                modalVisible: action.modalVisible,
                modalType: action.modalType,
                modalProps: action.modalProps
            };
        case SHOW_MODAL:
            return {
                ...state, 
                modalVisible: true
            };
        case HIDE_MODAL:
            return {
                ...state, 
                modalVisible: false
            };
        case TOGGLE_MODAL:
            return {
                ...state, 
                modalVisible: !state.modalVisible
            };
        default:
            return state;
    }
}

export default modalReducer;