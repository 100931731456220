import React, { useRef, useState, useEffect } from "react";

import { IInplayItemTimer, IInplayItemTimerProps } from "../../interfaces/Inplay";

import moment from "moment-timezone";

const extractTimer = (timer: IInplayItemTimer) => {
    let TIMER = '00:00';

    if(/^[0-9]{14}$/.test(timer.timer_updated)){
        var NOW_DATE = moment.utc();
        var NOW_DATE_TIME = NOW_DATE.unix();

        var TU_DATE = moment.utc(timer.timer_updated, 'YYYYMMDDHHmmss');
        var TU_DATE_TIME = TU_DATE.unix();

        var D = 0;
        var H = 0;
        var M = 0;
        var S = 0;

        if (Number(timer.timer_ticking) > 0) {  // when playing
            //console.log('playing');

            var DIFF_DATE_TIME = NOW_DATE_TIME - TU_DATE_TIME;

            if (timer.timer_countdown && Number(timer.timer_countdown) > 0)
                DIFF_DATE_TIME = DIFF_DATE_TIME * -1;

            var PassedSec = DIFF_DATE_TIME + (Number(timer.timer_minutes) * 60) + Number(timer.timer_seconds);
            var duration = moment.duration(PassedSec, 'seconds');

            D = duration.days();
            H = duration.hours();
            M = duration.minutes();

            if (!(timer.timer_countdown && Number(timer.timer_countdown) > 0))
                M = (M <= 0) ? 59 + M : 60 + M;
            
            S = duration.seconds();
            S = (S <= 0) ? 59 + S : S - 1;
        } else {
            //console.log('stop');

            var PassedSec = (Number(timer.timer_minutes) * 60) + Number(timer.timer_seconds);

            var duration = moment.duration(PassedSec, 'seconds');

            D = duration.days();
            H = duration.hours();
            M = duration.minutes();
            S = duration.seconds();

            M = (H * 60) + M;
        }

        //TIMER = H + ':' + ((M < 10) ? '0' + M : M) + ':' + ((S < 10) ? '0' + S : S);
        TIMER = ((M < 10) ? '0' + M : M) + ':' + ((S < 10) ? '0' + S : S);
    }

    return TIMER;
}

const InplayItemTimer = (props: IInplayItemTimerProps) => {
    const { timer } = props;

    return (
        <div className="timer">{timer.timer_quarter && <span>{'Q' + timer.timer_quarter}</span>} {extractTimer(timer)}</div>
    )
}

export default InplayItemTimer
