import React, { useState, useEffect } from "react";
import { Control, UseFormWatch, UseFieldArrayUpdate, UseFieldArrayReturn, FieldErrors } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    FormGroup,
    Button,
} from 'reactstrap';

import InputControl from '../common/InputControl';
import RadioArrayControlBox from '../common/RadioArrayControlBox';
import ConfigAPI from "../../modules/ConfigAPI";
import useModal from "../../hooks/GlobalModals";

const InputPassbooks = (props: { control: Control<any>, controlUpdate: UseFieldArrayUpdate<any, any>, index: number, field: Record<'id', string>, errors: FieldErrors<any>, bankOptions: {value: string, label: string}[]}) => {
    const { control, controlUpdate, index, field, errors, bankOptions } = props;

    const renderError = (fieldName: string, index:number, errors: FieldErrors<any>) => {
        if(
            errors && 
            Array.isArray(errors.passbooks) && 
            errors.passbooks[index][fieldName]
        ){
            return (
                <div className="form-feedback-custom invalid mb-2">
                    {errors.passbooks[index][fieldName].message}
                </div>
            )
        }
    }

    return (
        <Col>
            <RadioArrayControlBox
                control={control}
                controlUpdate={controlUpdate}
                name={`passbooks.${index}.default`}
            >
                <Row>
                    <Col className="pe-1">
                        <FormGroup>
                            <InputControl 
                                control={control}
                                type="select"
                                name={`passbooks.${index}.bankcode`}
                                options={bankOptions}
                            />
                            {renderError('bankcode', index, errors)}
                        </FormGroup>
                    </Col>
                    <Col className="ps-1">
                        <FormGroup>
                            <InputControl 
                                control={control}
                                name={`passbooks.${index}.holder`}
                                placeholder="예금주"
                            />
                            {renderError('postalcode', index, errors)}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputControl 
                            control={control}
                            name={`passbooks.${index}.number`}
                            placeholder="계좌번호"
                        />
                    </Col>
                </Row>
            </RadioArrayControlBox>
        </Col>
    )
};

const PassbooksForm = (props: { control: Control<any, any>, watch: UseFormWatch<any>, errors: FieldErrors<any>, passbookFields: UseFieldArrayReturn<any, "passbooks", "id"> }) => {
    let { control, watch, errors, passbookFields } = props;

    const { modalAlert } = useModal();

    //const [errorLeastone, setErrorLeastone] = useState('');
    const [ bankOptions, setBankOptions ] = useState<{value: string, label: string}[]>([]);

    let passbooksDefaultValues = {
        default: false,
        bankcode: '023',
        holder: '',
        number: ''
    };

    const watchPassbookFields = watch('passbooks');

    const fieldAppend = () => {
        if(watchPassbookFields.length <= 0)
            passbooksDefaultValues = { ...passbooksDefaultValues, default: true }

        if(watchPassbookFields.length < 5)
            passbookFields.append(passbooksDefaultValues);
    }

    const fieldRemove = (index: number) => {                    
        passbookFields.remove(index);
    }

    useEffect(() => {
        /*
        if(watchPassbookFields.length <= 0)
            passbookFields.append(passbooksDefaultValues);
        */
        ConfigAPI.getBanks().then((res) => {
            if(res.data.success){
                const { total, banks } = res.data.message;

                let filterBankOptions: {value: string, label: string}[] = [];

                for( const bank of banks ){
                    filterBankOptions.push({
                        value: bank.key,
                        label: (bank.name_kr) ? bank.name_kr : bank.name
                    })
                }

                setBankOptions(filterBankOptions);
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: 'Warning'
                });
            }
        }).catch((error) => {
            const res = error.response;

            if(res){
                modalAlert({
                    component: <>Unable to Initialize Application.</>,
                    title: 'Warning'
                });
            }
        });
    }, [watchPassbookFields])

    /*
    useEffect(() => {
        if(
            errors && 
            errors.passbooks && 
            Array.isArray(errors.passbooks) && 
            errors.passbooks[0].passbooks && 
            errors.passbooks[0].passbooks.type == 'leastone'
        ){
            setErrorLeastone(errors.passbooks[0].number.message);
        }
    },  [errors])
    */

    return (
        <Card className="card-form">
            <CardHeader>
                <CardTitle tag="h5">계좌 설정</CardTitle>
            </CardHeader>
            <CardBody>
                {passbookFields.fields.map(( field, index ) => {
                    return(
                        <React.Fragment key={index}>
                            {/*
                                errorLeastone && 
                                <div className="form-feedback-custom invalid mb-2">
                                    {errorLeastone}
                                </div>
                            */}
                            <Row className="align-items-center mb-3">
                                <InputPassbooks key={field.id} control={control} controlUpdate={passbookFields.update} index={index} field={field} errors={errors} bankOptions={bankOptions} />
                                <Col className="ps-1" md="2">
                                    <Button className="d-block w-100 btn-round" onClick={() => { fieldRemove(index) }}>
                                        <i className="fa fa-times"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                })}

                {passbookFields.fields.length <= 0 &&
                    <Row>
                        <Col className="text-center py-5">
                            "계좌 추가" 버튼으로 계좌를 추가할 수 있습니다.
                        </Col>
                    </Row>
                }

                <Row>
                    <Col className="text-center py-2">
                        <Button
                            className="btn-round"
                            color="secondary"
                            type="button"
                            onClick={fieldAppend}
                        >
                            <i className="fa fa-plus"></i> 계좌 추가
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default PassbooksForm