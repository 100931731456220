import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Label, Input } from 'reactstrap';
import useModal from "../../hooks/GlobalModals";

interface ISearchFormProps {
    setSearchForms: Dispatch<SetStateAction<any>>;
}

const LeaguesSearchForm = (props: ISearchFormProps) => {
    const { setSearchForms } = props;
    
    const { modalAlert } = useModal();

    const targetOptions = [
        { value: 'upcoming', label: '프리매치' },
        { value: 'inplay', label: '인플레이' }
    ]

    const amountTypeOptions = [
        { value: '', label: '전체' },
        { value: 'amount', label: '머니' },
        { value: 'bonus', label: '보너스' }
    ]

    const resultTypeOptions = [
        { value: '', label: '전체' },
        { value: 'wait', label: '대기' },
        { value: 'win', label: '적중' },
        { value: 'lose', label: '실패' },
        { value: 'cancel', label: '취소' },
        { value: 'except', label: '적특' },
        { value: 'error', label: '오류' }
    ]

    return (
        <div className="search-wrapper">
            <Card className="card-form-search">
                <CardBody>
                    <Row>
                        <Col md="2">
                            <Label for="target">타입</Label>
                            <Input
                                type="select"
                                name="target"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, target: e.target.value} })}
                            >
                                {targetOptions.map(( option, index ) => {
                                    return (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col md="2">
                            <Label for="target">결과</Label>
                            <Input
                                type="select"
                                name="result_type"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, result_type: e.target.value} })}
                            >
                                {resultTypeOptions.map(( option, index ) => {
                                    return (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col md="2">
                            <Label for="target">금액타입</Label>
                            <Input
                                type="select"
                                name="amount_type"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, amount_type: e.target.value} })}
                            >
                                {amountTypeOptions.map(( option, index ) => {
                                    return (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col md="6">
                        </Col>
                    </Row>
                    
                </CardBody>
            </Card>
        </div>
    )
}

export default LeaguesSearchForm