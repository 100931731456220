import React, { useRef, useState, useEffect, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button, Row, Col } from 'reactstrap';

import useModal from "../../hooks/GlobalModals";

import BetAPI from "../../modules/BetAPI";
import APISocket from "../../modules/APISocket";
import { useNavigate, useParams } from "react-router-dom";
import { IUpcomingItem, IUpcomingItemProps, ITargetUpcoming } from "../../interfaces/Upcoming";
import { setUpcoming } from "../../redux/reducers/betReducer";

const UpcomingItem = (props: IUpcomingItemProps) => {
    const { index, item } = props;

    const betState = useSelector((state: RootState) => state.betReducer);
    
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [ targetUpcoming, setTargetUpcoming ] = useState<ITargetUpcoming | null>(null);

    const default_image = `/images/${betState.targetSportUid}.svg`;

    useEffect(() => {
        setTargetUpcoming(item)
    }, [item]);

    const toggleItem = (event: MouseEvent) => event.currentTarget.parentElement!.classList.toggle('fold');

    const handleOnClick = (targetEvent: any) => {
        dispatch(setUpcoming({ 
            targetEventUid: targetEvent.uid,
            targetEvent 
        }));
    }

    return (
        <>
            {targetUpcoming != null && 
                <div className={`upcoming-item ${(index && index > 0) ? 'fold':''}`}>
                    <div className="name" onClick={toggleItem}>
                        <div>{targetUpcoming.league.name_kr ? targetUpcoming.league.name_kr : targetUpcoming.league.name}</div>
                        <div className="toggle">
                            <i className="fas fa-chevron-down"></i>
                            <i className="fas fa-chevron-up"></i>
                        </div>
                    </div>
                    <div className="events">
                        {targetUpcoming.events.map((targetEvent, key) => 
                            <Card className="card-upcoming" onClick={(event) => handleOnClick(targetEvent)} key={key}>
                                <CardBody>
                                    {/*<div>{targetEvent.uid} / {targetEvent.key}</div>*/}
                                    <div className="timer">{targetEvent.time}</div>
                                    <div className="players">
                                        <div className="player">
                                            <div className="team">
                                                <div className="image" style={{backgroundImage: `url('https://assets.open-888.com/images/team/m/${targetEvent.images[0]}.png'), url('${default_image}')`}}></div>
                                                <div className="name">{targetEvent.home}</div>
                                            </div>
                                            <div className="score">{targetEvent.score[0].home}</div>
                                        </div>
                                        <div className="vs">VS</div>
                                        <div className="player">
                                            <div className="score">{targetEvent.score[0].away}</div>
                                            <div className="team">
                                                <div className="image" style={{backgroundImage: `url('https://assets.open-888.com/images/team/m/${targetEvent.images[1]}.png'), url('${default_image}')`}}></div>
                                                <div className="name">{targetEvent.away}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="markets">+{targetEvent.markets}</div>
                                    {/*<div className="folder">
                                        <div className="odds left">
                                            <div className="name">{UpcomingItem.home}</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                        <div className="draw">VS</div>
                                        <div className="odds right">
                                            <div className="rate">0.00</div>
                                            <div className="name">{UpcomingItem.away}</div>
                                        </div>
                                    </div>
                                    <div className="folder type-col">
                                        <div className="hd">언오버</div>
                                        <div className="odds disable">
                                            <div className="name">Over 1.5</div>
                                            <div className="rate"><i className="fas fa-lock"></i></div>
                                        </div>
                                        <div className="odds">
                                            <div className="name">Under 1.5</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                    </div>
                                    <div className="folder type-col">
                                        <div className="hd">핸디캡</div>
                                        <div className="odds">
                                            <div className="name">Over 1.5</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                        <div className="odds">
                                            <div className="name">Under 1.5</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                    </div>
                                    <div className="folder type-col">
                                        <div className="odds">
                                            <div className="name">{UpcomingItem.home}</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                        <div className="odds">
                                            <div className="name">Draw</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                        <div className="odds">
                                            <div className="name">{UpcomingItem.away}</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                    </div>*/}
                                </CardBody>
                            </Card>
                        )}
                    </div>
                </div>
            }
        </>
    )
}

export default UpcomingItem
