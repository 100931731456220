import { IDefaultPopupProps} from "../../interfaces/GlobalPopups";
import { TGlobalPopup, TGlobalPopups, TGlobalPopupsEnum } from "../../types/GlobalPopups"

const SET_DEFAULT = 'popup/SET_DEFAULT' as const;
const SET_POPUP_ITEM = 'popup/SET_POPUP_ITEM' as const;
const SET_POPUP_ITEMS = 'popup/SET_POPUP_ITEMS' as const;
const SHOW_POPUP = 'popup/SHOW_POPUP' as const;
const HIDE_POPUP = 'popup/HIDE_POPUP' as const;

export const setDefault = (popupVisible: boolean, popupProps: IDefaultPopupProps) => ({ type: SET_DEFAULT, popupVisible, popupType: TGlobalPopupsEnum.DEFAULT, popupProps });
export const setPopupItem = (item: TGlobalPopup) => ({ type: SET_POPUP_ITEM, item });
export const setPopupItems = (items: TGlobalPopup[]) => ({ type: SET_POPUP_ITEMS, items });
export const showPopup = (index: number) => ({ type: SHOW_POPUP, index });
export const hidePopup = (index: number) => ({ type: HIDE_POPUP, index });

type PopupAction =
    | ReturnType<typeof setDefault>
    | ReturnType<typeof setPopupItem>
    | ReturnType<typeof setPopupItems>
    | ReturnType<typeof showPopup>
    | ReturnType<typeof hidePopup>

const defaultState: TGlobalPopups = {
    popupsVisible: false,
    items: [
        /*
        {
            popupVisible: false,
            popupType: TGlobalPopupsEnum.DEFAULT,
            popupProps: {
                component: null
            }
        }
        */
    ]
}

const popupReducer = (state: TGlobalPopups = defaultState, action: PopupAction): TGlobalPopups => {
    let targetItems = [ ...state.items ];
    let filterItems = [];

    switch(action.type){
        case SET_DEFAULT:
            targetItems.push({
                popupVisible: action.popupVisible,
                popupType: action.popupType,
                popupProps: action.popupProps
            })

            return {
                ...state,
                popupsVisible: targetItems.find((item) => item.popupVisible) ? true : false,
                items: targetItems
            };
        case SET_POPUP_ITEM:
            targetItems.push(action.item)

            return {
                ...state,
                items: targetItems
            };
        case SET_POPUP_ITEMS:
            return {
                ...state,
                items: action.items
            };
        case SHOW_POPUP:
            return {
                ...state, 
                items: state.items.map((item, index) => (index !== action.index) ? item : { ...item, popupVisible: true })
            };
        case HIDE_POPUP:
            filterItems = state.items.map((item, index) => (index !== action.index) ? item : { ...item, popupVisible: false })

            return {
                ...state, 
                popupsVisible: filterItems.find((item) => item.popupVisible) ? true : false,
                items: filterItems
            };
        default:
            return state;
    }
}

export default popupReducer;