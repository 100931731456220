import { TConfig } from "../../types/Config";

const SET_CONFIG = 'config/SET_CONFIG' as const;

export const setConfig = (config: TConfig) => ({ type: SET_CONFIG, config });

type ConfigAction =
    | ReturnType<typeof setConfig>;

const defaultState: TConfig = {
    siteName: ''
}

const configReducer = (state: TConfig = defaultState, action: ConfigAction): TConfig => {
    switch(action.type){
        case SET_CONFIG:
            return {
                ...state,
                ...action.config
            }
        default:
            return state;
    }
}

export default configReducer;