import { AxiosPromise } from "axios";
import API from "./API";

import { IParamPost } from "../interfaces/Post";

const BoardPostAPI = {
    get: (key_board: string, uid_post: string): AxiosPromise => {

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BOARD_URL + `/${key_board}/post/${uid_post}`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getPosts: (key_board: string, page: number = 1, limit: number = 10, keyword: string = ''): AxiosPromise => {
        let _query = (page <= 1) ? '' : `&page=${page}`;
        _query += (limit <= 1) ? '&limit=1' : `&limit=${limit}`;
        _query += (keyword) ? `&keyword=${keyword}` : '';

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_BOARD_URL + `/${key_board}/posts` + `?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    setPost: (key_board: string, params: IParamPost): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_BOARD_URL + `/${key_board}/post`,
            data: params
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default BoardPostAPI;