import React from "react";

import { TGlobalPopups, TGlobalPopupsEnum } from "../../../types/GlobalPopups";

import DefaultPopup from "./DefaultPopup";

const POPUP_COMPONENTS: any = {
    [TGlobalPopupsEnum.DEFAULT]: DefaultPopup
};

const GlobalPopup = ({ popupsVisible, items }: TGlobalPopups) => {
    const renderComponent = () => {
        if(popupsVisible && items.length > 0){
            return <>
                <div className="popup-items show fade">
                    {items.map((item, key) => {
                        const PopupComponent = POPUP_COMPONENTS[item.popupType];

                        return <PopupComponent key={key} index={key} popupVisible={item.popupVisible} {...item.popupProps} />;
                    })}
                </div>
                <div className="popup-backdrop fade show"></div>
            </>
        }else{
            return null;
        }
    };

    return <>{renderComponent()}</>;
};

export default GlobalPopup;