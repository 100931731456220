import { AxiosPromise } from "axios";
import API from "./API";

//import { ISIgn } from "../interfaces/SignX";

const SignXAPI = {
    sign: (mode: string, data: any): AxiosPromise => {
        const baseUrl = process.env.REACT_APP_API_SIGN_URL + `/${mode}`;

        return API({
            method: 'post',
            data,
            url: baseUrl,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    signOut: (): AxiosPromise => {
        const baseUrl = process.env.REACT_APP_API_SIGN_URL + '/out';

        return API({
            method: 'post',
            url: baseUrl,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }
}

export default SignXAPI;