import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";

import { IInplayItemScoreProps } from "../../interfaces/Inplay";


const InplayItemPlayers = (props: IInplayItemScoreProps) => {
    const { home, away, scores, images } = props;

    const betState = useSelector((state: RootState) => state.betReducer);
    
    const default_image = `/images/${betState.targetSportUid}.svg`;

    return (
        <>
        {/*<div className="score">
            <div className="image home" style={{backgroundImage: `url('https://assets.open-888.com/images/team/m/${images[0]}.png')`}}></div>
            <div className="home">{score[0].home}</div>
            <div className="vs">VS</div>
            <div className="away">{score[0].away}</div>
            <div className="image away" style={{backgroundImage: `url('https://assets.open-888.com/images/team/m/${images[1]}.png')`}}></div>
        </div>*/}
        <div className="players">
            <div className="player">
                <div className="team">
                    <div className="image" style={{backgroundImage: `url('https://assets.open-888.com/images/team/m/${images[0]}.png'), url('${default_image}')`}}></div>
                    <div className="name">{home}</div>
                </div>
                <div className="score">{scores[0].home}</div>
            </div>
            <div className="vs">VS</div>
            <div className="player">
                <div className="score">{scores[0].away}</div>
                <div className="team">
                    <div className="image" style={{backgroundImage: `url('https://assets.open-888.com/images/team/m/${images[1]}.png'), url('${default_image}')`}}></div>
                    <div className="name">{away}</div>
                </div>
            </div>
        </div>
        </>
    )
}

export default InplayItemPlayers
