import React, { useState } from 'react';
import { Alert } from 'reactstrap';

import routes from '../../routes';

import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import BetSlip from './BetSlip';

import { ILayoutProps } from '../../interfaces/Layout';

const Client = (layoutProps: ILayoutProps) => {
    
    const [backgroundColor, setBackgroundColor] = useState('black');
    const [activeColor, setActiveColor] = useState("primary");

    return (
        <>
            <Header routes={routes} />
            <div className="panel">
                <Sidebar
                    routes={routes}
                />
                <div className="contents">
                    {layoutProps.element}

                    <Footer />
                </div>
                <BetSlip />
            </div>
        </>
    );
};

export default Client