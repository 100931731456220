//import { pathToRegexp } from 'path-to-regexp';
import { IMultiRoute } from './interfaces/MultiRoutes';

import SignXLayout from './components/layouts/SignX';
import DashboardLayout from './components/layouts/Dashboard';
import ClientLayout from './components/layouts/Client';

import Sign from './components/sign/SignView';
import Main from './components/main/MainView';
import Account from './components/account/AccountView';
import AmountDeposit from './components/amount/AmountDepositView';
import AmountWithdraw from './components/amount/AmountWithdrawView';
import Inplay from './components/inplay/InplayView';
import Upcoming from './components/upcoming/UpcomingView';
import Bettings from './components/bettings/BttingsView';
import Amounts from './components/amount/AmountsView';
import Memos from './components/memos/MemosView';
import Posts from './components/posts/PostsView';
import Post from './components/posts/PostView';


const routes: IMultiRoute[] = [
    {
        path: '/signx',
        access: 'public',
        name: 'SignX',
        icon: '',
        layout: SignXLayout,
        layoutName: 'SignX',
        layoutPath: '',
        component: <Sign />
    },
    {
        path: '/',
        access: 'public',
        name: 'Main',
        icon: 'fa fa-home',
        layout: ClientLayout,
        layoutName: 'Client',
        layoutPath: '',
        component: <Main />
    },
    {
        path: '/board',
        access: 'public',
        name: '게시판',
        icon: 'fa fa-home',
        layout: ClientLayout,
        layoutName: 'Client',
        layoutPath: '',
        component: <Posts />,
        subRoutes: [
            {
                path: '/board',
                access: 'public',
                name: '게시판',
                icon: '',
                layout: ClientLayout,
                layoutName: 'Client',
                layoutPath: '',
                component: <Posts />,
                childRoutes: [
                    {
                        path: ':key_board',
                        name: '게시글 목록',
                        component: undefined
                    }
                ],
            },
            {
                path: '/board',
                access: 'public',
                name: '게시글',
                icon: '',
                layout: ClientLayout,
                layoutName: 'Client',
                layoutPath: '',
                component: <Post />,
                childRoutes: [
                    {
                        path: ':key_board/post/:uid_post',
                        name: '게시글 보기',
                        component: undefined
                    }
                ],
            }
        ]
    },
    {
        path: '/account/:uuid',
        access: 'pivate',
        name: '정보수정',
        icon: 'fa fa-user',
        layout: ClientLayout,
        layoutName: 'Client',
        layoutPath: '',
        component: <Account />
    },
    {
        path: '/amount/deposit',
        access: 'pivate',
        name: '입금신청',
        icon: 'fa fa-user',
        layout: ClientLayout,
        layoutName: 'Client',
        layoutPath: '',
        component: <AmountDeposit />
    },
    {
        path: '/amount/withdraw',
        access: 'pivate',
        name: '출금신청',
        icon: 'fa fa-user',
        layout: ClientLayout,
        layoutName: 'Client',
        layoutPath: '',
        component: <AmountWithdraw />
    },
    {
        path: '/amounts',
        access: 'pivate',
        name: '입출금내역',
        icon: 'fa fa-user',
        layout: ClientLayout,
        layoutName: 'Client',
        layoutPath: '',
        component: <Amounts />
    },
    {
        path: '/memos',
        access: 'pivate',
        name: '쪽지목록',
        icon: 'fa fa-user',
        layout: ClientLayout,
        layoutName: 'Client',
        layoutPath: '',
        component: <Memos />
    },
    {
        path: '/bettings',
        access: 'pivate',
        name: '베팅내역',
        icon: 'fa fa-user',
        layout: ClientLayout,
        layoutName: 'Client',
        layoutPath: '',
        component: <Bettings />
    },
    {
        path: '/upcoming',
        access: 'public',
        name: 'Upcoming',
        icon: 'fa fa-user',
        layout: ClientLayout,
        layoutName: 'Client',
        layoutPath: '',
        component: <Upcoming />,
        subRoutes: [
            {
                path: '/upcoming',
                access: 'public',
                name: 'Upcoming',
                icon: '',
                layout: ClientLayout,
                layoutName: 'Client',
                layoutPath: '',
                component: <Upcoming />
            }
        ]
    },
    {
        path: '/inplay',
        access: 'public',
        name: 'Inplay',
        icon: 'fa fa-user',
        layout: ClientLayout,
        layoutName: 'Client',
        layoutPath: '',
        component: <Inplay />,
        subRoutes: [
            {
                path: '/inplay',
                access: 'public',
                name: 'Inplay',
                icon: '',
                layout: ClientLayout,
                layoutName: 'Client',
                layoutPath: '',
                component: <Inplay />,
                childRoutes: [
                    {
                        path: ':uid',
                        name: 'Inplay Event',
                        component: undefined
                    }
                ],
            }
        ]
    }
];

export default routes
