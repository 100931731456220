import React from "react";
import { Spinner } from "reactstrap";
import { IGlobalSpinnerProps } from "../../interfaces/GlobalSpinner";

const GlobalSpinner = ({ visible }: IGlobalSpinnerProps) => {
    const renderComponent = () => {
        if(visible){
            return (
                <div className="wrapper-spinner">
                    <Spinner type="grow" style={{ width: '3rem', height: '3rem' }} />
                </div>
            )
        }else{
            return (
                <></>
            )
        }
    };

    return <>{renderComponent()}</>;
};

export default GlobalSpinner;