import { TAuth } from "../../types/Auth";

const SET_AUTH = 'auth/SET_AUTH' as const;

export const setAuth = (auth: TAuth) => ({ type: SET_AUTH, auth });

type AuthAction =
    | ReturnType<typeof setAuth>;

const defaultState: TAuth = {
    modifire: 'PUBLIC',
    uuid: '',
    ruid: '',
    userid: '',
    name: '',
    amount: 0,
    amount_bonus: 0,
    memo_new: 0,
    expires: 0
}

const authReducer = (state: TAuth = defaultState, action: AuthAction): TAuth => {
    switch(action.type){
        case SET_AUTH:
            return {
                ...state,
                ...action.auth
            }
        default:
            return state;
    }
}

export default authReducer;