import React, { MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";

import { RootState } from "../../redux/reducers/rootReducer";
import { resetCart } from "../../redux/reducers/betCartReducer";
import { setInplay } from "../../redux/reducers/betReducer";

import InplayItem from "./InplayItem";
import InplayMarketsItem from "./InplayMarketsItem";
import InplayItemTimer from "./InplayItemTimer";
import InplayItemGrounds from "./InplayItemGrounds";
import InplayItemPlayers from "./InplayItemPlayers";

import { IInplayItem, ITargetInplay } from "../../interfaces/Inplay";

import APISocket from "../../modules/APISocket";
import useModal from "../../hooks/GlobalModals";

const Inplay = () => {
    const authState = useSelector((state: RootState) => state.authReducer);
    const configState = useSelector((state: RootState) => state.configReducer);
    const betState = useSelector((state: RootState) => state.betReducer);

    const navigate = useNavigate();

    const { modalAlert } = useModal();

    const dispatch = useDispatch();

    const [ isLoadingEvents, setIsLoadingEvents ] = useState<boolean>(false);
    const [ isLoadingMarkets, setIsLoadingMarkets ] = useState<boolean>(false);

    const [ inplays, setInplays ] = useState<{ [key: string]: ITargetInplay }>({});
    const [ targetInplays, setTargetInplays ] = useState<ITargetInplay[]>([]);
    const [ targetEventUid, setTargetEventUid ] = useState<string>('');
    const [ targetEvent, setTargetEvent ] = useState<any>(null);
    const [ targetMarkets, setTargetMarkets ] = useState<any[]>([]);

    /*
    useEffect(() => {
        console.log('betState 1', betState)
    }, [betState])
    */
    
    useEffect(() => {
        if(!authState.uuid && configState['inplay_bet_used_signin']){
            modalAlert({
                component: <>회원만 이용 가능합니다.</>,
                title: '알림'
            });

            navigate('/', {replace: true});
        }

        setIsLoadingEvents(true);
        setIsLoadingMarkets(true);

        const targetInplaysSocket = APISocket.getIo(process.env.REACT_APP_API_SOCKET_URL + '/inplay');

        targetInplaysSocket.connect();

        targetInplaysSocket.on('connect', () => {
            console.log('Connected Inplays Socket.');

            targetInplaysSocket.emit('/request', { type: 'inplay' });
        });

        targetInplaysSocket.on('message', (response) => {
            if(betState.targetSportUid){
                switch(response.type){
                    case 'inplay':
                        const inplayData = response.data;

                        if(inplayData){
                            const sportKeys = Object.keys(inplayData);

                            if(sportKeys.length <= 0){
                                // Nothing Data
                            }else{
                                let tempInplays: { [key: string]: ITargetInplay } = {};
                                let tempLeagueOrder = 0;

                                for( const sportKey of sportKeys ){
                                    if(sportKey == betState.targetSportUid){
                                        for( const inplayKey in inplayData[sportKey] ){
                                            //if(forCount >= 50) break;

                                            const inplay = inplayData[sportKey][inplayKey];

                                            const home = (inplay.team_home.name_kr) ? inplay.team_home.name_kr : inplay.team_home.name;
                                            const away = (inplay.team_away.name_kr) ? inplay.team_away.name_kr : inplay.team_away.name;

                                            const { order: targetSportOrder, ...targetSport } = inplay.sport;
                                            const { order: targetLeagueOrder, ...targetLeague } = inplay.league;

                                            const filterEvent = {
                                                uid: inplay.uid,
                                                key: inplay.key,
                                                key_fi: inplay.key_fi,
                                                sport: inplay.sport,
                                                league: inplay.league,
                                                timer: {
                                                    timer_countdown: inplay.timer_countdown,
                                                    timer_updated: inplay.timer_updated,
                                                    timer_ticking: inplay.timer_ticking,
                                                    timer_minutes: inplay.timer_minutes,
                                                    timer_seconds: inplay.timer_seconds,
                                                    timer_quarter: inplay.quarter,
                                                },
                                                scores: inplay.scores,
                                                home, 
                                                away,
                                                images: [ inplay.team_home.image_id, inplay.team_away.image_id ],
                                                inings: inplay.inings,
                                                grounds: inplay.grounds,
                                                markets: inplay.markets,
                                                order: inplay.order,
                                                status: inplay.status
                                            }

                                            if(inplay.status > 1)
                                                console.log(filterEvent);
                                            
                                            if(!tempInplays[targetLeague.uid]){
                                                tempInplays[targetLeague.uid] = { 
                                                    sport: targetSport,
                                                    league: targetLeague, 
                                                    order: tempLeagueOrder, //targetLeagueOrder,
                                                    events: [] 
                                                };

                                                tempLeagueOrder++;
                                            }

                                            //tempInplays[targetLeague.uid].events[inplay.order] = { ...filterEvent };
                                            tempInplays[targetLeague.uid].events.push({ ...filterEvent });
                                        }

                                        break;
                                    }
                                }

                                console.log('tempInplays', tempInplays)

                                setInplays(() => ({ ...tempInplays }));

                                setIsLoadingEvents(false);
                            }
                        }
                        
                        break;
                    case 'error':
                        console.error(response.message);
                        break;
                }
            }
        });

        targetInplaysSocket.on('disconnect', () => {
            console.log('Disonnected Inplays Socket.')
        });

        targetInplaysSocket.on('connect_error', (error) => {
            console.log(error.message);
        })

        return() => {
            targetInplaysSocket.disconnect();

            setTargetInplays([]);

            setTargetEventUid('');
            setTargetEvent(null);
            setTargetMarkets([]);

            dispatch(resetCart());

            dispatch(setInplay({
                targetType: '',
                targetEventUid: '',
                targetEvent: {}
            }));
        }
    }, [configState, betState.targetSportUid]);

    useEffect(() => {
        if(!betState.targetSportUid || Object.keys(inplays).length <= 0){
            // Nothing
            setIsLoadingMarkets(false);
        }else{
            let filterInplays: ITargetInplay[] = [];

            Object.keys(inplays).map((targetInplayKey, index) => {
                const targetInplay = inplays[targetInplayKey];

                if(targetInplay && targetInplay.events.length > 0 && targetInplay.sport.uid === betState.targetSportUid){
                    if(!targetEventUid && targetInplay.order == 0){ 
                        setTargetEventUid(targetInplay.events[0].uid);
                        setTargetEvent({ ...targetInplay.events[0] });

                        dispatch(setInplay({
                            targetType: 'inplay',
                            targetEventUid: targetInplay.events[0].uid,
                            targetEvent: targetInplay.events[0]
                        }))
                    }

                    if(!filterInplays[targetInplay.order])
                        filterInplays[targetInplay.order] = { ...targetInplay };
                }
            });

            setTargetInplays([ ...filterInplays ]);
        }
    }, [inplays]);

    useEffect(() => {
        setIsLoadingMarkets(true);

        const targetEventSocket = APISocket.getIo(process.env.REACT_APP_API_SOCKET_URL + '/inplay-event');

        if(betState.targetType != 'inplay' || !betState.targetEventUid || !betState.targetEventUid){
            // Nothing
        }else{
            let existFlag = false;

            Object.keys(inplays).map((targetInplayKey, index) => {
                const targetInplay = inplays[targetInplayKey];

                if(!existFlag && targetInplay && targetInplay.events.length > 0){
                    const targetEvent = targetInplay.events.find((tempEvent) => tempEvent && tempEvent.uid == betState.targetEventUid);

                    if(targetEvent){
                        existFlag = true;

                        setTargetEvent({ ...targetEvent });
                    }
                }
            });

            if(!existFlag){
                // Nothing
            }else{
                targetEventSocket.connect();

                targetEventSocket.on('connect', () => {
                    console.log('Connected Event Socket.');

                    if(!betState.targetEventUid){
                        console.error('Invalid Request.');

                        targetEventSocket.disconnect();
                    }else{
                        targetEventSocket.emit('message', { type: 'inplay-event', uid: betState.targetEventUid, uid_sport: betState.targetSportUid });
                    }
                });

                targetEventSocket.on('message', (response) => {
                    switch(response.type){
                        case '':
                        case 'Initialize':
                        case 'Prepare':
                            break;
                        case 'Run':
    
                            //spinnerVisible(false);
    
                            const inplayEventData = response.data;
    
                            if(inplayEventData){
                                const { info, marketGroups, markets, columns, odds } = inplayEventData;
    
                                let filterEvent: IInplayItem | null = null;
    
                                let home = '';
                                let away = '';
    
                                if(info){
                                    home = (info.team_home.name_kr) ? info.team_home.name_kr : info.team_home.name;
                                    away = (info.team_away.name_kr) ? info.team_away.name_kr : info.team_away.name;
    
                                    filterEvent = {
                                        uid: info.uid,
                                        key: info.key,
                                        key_fi: info.key_fi,
                                        sport: info.sport,
                                        league: info.league,
                                        timer: {
                                            timer_countdown: info.timer_countdown,
                                            timer_updated: info.timer_updated,
                                            timer_ticking: info.timer_ticking,
                                            timer_minutes: info.timer_minutes,
                                            timer_seconds: info.timer_seconds,
                                            timer_quarter: info.quarter
                                        },
                                        scores: info.scores,
                                        home, 
                                        away,
                                        images: [ info.team_home.image_id, info.team_away.image_id ],
                                        inings: info.inings,
                                        grounds: info.grounds,
                                        //order: info.order
                                        status: info.status
                                    };
                                }
    
                                setTargetEvent(() => ({ ...filterEvent }));
    
                                //let filterEvent: IInplayEventItem[] = [];
                                let filterMarkets: any = [];
    
                                for( const marketGroupKey in marketGroups ){
                                    let targetMarketGroup = marketGroups[marketGroupKey];

                                    targetMarketGroup.name = targetMarketGroup.name.replaceAll('[:home]', home);
                                    targetMarketGroup.name = targetMarketGroup.name.replaceAll('[:away]', away);
    
                                    if(targetMarketGroup.name_kr){
                                        targetMarketGroup.name_kr = targetMarketGroup.name_kr.replaceAll('[:home]', home);
                                        targetMarketGroup.name_kr = targetMarketGroup.name_kr.replaceAll('[:away]', away);
                                    }

                                    if(targetMarketGroup.result_text){
                                        const resultTextMatches = targetMarketGroup.result_text.matchAll(/\[:([^,]+)\]/g);
                                        const resultTextArray = Array.from(resultTextMatches, (match: string[]) => match[1]);

                                        targetMarketGroup.result_text = resultTextArray.join(',');
                                    }
    
                                    if(!filterMarkets[targetMarketGroup.order])
                                        filterMarkets[targetMarketGroup.order] = { ...targetMarketGroup, markets: [] };
    
                                    for( const marketKey in markets ){
                                        let targetMarket = markets[marketKey];
    
                                        if(marketGroupKey != targetMarket.uid_market_group){
                                            continue;
                                        }else{
                                            if(targetMarket.name){
                                                targetMarket.name = targetMarket.name.replaceAll('[:home]', home);
                                                targetMarket.name = targetMarket.name.replaceAll('[:away]', away);
                                            }
            
                                            if(targetMarket.name_kr){
                                                targetMarket.name_kr = targetMarket.name_kr.replaceAll('[:home]', home);
                                                targetMarket.name_kr = targetMarket.name_kr.replaceAll('[:away]', away);
                                            }

                                            if(!filterMarkets[targetMarketGroup.order].markets[targetMarket.order])
                                                filterMarkets[targetMarketGroup.order].markets[targetMarket.order] = { ...targetMarket, columns: [], odds: [] };
    
                                            let columnsForCount = 0;
                                            let oddsForCount = 0;
    
                                            for( const columnKey in columns ){
                                                let targetColumn = columns[columnKey];
    
                                                if(marketKey != targetColumn.uid_market){
                                                    continue;
                                                }else{
                                                    if(!filterMarkets[targetMarketGroup.order].markets[targetMarket.order].columns[targetColumn.order]){
                                                        filterMarkets[targetMarketGroup.order].markets[targetMarket.order].columns[targetColumn.order] = { ...targetColumn, odds: [] };
    
                                                        columnsForCount++;
                                                    }
    
                                                    for( const oddsKey in odds ){
                                                        let targetOdds = odds[oddsKey];
    
                                                        if(targetOdds.name){
                                                            targetOdds.name = targetOdds.name.replaceAll('[:home]', home);
                                                            targetOdds.name = targetOdds.name.replaceAll('[:away]', away);
                                                        }

                                                        if(targetOdds.name_kr){
                                                            targetOdds.name_kr = targetOdds.name_kr.replaceAll('[:home]', home);
                                                            targetOdds.name_kr = targetOdds.name_kr.replaceAll('[:away]', away);
                                                        }
    
                                                        if(columnKey != targetOdds.uid_column){
                                                            continue;
                                                        }else{
                                                            filterMarkets[targetMarketGroup.order].markets[targetMarket.order].columns[targetColumn.order].odds[targetOdds.order] = targetOdds;
    
                                                            oddsForCount++;
                                                        }
                                                    }
                                                }
                                            }
    
                                            if(columnsForCount <= 0){
                                                for( const oddsKey in odds ){
                                                    let targetOdds = odds[oddsKey];
    
                                                    if(targetOdds.name){
                                                        targetOdds.name = targetOdds.name.replaceAll('[:home]', home);
                                                        targetOdds.name = targetOdds.name.replaceAll('[:away]', away);
                                                    }

                                                    if(targetOdds.name_kr){
                                                        targetOdds.name_kr = targetOdds.name_kr.replaceAll('[:home]', home);
                                                        targetOdds.name_kr = targetOdds.name_kr.replaceAll('[:away]', away);
                                                    }
    
                                                    if(marketKey != targetOdds.uid_market){
                                                        continue;
                                                    }else{
                                                        filterMarkets[targetMarketGroup.order].markets[targetMarket.order].odds[targetOdds.order] = targetOdds;
    
                                                        oddsForCount++;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
    
                                setTargetMarkets(() => [ ...filterMarkets ]);
    
                                setIsLoadingMarkets(false);
                            }
                            
                            break;
                        case 'Finish':
                            console.log('The match is end.');
                            break;
                        case 'Error':
                            console.error(response.message);
                            break;
                        default:
                            console.log(response.type, response.message)
                    }

                    setIsLoadingMarkets(false);
                });
    
                targetEventSocket.on('disconnect', () => {
                    console.log('Disonnected Event Socket.');
                });
    
                targetEventSocket.on('connect_error', (error) => {
                    console.log(error.message);
                })
            }
        }

        return() => {
            //console.log('Inplay Event Unmount');
            targetEventSocket.disconnect();
        }
    }, [betState.targetEventUid])

    return (
        <div className="inplays">
            <div className="title">
                <div>인플레이</div>
            </div>
            <div>
                <div className="inplay-items">
                    {isLoadingEvents && 
                        <div className="inplay-spinner">
                            <div>
                                <Spinner type="grow" style={{ width: '3rem', height: '3rem' }} />
                                <div className="guide">경기를 불러오는 중입니다...</div>
                            </div>
                        </div>
                    }

                    {targetInplays.map(( targetInplay, key ) => {
                        if(targetInplay)
                            return <InplayItem key={key} item={targetInplay} />
                    })}

                    {(!isLoadingEvents && targetInplays.length <= 0) && 
                        <div className="inplay-empty">
                            <div>
                                <div className="guide">이용 가능한 경기가 없습니다.</div>
                            </div>
                        </div>
                    }
                </div>
                
                <div className="inplay-markets">
                    {isLoadingMarkets && 
                        <div className="inplay-spinner">
                            <div>
                                <Spinner type="grow" style={{ width: '3rem', height: '3rem' }} />
                                <div className="guide">마켓을 불러오는 중입니다...</div>
                            </div>
                        </div>
                    }

                    {!isLoadingMarkets &&
                        <>
                            {(targetEvent && targetMarkets.length > 0) && 
                                <div className="inplay-video">
                                    {/*<div>{targetEvent.uid} / {targetEvent.key} / {targetEvent.status}</div>*/}
                                    {targetEvent.sport.name == 'Baseball' 
                                        ? 
                                            <InplayItemGrounds inings={targetEvent.inings} grounds={targetEvent.grounds}/>
                                        :
                                            <InplayItemTimer timer={targetEvent.timer} />
                                    }
                                    <InplayItemPlayers home={targetEvent.home} away={targetEvent.away} scores={targetEvent.scores} images={targetEvent.images} />
                                </div>
                            }
                            
                            {targetMarkets.map((targetMarketsItem, key) => 
                                <InplayMarketsItem key={key} item={targetMarketsItem} />
                            )}

                            {targetMarkets.length <= 0 && 
                                <div className="inplay-empty">
                                    <div>
                                        <div className="guide">이용 가능한 마켓이 없습니다.</div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Inplay