import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import {
    Alert
} from 'reactstrap';

import { hideNotify } from "../../../redux/reducers/notifyReducer";

const DefaultNotify = ({ index, notifyVisible, idx, component, handleDismiss }: any) => {

    const dispatch = useDispatch();

    const handleHideNotify = (index: number) => dispatch(hideNotify(index));

    const onDismiss = async (index: number) => {
        if (handleDismiss) {
            await handleDismiss();
        }

        handleHideNotify(index);
    };

    return (
        <Alert className="shadow-lg" isOpen={notifyVisible} toggle={(event) => onDismiss(index)} color="primary">
            <i className="fas fa-bell"></i> {component}
        </Alert>
    );
};

export default DefaultNotify;
