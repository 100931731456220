import React from "react";

import { TGlobalNotifies, TGlobalNotifiesEnum } from "../../../types/GlobalNotifies";

import DefaultNotify from "./DefaultNotify";

const NOTIFY_COMPONENTS: any = {
    [TGlobalNotifiesEnum.DEFAULT]: DefaultNotify
};

const GlobalNotifies = ({ notifiesVisible, items }: TGlobalNotifies) => {
    const renderComponent = () => {
        if(notifiesVisible && items.length > 0){
            return <>
                <div className={`notify-items fade show`}>
                    {items.map((item, key) => {
                        const NotifyComponent = NOTIFY_COMPONENTS[item.notifyType];

                        return <NotifyComponent key={key} index={key} notifyVisible={item.notifyVisible} {...item.notifyProps} />;
                    })}
                </div>
            </>
        }else{
            return null;
        }
    };

    return <>{renderComponent()}</>;
};

export default GlobalNotifies;