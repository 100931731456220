import React from 'react';
import { Routes, Route } from "react-router-dom";

import routes from '../../routes';

import Footer from './Footer';

import { ILayoutProps } from '../../interfaces/Layout';

const SignX = (layoutProps: ILayoutProps) => {
    return (
        <div className="panel-full">
            {layoutProps.element}
            <Footer />
        </div>
    )
};

export default SignX