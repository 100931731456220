import { useDispatch } from "react-redux";

import { setAlert, setConfirm, setForm } from "../redux/reducers/modalReducer";

import { IAlertModalProps, IConfirmModalProps, IFormModalProps } from "../interfaces/GlobalModals";

const useModal = () => {
    const dispatch = useDispatch();

    const modalAlert = (props: IAlertModalProps) => {
        dispatch(setAlert(true, props));
    };

    const modalConfirm = (props: IConfirmModalProps) => {
        dispatch(setConfirm(true, props));
    };

    const modalForm = (props: IFormModalProps) => {
        dispatch(setForm(true, props));
    }

    return { modalAlert, modalConfirm, modalForm };
}

export default useModal;