import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from '../../redux/reducers/rootReducer';
import moment from "moment";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    Button
} from 'reactstrap';


import useModal from "../../hooks/GlobalModals";
import useSpinner from "../../hooks/GlobalSpinner";

import BetAPI_V2 from "../../modules/BetAPI.v2";
import Util from "../../modules/Util";

import SearchForm from './SearchForm';
import Pagination from "../common/Pagination";
import AmountsAPI from "../../modules/AmountsAPI";
import AmountAPI from "../../modules/AmountAPI";

const AmountsForm = () => {
    const navigate = useNavigate();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm } = useModal();
    const { spinnerVisible } = useSpinner();

    const [ data, setData ] = useState<{total: number, amounts: any[]}>({
        total: 0,
        amounts: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const [ { type, category, keyword }, setSearchForms ] = useState({
        //method: '',
        type: '',
        category: '',
        keyword: ''
    });

    useEffect(() => {
        spinnerVisible(true);

        AmountsAPI.get(page, limit, type, category, keyword).then((res) => {
            if(res.data.success){
                const data = res.data.message;

                console.log(res.data.message)

                setData(data);
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '입출금내역'
                });
            }

            spinnerVisible(false);
        }).catch(( error ) => {
            spinnerVisible(false);

            modalAlert({
                component: <>{error.message}</>,
                title: '입출금내역'
            });
        });
    }, [page, limit]);

    useEffect(() => {
        if(page != 1) setPage(1);

        const delay = setTimeout(() => {
            spinnerVisible(true);

            AmountsAPI.get(1, limit, type, category, keyword).then((res) => {
                if(res.data.success){
                    const data = res.data.message;
    
                    setData(data);
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '입출금내역'
                    });
                }
    
                spinnerVisible(false);
            }).catch(( error ) => {
                spinnerVisible(false);
    
                modalAlert({
                    component: <>{error.message}</>,
                    title: '입출금내역'
                });
            });
        }, 300);

        return () => clearTimeout(delay)
    }, [type, category, keyword]);

    const handleSetStatus = (idx: number, status: string) => {
        AmountAPI.setStatus({ idx, status }).then(( res ) => {
            if(res.data.success){
                modalAlert({
                    component: <>처리가 완료되었습니다.</>,
                    title: '입출금내역'
                });

                navigate(0);
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '입출금내역'
                });
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '입출금내역'
            });
        });
    }
    
    return (
        <div className="amounts-form">
            <div className="title">
                <div>입출금내역</div>
            </div>
            <Row>
                <Col>
                    <SearchForm searchForms={{ type, category, keyword }} setSearchForms={setSearchForms} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="card-form">
                        <CardBody>
                            <table className="table">
                                <thead>
                                    <tr className="align-middle">
                                        <th scope="col">분류</th>
                                        <th scope="col" rowSpan={2}>설명</th>
                                        <th scope="col" rowSpan={2}>금액</th>
                                        <th scope="col">이전금액</th>
                                        <th scope="col">등록일</th>
                                        <th scope="col" rowSpan={2}>처리</th>
                                    </tr>
                                    <tr className="align-middle">
                                        <th scope="col">상태</th>
                                        <th scope="col">이후금액</th>
                                        <th scope="col">처리일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.amounts.map(( amount: any, key ) => 
                                        <React.Fragment key={key}>
                                            <tr className="align-middle text-center">
                                                <td>{amount.type} {amount.category}</td>
                                                <td rowSpan={2}>{amount.description}</td>
                                                <td rowSpan={2}>{amount.method_sign} {Util.getNumberFormat(amount.amount)}</td>
                                                <td>{Util.getNumberFormat(amount.amount_before)}</td>
                                                <td>{moment(amount.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                <td rowSpan={2}>
                                                    {amount.status == '대기' 
                                                    ? 
                                                        <>
                                                            <Button
                                                                type="button"
                                                                onClick={(event) => handleSetStatus(amount.idx, 'cancel')}
                                                            >
                                                                취소
                                                            </Button>
                                                        </>
                                                    : 
                                                        <>-</>
                                                    }
                                                </td>
                                            </tr>
                                            <tr className="align-middle text-center">
                                                <td>{amount.status}</td>
                                                <td>{Util.getNumberFormat(amount.amount_after)}</td>
                                                <td>{amount.processed_at ? moment(amount.processed_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                            </tr>
                                        </React.Fragment>
                                    )}

                                    {data.total <= 0 && 
                                        <tr className="align-middle">
                                            <td colSpan={6} className="py-5 text-center">입출금내역이 없습니다.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <Pagination 
                                total={data.total}
                                limit={limit}
                                page={page}
                                setPage={setPage}
                                setLimit={setLimit}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default AmountsForm