import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/rootReducer';
import { setSports, setUpcoming } from "../../redux/reducers/betReducer";
import { Routes, Route, matchPath, useNavigate } from "react-router-dom";
import { NavLink, Link, useLocation } from "react-router-dom";

import useModal from '../../hooks/GlobalModals';

import { IMultiRoute } from '../../interfaces/MultiRoutes';

import BetAPI from '../../modules/BetAPI.v2';

const Sidebar = ({ routes, ...props }: { routes: IMultiRoute[], [key: string]: any }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const { modalAlert } = useModal();

    const dispatch = useDispatch();

    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [ navItems, setNavItems ] = useState<any[]>([]);

    const [activeSidebar, setActiveSidebar] = useState<boolean>(true);

    const handleResize = () => {
        setInnerWidth(window.innerWidth);
    };

    useEffect(() => {
        BetAPI.getSports().then(( res ) => {
            if(res.data.success){
                let sports = res.data.message;

                const filterSports = sports.map((sport: any) => ({
                    uid: sport.uid,
                    name: sport.name,
                    name_kr: sport.name_kr
                }))

                if(filterSports.length > 0){
                    dispatch(setSports({
                        targetSportUid: filterSports[0].uid,
                        targetSports: filterSports
                    }));

                    setNavItems([ ...filterSports ]);
                }
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: 'Warning'
                });
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>Unable to Initialize Application.</>,
                title: 'Warning'
            });
        });

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    useEffect(() => {
        setActiveSidebar((activeSidebar) => {
            return (innerWidth <= 1599 && innerWidth > 1199) ? false : (innerWidth > 1599 || innerWidth <= 1199) ? true : activeSidebar;
        });
    }, [innerWidth])

    const handleOnClick = (uid: string) => {
        const currentPath = location.pathname;

        dispatch(setSports({
            targetSportUid: uid
        }));

        if(currentPath == '/inplay'){
            navigate('/inplay');
        }else{
            console.log('test')
            navigate('/upcoming');
        }
    }

    return (
        <div className="left-site-menu">
            <div className={`left-box ${activeSidebar ? 'active' : ''}`}>
                <nav className="menu">
                    <div 
                        className="toggle"
                        onClick={() => {setActiveSidebar(!activeSidebar)}}
                    >
                        <div>
                            <i className="fas fa-exchange-alt"></i>
                        </div>
                    </div>
                    <ul className="main-list-menu">
                        <li>
                            <ul className="menu-group">
                                <li className="title">
                                    Games
                                </li>
                                {navItems.map((navItem, key) =>
                                    <li key={key}>
                                        <div onClick={(event) => handleOnClick(navItem.uid)}>
                                            <div className="icon">
                                                <img src={`/images/${navItem.uid}.svg`} alt={`${navItem.name_kr ? navItem.name_kr : navItem.name}`} />
                                            </div>
                                            <span>
                                                {navItem.name_kr ? navItem.name_kr : navItem.name}
                                            </span>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </li>
                    </ul>
                    <div className="hamburger">
                        <span></span>
                    </div>
                    <div className="hamb">
                        <span></span>
                    </div>
                    <div className="dimmer"></div>
                </nav>
            </div>
        </div>
    );
};

export default Sidebar