import React, { useRef, useState, useEffect } from "react";

import { IInplayItemTimer, IInplayItemTimerProps } from "../../interfaces/Inplay";

import moment from "moment-timezone";

const extractInings = (inings: string) => {
    let result = '';

    if(inings){
        const iningsArray = inings.split('^');

        if(iningsArray.length >= 2){
            result = iningsArray[1] + '회';

            switch(iningsArray[0]){
                case '1':
                    result += ' 초';
                    break;
                case '2':
                    result += ' 말';
                    break;
                case '4':
                    result += ' 종료';
                    break;
            }
        }
    }

    return result;
}

const extractGrounds = (grounds: string) => {
    let result = '';

    if(grounds){
        const splitArray = grounds.split('^');
        const groundsArray = splitArray[0].split('#');

        const base = groundsArray[0]

        const ball = groundsArray[3]
        const strike = groundsArray[4]
        const out = groundsArray[5]

        result += 'B' + ball;
        result += ' S' + strike;
        result += ' O' + out;
    }

    return result;
}

const InplayItemGrounds = (props: any) => {
    const { inings, grounds } = props;

    return (
        <div className="grounds">
            <div className="ining text-end me-2">{extractInings(inings)}</div>
            <div className="ground text-start">{extractGrounds(grounds)}</div>
        </div>
    )
}

export default InplayItemGrounds
