import { AxiosPromise } from "axios";
import API from "./API";
import { IParamAmount, IParamAmountStatus } from "../interfaces/Amount";

const AmountAPI = {
    requestDeposit: (params: IParamAmount): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_AMOUNT_URL + '/deposit',
            data: params
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    requestWithdraw: (params: IParamAmount): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_AMOUNT_URL + '/withdraw',
            data: params
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    setStatus: (params: IParamAmountStatus): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_AMOUNT_URL + `/status`,
            data: params
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    }
}

export default AmountAPI;