import { useDispatch } from "react-redux";

import { setDefault } from "../redux/reducers/popupReducer";

import { IDefaultPopupProps } from "../interfaces/GlobalPopups";

const usePopup = () => {
    const dispatch = useDispatch();

    const popupDefault = (props: IDefaultPopupProps) => {
        dispatch(setDefault(true, props));
    };

    return { popupDefault };
}

export default usePopup;