import React, { useEffect, useState } from "react";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Editor, EditorConfig } from "@ckeditor/ckeditor5-core";
import { EventInfo } from "@ckeditor/ckeditor5-utils";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface IErrorDetails {
    phase: 'initialization' | 'runtime';
    willEditorRestart?: boolean;
}

interface ITextEditorProps {
    data: string | null | undefined;
    placeholder?: string,
    onReady?: (editor: ClassicEditor) => void;
    onError?: (error: Error, details: IErrorDetails) => void;
    onChange?: (event: EventInfo, editor: ClassicEditor) => void;
    onFocus?: (event: EventInfo, editor: ClassicEditor) => void;
    onBlur?: (event: EventInfo, editor: ClassicEditor) => void;
}

const TextEditor = (props: ITextEditorProps) => {
    const { 
        data,
        placeholder,
        onReady,
        onError,
        onChange,
        onFocus,
        onBlur
    } = props;

    let editorConfig: EditorConfig = { 
        placeholder,
        removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
    }
    
    return (
        <CKEditor
            editor={ ClassicEditor }
            config={editorConfig} 
            data={data}
            onReady={onReady}
            onError={onError}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    )
}

export default TextEditor;