import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import * as DOMPurify from 'dompurify';

import moment from "moment";
import Pagination from "../common/Pagination";
import useModal from "../../hooks/GlobalModals";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";

import BoardPostAPI from "../../modules/BoardPostAPI";
import { IPost } from "../../interfaces/Post";

const PostView = () => {
    const { key_board, uid_post } = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm } = useModal();

    const [ post, setPost ] = useState<IPost>({
        writer: '',
        subject: '',
        content: ''
    });

    const [ title, setTitle ] = useState<string>('게시판');
    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    useEffect(() => {
        switch(key_board){
            case 'notice':
                setTitle('공지사항');
                break;
            case 'event':
                setTitle('이벤트');
                break;
            case 'qna':
                setTitle('고객센터');
                break;
        }

        if(key_board && uid_post){
            BoardPostAPI.get(key_board, uid_post).then(( res ) => {
                if(res.data.success){
                    const data = res.data.message;

                    console.log('data', data)
                    
                    setPost(data);
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '게시판',
                        handleConfirm: () => {
                            navigate(-1);
                        }
                    });
                }
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '게시판',
                    handleConfirm: () => {
                        navigate(-1);
                    }
                });
            });
        }else{
            modalAlert({
                component: <>존재하지 않는 게시판입니다.</>,
                title: '게시판',
                handleConfirm: () => {
                    navigate(-1);
                }
            });
        }
    }, [location])

    return (
        <div className="contents">
            <div className="post">
                <div className="post-form">
                    <div className="title">
                        <div>{title}</div>
                    </div>
                    <Card className="card-form">
                        <CardHeader>
                            <CardTitle tag="h5">{post.subject}</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <div className={`content ${key_board}`}>
                                        <div 
                                            dangerouslySetInnerHTML={{ 
                                                __html: DOMPurify.sanitize(post.content) 
                                            }}
                                        >
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            {(key_board == 'qna' && post.answer) && 
                                <Row>
                                    <Col>
                                        <div className="content-title">답변</div>
                                        <div className="content">{post.answer.content}</div>
                                    </Col>
                                </Row>
                            }
                        </CardBody>
                    </Card>

                    <Row>
                        <Col className="text-center pt-4 pb-4">
                            <Button
                                className="btn-round"
                                type="submit"
                                color="primary"
                                onClick={(event) => navigate(`/board/${key_board}`)}
                            >
                                목록으로
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default PostView