import { io } from 'socket.io-client';

/*
const APISocket = io(process.env.REACT_APP_API_SOCKET_URL, {
    autoConnect: false,
    auth:{
        'x-api-key': process.env.REACT_APP_API_KEY
    }
});
*/

export default {
    getIo: (url: string) => {
        return io(url, {
            autoConnect: false,
            auth:{
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
    }
}