import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import {
    Modal,
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    Row,
    Col
} from 'reactstrap';

import { hidePopup } from "../../../redux/reducers/popupReducer";

const DefaultPopup = ({ index, popupVisible, idx, component, title='', confirmText = '창닫기', handleConfirm }: any) => {

    title = (title == '') ? 'Alert' : title;

    const dispatch = useDispatch();

    const handleHidePopup = (index: number) => dispatch(hidePopup(index));

    const onCloseExpireDay = (idx: number, days: number = 1) => {
        let expire = new Date();
        expire.setTime(expire.getTime() + (days * 24 * 60 * 60 * 1000));
        localStorage.setItem(`popup-${idx}-close-expire`, String(expire.getTime()));

        handleHidePopup(index);
    }

    const onConfirm = async (index: number) => {
        if (handleConfirm) {
            await handleConfirm();
        }

        handleHidePopup(index);
    };

    return (
        <div className={`popup-item shadow-sm ${popupVisible ? 'show' : ''}`}>
            <div className="popup-header">
                <div className="popup-title"><i className="fas fa-bell"></i> {title}</div>
                <div className="popup-close">
                    <Button className="btn" onClick={(event) => onCloseExpireDay(idx)}>오늘 하루 열지 않기 <i className="fas fa-times"></i></Button>
                </div>
            </div>
            <div className="popup-body">
                {component}
            </div>
            <div className="popup-footer">
                <Button className="btn" color="primary" onClick={(event) => onConfirm(index)}>
                    {confirmText}
                </Button>
            </div>
        </div>
    );
};

export default DefaultPopup;
