import { AxiosPromise } from "axios";
import API from "./API";

const MemoAPI = {
    delete: (idx: number): AxiosPromise => {
        return API({
            method: 'delete',
            url: process.env.REACT_APP_API_MEMO_URL + `/${idx}`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    read: (idx: number): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_MEMO_URL + `/read/${idx}`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    },
    readAll: (): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_MEMO_URL + `/read/all`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        })
    }
}

export default MemoAPI;