import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { setAuth } from '../../redux/reducers/authReducer';
import { clearNotifyItems } from "../../redux/reducers/notifyReducer";
import { Link, useLocation, matchPath, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

import { IMultiRoute } from '../../interfaces/MultiRoutes';
import SignXAPI from "../../modules/SignXAPI";
import useModal from "../../hooks/GlobalModals";

const Header = ({ routes, ...props }: { routes: IMultiRoute[], [key: string]: any }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const configState = useSelector((state: RootState) => state.configReducer);
    const authState = useSelector((state: RootState) => state.authReducer);

    const [activeNav, setActiveNav] = useState<boolean>(false);

    const { modalAlert } = useModal();

    const getBrand = (routes: IMultiRoute[]) => {
        let brandName = "";

        for( const route of routes ){
            brandName = (matchPath(route.path, location.pathname)) ? route.name : '';

            if(!brandName && route.childRoutes){
                for( const childRoute of route.childRoutes) {
                    let fullpath = route.path + '/' + childRoute.path;
    
                    if(matchPath(fullpath, location.pathname)){
                        brandName = childRoute.name;
                        break;
                    }
                }
            }

            if(!brandName && route.subRoutes){
                brandName = getBrand(route.subRoutes);
            }

            if(brandName) break;
        }

        return brandName;
    };

    const handleSignOut = () => {
        SignXAPI.signOut().then(( res ) => {
            if(!res.data.success){
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '로그아웃'
                });
            }else{
                dispatch(clearNotifyItems());
                dispatch(setAuth({ modifire: 'PUBLIC', uuid: '', ruid: '', userid: '', name: '', amount: 0, amount_bonus: 0, memo_new: 0, expires: 0 }));

                navigate('/')
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '로그아웃'
            });
        });
    }

    return (
        <header className="header-section header-hidden">
            <div className="header-wrapper">
                <div className="menu-logo-adjust d-flex align-items-center">
                    <div className="logo-menu me-5">
                        <Link
                            to="/"
                            className="logo"
                        >
                            <img src="/images/logo.png" alt={configState.site_name} />
                        </Link>
                    </div>
                    <div className={`header-bar ${activeNav ? 'active':''}`} onClick={() => {setActiveNav(!activeNav)}}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <ul className={`main-menu ${activeNav ? 'active':''}`}>
                        <li className="active">
                            <Link to="/upcoming">스포츠</Link>
                        </li>
                        <li>
                            <Link to="/inplay">인플레이</Link>
                        </li>
                        <li>
                            <Link to="#">E-스포츠</Link>
                        </li>
                        <li>
                            <Link to="#">미니게임</Link>
                        </li>
                        <li>
                            <Link to="#">카지노</Link>
                        </li>

                        <li className="menu--btn mt-4">
                            <Link to="#0" className="btn-round btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <span>로그인</span>
                            </Link>
                        </li>
                        <li className="menu--btn">
                            <Link to="#0" className="btn-round btn btn-theme-primary w-100" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                                <span>회원가입</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="right-menu-reature">
                    <div className="signup-area">
                        {!authState.uuid ? <>
                            <Link to="#0" className="btn-round btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <span>
                                    로그인
                                </span>
                            </Link>
                            <Link to="#0" className="btn-round btn btn-secondary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                                <span>
                                    회원가입
                                </span>
                            </Link>
                        </> : <>
                            <Button
                                className="btn-round btn btn-primary"
                                type="button" 
                                color="primary" 
                                size="sm" 
                                onClick={(event) => navigate(`/account/${authState.uuid}`)}
                            >
                                <i className="fas fa-user-cog"></i> 정보수정
                            </Button>
                            <Button
                                className="btn-round btn btn-secondary"
                                type="button" 
                                color="secondary" 
                                size="sm"
                                onClick={(event) => navigate('/board/qna')}
                            >
                                <i className="fas fa-headset"></i> 고객센터
                            </Button>
                            <Button
                                className="btn-round btn btn-secondary"
                                type="button" 
                                color="secondary" 
                                size="sm"
                                onClick={handleSignOut}
                            >
                                <i className="fas fa-sign-out-alt"></i> 로그아웃
                            </Button>
                        </>}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header