import React from 'react'
import { Routes, Route, Navigate, Path } from 'react-router-dom'
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { IMultiRoute, IMultiRoutesProps } from '../../interfaces/MultiRoutes';

const MultiRoutes = (props: IMultiRoutesProps): React.ReactElement => {
    const authState = useSelector((state: RootState) => state.authReducer);

    const renderRoute = (route: IMultiRoute) => {
        const Layout = route.layout;

        /*
        if(route.access == 'public'){
            if(authState.uuid && route.name == 'SignX')
                return <Route path='*' element={<Navigate to='/' />} />

            return <Route path={route.path} element={<Layout element={route.component} />}>
                {route.childRoutes && route.childRoutes.map((child, key) => {
                    const _component = (child.component) ? child.component : route.component;

                    return (
                        <Route key={key} path={child.path} element={<Layout element={_component} />} />
                    )
                })}
            </Route>
        }else{//private
            if(!authState.uuid)
                return <Route path='*' element={<Navigate to='/signx' />} />

            return <Route path={route.path} element={<Layout element={route.component} />}>
                {route.childRoutes && route.childRoutes.map((child, key) => {
                    const _component = (child.component) ? child.component : route.component;

                    return (
                        <Route key={key} path={child.path} element={<Layout element={_component} />} />
                    )
                })}
            </Route>
        }
        */
        if(route.access == 'private' && !authState.uuid)
            return <Route path='*' element={<Navigate to='/signx' />} />

        if(authState.uuid && route.name == 'SignX')
            return <Route path='*' element={<Navigate to='/' />} />

        return <Route path={route.path} element={<Layout element={route.component} />}>
            {route.childRoutes && route.childRoutes.map((child, key) => {
                const _component = (child.component) ? child.component : route.component;

                return (
                    <Route key={key} path={child.path} element={<Layout element={_component} />} />
                )
            })}
        </Route>
    }

    let renderKey = 0;

    return (
        <Routes>
            {props.routes.map((route, key) => {
                renderKey += key;

                return(
                    <React.Fragment key={renderKey}>
                        {renderRoute(route)}

                        {route.subRoutes && route.subRoutes.map((subRoute, subKey) => {
                            renderKey += subKey

                            return (
                                <React.Fragment key={renderKey}>
                                    {renderRoute(subRoute)}
                                </React.Fragment>
                            )
                        })}
                    </React.Fragment>
                )
            })}
        </Routes>
    );
}

export default MultiRoutes
