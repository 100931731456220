import { IDefaultNotifyProps} from "../../interfaces/GlobalNotifies";
import { TGlobalNotify, TGlobalNotifies, TGlobalNotifiesEnum } from "../../types/GlobalNotifies"

const SET_DEFAULT = 'notify/SET_DEFAULT' as const;
const SET_NOTIFY_ITEM = 'notify/SET_NOTIFY_ITEM' as const;
const SET_NOTIFY_ITEMS = 'notify/SET_NOTIFY_ITEMS' as const;
const REMOVE_NOTIFY_ITEM = 'notify/REMOVE_NOTIFY_ITEM' as const;
const CLEAR_NOTIFY_ITEMS = 'notify/CLEAR_NOTIFY_ITEMS' as const;
const SHOW_NOTIFY = 'notify/SHOW_NOTIFY' as const;
const HIDE_NOTIFY = 'notify/HIDE_NOTIFY' as const;

export const setDefault = (notifyVisible: boolean, notifyProps: IDefaultNotifyProps) => ({ type: SET_DEFAULT, notifyVisible, notifyType: TGlobalNotifiesEnum.DEFAULT, notifyProps });
export const setNotifyItem = (item: TGlobalNotify) => ({ type: SET_NOTIFY_ITEM, item });
export const setNotifyItems = (items: TGlobalNotify[]) => ({ type: SET_NOTIFY_ITEMS, items });
export const removeNotifyItem = (notifyKey: string) => ({ type: REMOVE_NOTIFY_ITEM, notifyKey });
export const clearNotifyItems = () => ({ type: CLEAR_NOTIFY_ITEMS });
export const showNotify = (index: number) => ({ type: SHOW_NOTIFY, index });
export const hideNotify = (index: number) => ({ type: HIDE_NOTIFY, index });

type NotifyAction =
    | ReturnType<typeof setDefault>
    | ReturnType<typeof setNotifyItem>
    | ReturnType<typeof setNotifyItems>
    | ReturnType<typeof removeNotifyItem>
    | ReturnType<typeof clearNotifyItems>
    | ReturnType<typeof showNotify>
    | ReturnType<typeof hideNotify>

const defaultState: TGlobalNotifies = {
    notifiesVisible: false,
    items: [
        /*
        {
            notifyVisible: false,
            notifyType: TGlobalNotifiesEnum.DEFAULT,
            notifyProps: {
                component: null
            }
        }
        */
    ]
}

const notifyReducer = (state: TGlobalNotifies = defaultState, action: NotifyAction): TGlobalNotifies => {
    let targetItems = [ ...state.items ];
    let filterItems = [];

    switch(action.type){
        case SET_DEFAULT:
            const existItem = targetItems.find((item) => item.notifyProps.notifyKey == action.notifyProps.notifyKey)

            if(!existItem){
                filterItems = [ ...targetItems ];

                filterItems.push({
                    notifyVisible: action.notifyVisible,
                    notifyType: action.notifyType,
                    notifyProps: action.notifyProps
                })
            }else{
                filterItems = targetItems.map((item) => (item.notifyProps.notifyKey == action.notifyProps.notifyKey) ? { ...item, notifyVisible: true} : item)
            }

            return {
                ...state,
                notifiesVisible: filterItems.find((item) => item.notifyVisible) ? true : false,
                items: filterItems
            };
        case SET_NOTIFY_ITEM:
            targetItems.push(action.item)

            return {
                ...state,
                items: targetItems
            };
        case SET_NOTIFY_ITEMS:
            return {
                ...state,
                items: action.items
            };
        case REMOVE_NOTIFY_ITEM:
            filterItems = targetItems.filter((item) => item.notifyProps.notifyKey != action.notifyKey)

            return {
                ...state,
                notifiesVisible: (filterItems.length > 0) ? true : false,
                items: filterItems
            };
        case CLEAR_NOTIFY_ITEMS:
            return {
                ...state,
                notifiesVisible: false,
                items: []
            };
        case SHOW_NOTIFY:
            return {
                ...state, 
                items: state.items.map((item, index) => (index !== action.index) ? item : { ...item, notifyVisible: true })
            };
        case HIDE_NOTIFY:
            filterItems = state.items.map((item, index) => (index !== action.index) ? item : { ...item, notifyVisible: false })

            return {
                ...state, 
                notifiesVisible: filterItems.find((item) => item.notifyVisible) ? true : false,
                items: filterItems
            };
        default:
            return state;
    }
}

export default notifyReducer;