import React, { ChangeEventHandler, useEffect, useState} from "react";
import { Controller, Control, UseFieldArrayUpdate } from "react-hook-form";
import { Container, FormGroup, Input } from "reactstrap";

type TRadioControlBox = { 
    control: Control<any, any>;
    controlUpdate: UseFieldArrayUpdate<any, any>,
    name: string;
    disabled?: boolean;
    label?: string;
    rules?: object;
    className?: string;
    children: string | JSX.Element | JSX.Element[];
}

const RadioArrayControlBox = ({ control, controlUpdate, name, rules, children, disabled=false} : TRadioControlBox) => {
    let fieldIndex = 0;
    let fieldName = '';
    let fieldChecked = false;
    let fieldArray: any[] = [];
    let fieldArrayName = '';
    let fieldArraySize = 0;

    if(/^[a-z0-9]+\.(\.?[a-z0-9]+)+$/.test(name)){
        const splitName = name.split('.');

        if(splitName.length == 3){
            fieldArray = control._getFieldArray(splitName[0])

            fieldIndex = Number(splitName[1])
            fieldName = splitName[2]
            fieldChecked = fieldArray[fieldIndex][fieldName]
            fieldArrayName = splitName[0]
            fieldArraySize = fieldArray.length;
        }
    }

    return (
        <div className={`form-control-box radio d-flex ${fieldChecked ? 'checked':''}`}>
            <div className="d-flex align-items-center">
                <Controller
                    control={control}
                    name={name}
                    rules={rules}
                    render={({ field: {value: fieldValue, onChange: fieldOnChange, ...fieldParams}, fieldState }) => {
                        let valid, invalid;

                        if(rules && fieldState.isDirty){
                            valid = !fieldState.error && fieldValue ? true : undefined;
                            valid = fieldState.error && fieldState.error.type === 'required' ? false : undefined;
                            valid = fieldState.error && fieldState.error.type === 'pattern' ? false : undefined;
                        
                            invalid = fieldState.error ? true : undefined;
                        }

                        return (
                            <>
                                <Input
                                    {...fieldParams}
                                    type="radio"
                                    name={`${fieldArrayName}_${fieldName}`}
                                    disabled={disabled}
                                    defaultChecked={fieldValue ? true : false}
                                    bsSize="sm"
                                    valid={valid}
                                    invalid={invalid}
                                    onChange={(event) => {
                                        for(let i=0; i<fieldArraySize; i++){
                                            controlUpdate(i, { ...fieldArray[i], [fieldName]: false })
                                        }

                                        return fieldOnChange(event.target.checked)
                                    }}
                                />
                            </>
                        )
                    }}
                />
            </div>
            <div className="flex-grow-1">
                <Container>
                    {children}
                </Container>
            </div>
        </div>
    )
}

export default RadioArrayControlBox;