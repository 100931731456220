import React, { MouseEvent, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import * as DOMPurify from 'dompurify';

import useModal from "../../hooks/GlobalModals";
import usePopup from "../../hooks/GlobalPopups";

import main_visual_1 from "../../assets/img/main_visual_01.png";
import quick_banner_sports from "../../assets/img/main_quick_banner_sports.png";
import quick_banner_livesports from "../../assets/img/main_quick_banner_livesports.png";
import quick_banner_virtual from "../../assets/img/main_quick_banner_virtual.png";
import quick_banner_mini from "../../assets/img/main_quick_banner_mini.png";
import quick_banner_casino from "../../assets/img/main_quick_banner_casino.png";
import BoardPostAPI from "../../modules/BoardPostAPI";
import PopupsAPI from "../../modules/PopupsAPI";

const Main = () => {
    const navigate = useNavigate();

    const { modalAlert, modalConfirm } = useModal();
    const { popupDefault } = usePopup();

    const [ latestNotice, setLatestNotice ] = useState({
        total: 0,
        posts: []
    });

    const [ latestEvent, setLatestEvent ] = useState({
        total: 0,
        posts: []
    });

    const isPopupCloseExpire = (idx: number) => {
        const expireDay = localStorage.getItem(`popup-${idx}-close-expire`);
        let today = new Date();
    
        return (today.getTime() <= Number(expireDay)) ? true : false
    }

    useEffect(() => {
        PopupsAPI.get().then(( res ) => {
            if(res.data.success){
                const { total: popupsTotal, popups } = res.data.message;
                
                popups.map((popup: any) => {
                    if(!isPopupCloseExpire(popup.idx))
                        popupDefault({
                            idx: popup.idx,
                            component: <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(popup.contents) }}></div>,
                            title: popup.subject,
                        })
                })
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '팝업'
                });
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '팝업'
            });
        });

        BoardPostAPI.getPosts('notice', 1, 5).then(( res ) => {
            if(res.data.success){
                const data = res.data.message;
                
                setLatestNotice(data);
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '공지사항'
                });
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '공지사항'
            });
        });

        BoardPostAPI.getPosts('event', 1, 5).then(( res ) => {
            if(res.data.success){
                const data = res.data.message;
                
                setLatestEvent(data);
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '공지사항'
                });
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '공지사항'
            });
        });
    }, [])

    const handleItemMove = (event: MouseEvent) => {
        const targetWidth = event.currentTarget.clientWidth;
        const targetHeight = event.currentTarget.clientHeight;

        const mouseX = event.pageX - event.currentTarget.getBoundingClientRect().left - targetWidth / 2;
        const mouseY = event.pageY - event.currentTarget.getBoundingClientRect().top -  targetHeight / 2;

        let rotateX = (mouseX / targetWidth) * 30;
        let rotateY = (mouseY / targetHeight) * -30;

        event.currentTarget.setAttribute('style', `transform: rotateY(${rotateX}deg) rotateX(${rotateY}deg)`);

        rotateX = (mouseX / targetWidth) * 35;
        rotateY = (mouseY / targetHeight) * -35;

        const image = event.currentTarget.querySelector('img');

        if(image)
            image.setAttribute('style', `transform: scale(1.04) rotateY(${rotateX}deg) rotateX(${rotateY}deg)`);
    }

    const handleItemOut = (event: MouseEvent) => {
        const currentTarget = event.currentTarget;

        currentTarget.setAttribute('style', `transform: rotateY(0deg) rotateX(0deg)`);

        const image = currentTarget.querySelector('img');

        if(image)
            image.setAttribute('style', `transform: scale(1) rotateY(0deg) rotateX(0deg)`);
    }

    return (
        <div className="main">
            <div className="visual-items">
                <div className="item">
                    <img src={main_visual_1} className="w-100" />
                </div>
            </div>
            <div className="board-items">
                <div className="board-item">
                    <div className="post-title" onClick={(event) => navigate('/board/notice')}>
                        <div className="left">
                            <i className="fas fa-list-alt"></i> 공지사항
                        </div>
                        <div className="right">
                            <i className="fas fa-plus-square"></i>
                        </div>
                    </div>
                    <div className="post-items">
                        <div>
                            {latestNotice.posts.map(( post: any, key ) => 
                                <div key={key} className="post-item" onClick={(event) => navigate(`/board/notice/post/${post.uid}`)}>
                                    <span className="m-r-5 m-t-3 f-s-20 line-height-20 home-notice-gold8">•</span>
                                    세븐 [SEVEN] - {post.subject}
                                </div>
                            )}

                            {latestNotice.posts.length <= 0 && 
                                <div className="post-empty">게시글이 없습니다.</div>
                            }
                        </div>
                    </div>
                </div>
                <div className="board-item">
                    <div className="post-title" onClick={(event) => navigate('/board/event')}>
                        <div className="left">
                            <i className="fas fa-bomb"></i> 이벤트
                        </div>
                        <div className="right">
                            <i className="fas fa-plus-square"></i>
                        </div>
                    </div>
                    <div className="post-items">
                        <div>
                            {latestEvent.posts.map(( post: any, key ) => 
                                <div key={key} className="post-item" onClick={(event) => navigate(`/board/event/post/${post.uid}`)}>
                                    <span className="m-r-5 m-t-3 f-s-20 line-height-20 home-notice-gold8">•</span>
                                    <span>EVENT {(key + 1) < 10 ? '0' + (key + 1) : (key + 1)}.</span>
                                    {post.subject}
                                </div>
                            )}

                            {latestEvent.posts.length <= 0 && 
                                <div className="post-empty">게시글이 없습니다.</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="quick-items">
                <div 
                    className="item"
                    onMouseMove={handleItemMove}
                    onMouseOut={handleItemOut}
                >
                    <div>
                        <Link to="/">
                            <img src={quick_banner_sports} />
                        </Link>
                    </div>
                </div>
                <div 
                    className="item"
                    onMouseMove={handleItemMove}
                    onMouseOut={handleItemOut}
                >
                    <div>
                        <Link to="/">
                            <img src={quick_banner_livesports} />
                        </Link>
                    </div>
                </div>
                <div 
                    className="item"
                    onMouseMove={handleItemMove}
                    onMouseOut={handleItemOut}
                >
                    <div>
                        <Link to="/">
                            <img src={quick_banner_virtual} />
                        </Link>
                    </div>
                </div>
                <div 
                    className="item"
                    onMouseMove={handleItemMove}
                    onMouseOut={handleItemOut}
                >
                    <div>
                        <Link to="/">
                            <img src={quick_banner_mini} />
                        </Link>
                    </div>
                </div>
                <div 
                    className="item"
                    onMouseMove={handleItemMove}
                    onMouseOut={handleItemOut}
                >
                    <div>
                        <Link to="/">
                            <img src={quick_banner_casino} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main