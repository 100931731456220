const Util = {
    getBoolean: (value: any): boolean => {
        let result = false;

        const valueType = typeof value;

        switch(valueType){
            case 'boolean':
                result = value;
                break;
            case 'string':
                value = value.toLowerCase();
                result = value === 'true';
                break;
            case 'number':
                result = value === 1;
                break;
            default:
                result = false;
        }

        return result;
    },
    removeSpace: (value: string) => {
        const regexp = /[\s\uFEFF\xA0]+/gi;
    
        return value.replace(regexp, '');
    },
    getNumberFormat: (value: number, maxDigits: number = 3) => {
        return value.toLocaleString(undefined, {maximumFractionDigits: maxDigits});
    }
}

export default Util;