import axios, { AxiosRequestConfig, InternalAxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

const API = axios.create({
    withCredentials: true
});

const onErrorResponse = (error: AxiosError | Error): Promise<AxiosError | Error> => {
    if(axios.isAxiosError(error)){
        const { message } = error;
        const { method, url } = error.config as AxiosRequestConfig;
        const { statusText, status } = error.response as AxiosResponse ?? {};

        switch (status) {
            case 401: {
                // "Login required"
                break;
            }
            case 403: {
                // "Permission denied"
                break;
            }
            case 404: {
                // "Invalid request"
                break;
            }
            case 500: {
                // "Server error"
                break;
            }
            default: {
                // "Unknown error occurred"
                break;
            }
        }

       /*
       const errorRequest = error.request;
        const errorResponse = error.response;

        if (errorRequest) {
            console.log('[ ' + errorRequest.status + ' ] Error Message:', errorRequest.data.message);
        } else if (errorResponse) {
            console.log(errorResponse);
        } else {
            console.log('Error', error.message);
        }
        */

        console.log(`🚨 [API] ${method?.toUpperCase()} ${url} | Error ${status} ${message}`)
    }else{
        console.log(`🚨 [API] | Error ${error.message}`);
    }

    return Promise.reject(error);
}

API.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    
    config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
    
    return config;
}, onErrorResponse)

API.interceptors.response.use((response: AxiosResponse): AxiosResponse => {
    return response;
}, onErrorResponse)

export default API;