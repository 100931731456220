import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import {
    Modal,
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button
} from 'reactstrap';

import { hideModal, toggleModal } from "../../../redux/reducers/modalReducer";

const AlertModal = ({ modalVisible, component, title='', confirmText = 'OK', handleConfirm }: any) => {

    title = (title == '') ? 'Alert' : title;

    const dispatch = useDispatch();

    const handleHideModal = () => dispatch(hideModal());
    const handleToggleModal = () => dispatch(toggleModal());

    const onConfirm = async () => {
        if (handleConfirm) {
            await handleConfirm();
        }

        handleHideModal();
    };

    return (
        <Modal isOpen={modalVisible} toggle={handleToggleModal} centered>
            <ModalHeader toggle={handleToggleModal}>{title}</ModalHeader>
            <ModalBody>
                {component}
            </ModalBody>
            <ModalFooter>
                <Button className="btn-round" color="primary" onClick={onConfirm}>
                    {confirmText}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AlertModal;
