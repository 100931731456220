import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    Button
} from 'reactstrap';

import useModal from "../../hooks/GlobalModals";

import Util from "../../modules/Util";
import AmountAPI from "../../modules/AmountAPI";

import InputControl from '../common/InputControl';

import { IAmountDeposit } from "../../interfaces/Amount";


const AmountDepositFrom = () => {
    const { uuid } = useParams();

    const navigate = useNavigate();

    const authState = useSelector((state: RootState) => state.authReducer)
    const configState = useSelector((state: RootState) => state.configReducer);

    const [roleOptions, setRoleOptions] = useState<{value: any, label: any}[]>([]);
    const [passwordRequired, setPasswordRequired] = useState<boolean>(true);    

    const { modalAlert } = useModal();

    const location = useLocation();

    let defaultValues: IAmountDeposit = {
        amount: 0
    };

    const { watch, handleSubmit, control, reset, getValues, setFocus, setError, formState } = useForm<IAmountDeposit>({
        mode: 'onChange', 
        reValidateMode: 'onSubmit', 
        shouldFocusError: true, 
        defaultValues
    });

    const amount = watch('amount');

    useEffect(() => {
        
    }, [location]);

    const handleAddAmount = (value: number) => {
        const formValues = getValues();

        reset({ ...formValues, amount: formValues.amount + value });
    }

    const handleResetAmount = () => {
        const formValues = getValues();

        reset({ ...formValues, amount: 0 });
    }

    const handleOnSubmit: SubmitHandler<IAmountDeposit> = (formData) => {
        let { amount, ...formParams} = formData

        if(amount <= 0){
            modalAlert({
                component: <>입금액을 정확하게 입력해주세요.</>,
                title: '입급신청'
            })
        }else{
            AmountAPI.requestDeposit({ amount }).then((res) => {
                if(res.data.success){
                    modalAlert({
                        component: <>입금신청이 완료되었습니다.</>,
                        title: '입급신청'
                    });

                    navigate('/amounts');
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '입급신청'
                    });
                }
            }).catch((error) => {
                const res = error.response;
    
                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '입급신청'
                    });
                }
            })
        }
    }
    
    return (
        <div className="amount-form">
            <div className="title">
                <div>입금신청</div>
            </div>
            <div className="guide">
                <div>
                    <ul>
                        <li>- 계정정보에 설정된 입금자명으로 입금하셔야 처리됩니다.</li>
                        <li>- 은행 점검시간동안은 입금이 지연 될 수 있습니다.</li>
                        <li>- 입금계좌가 수시로 변경되기때문에 꼭 "계좌문의" 후 신청바랍니다.</li>
                    </ul>
                </div>
            </div>
            <Form onSubmit={handleSubmit(handleOnSubmit)}>
                <Row>
                    <Col>
                        <Card className="card-form">
                            <CardBody>
                                <table className="table">
                                    <colgroup>
                                        <col width="30%"/>
                                        <col />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th scope="row">보유머니</th>
                                            <td>{Util.getNumberFormat(authState.amount)} 원</td>
                                        </tr>
                                        <tr className="align-middle">
                                            <th scope="row">입금액</th>
                                            <td>
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        type="number"
                                                        name="amount"
                                                        placeholder="입금신청 금액"
                                                    />
                                                </FormGroup>
                                                <div>
                                                    <Button
                                                        type="button" 
                                                        color="secondary" 
                                                        onClick={(event) => handleAddAmount(10000)}
                                                    >
                                                        1만원
                                                    </Button>
                                                    <Button
                                                        type="button" 
                                                        color="secondary" 
                                                        onClick={(event) => handleAddAmount(50000)}
                                                    >
                                                        5만원
                                                    </Button>
                                                    <Button
                                                        type="button" 
                                                        color="secondary" 
                                                        onClick={(event) => handleAddAmount(100000)}
                                                    >
                                                        10만원
                                                    </Button>
                                                    <Button
                                                        type="button" 
                                                        color="secondary" 
                                                        onClick={(event) => handleAddAmount(500000)}
                                                    >
                                                        50만원
                                                    </Button>
                                                    <Button
                                                        type="button" 
                                                        color="secondary" 
                                                        onClick={(event) => handleAddAmount(1000000)}
                                                    >
                                                        100만원
                                                    </Button>
                                                    <Button
                                                        type="button" 
                                                        color="secondary" 
                                                        onClick={(event) => handleAddAmount(5000000)}
                                                    >
                                                        500만원
                                                    </Button>
                                                    <Button
                                                        type="button" 
                                                        color="tertiary" 
                                                        onClick={(event) => handleResetAmount()}
                                                    >
                                                        정정하기
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <Row>
                                    <Col className="text-center pt-4 pb-4">
                                        <Button
                                            className="btn-round"
                                            type="submit"
                                            color="primary"
                                            disabled={formState.isSubmitting} 
                                        >
                                            입금신청
                                        </Button>
                                    </Col>
                                </Row>
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default AmountDepositFrom