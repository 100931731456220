import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    Button,
    InputGroup,
    Label,
    Input
} from 'reactstrap';

import Util from '../../modules/Util';
import AccountAPI from "../../modules/AccountAPI";
import ConfigAPI from "../../modules/ConfigAPI";
import useModal from "../../hooks/GlobalModals";

import InputControl from '../common/InputControl';

import PhonesForm from "./PhonesForm";
import EmailsForm from "./EmailsForm";
import AddressesForm from "./AddressesForm";
import PassboosForm from "./PassbookForm";

import { IAccount } from "../../interfaces/Account";

import profileDefaultImage from "../../assets/img/profile-default.svg";
import AmountAPI from "../../modules/AmountAPI";

const AccountForm = () => {
    const { uuid } = useParams();

    const authState = useSelector((state: RootState) => state.authReducer)
    const configState = useSelector((state: RootState) => state.configReducer);

    const [passwordRequired, setPasswordRequired] = useState<boolean>(true);    

    const { modalAlert } = useModal();

    const location = useLocation();

    let defaultValues: IAccount = {
        roleName: '',
        levelName: '',
        userid: '',
        password: '',
        passwordConfirm: '',
        password_withdraw: '',
        nickname: '',
        amount: 0,
        amount_bonus: 0,
        addresses: [],
        phones: [],
        emails: [],
        passbooks: []
    };

    if(Util.getBoolean(configState.signup_used_fullname)){
        defaultValues = {...defaultValues, fullname: ''}
    }else{
        defaultValues = {...defaultValues, firstname: '', lastname: ''}
    }

    const addressesDefaultValues = {
        default: false,
        type: 'home', 
        postalcode: '', 
        city: '', 
        country: '', 
        address: '' 
    };

    const phonesDefaultValues = {
        default: false,
        type: 'cell', 
        dialin: '82', 
        number: '' 
    };

    const emailsDefaultValues = {
        default: false,
        type: 'private', 
        email: '' 
    };

    const passbooksDefaultValues = {
        default: false,
        bankcode: '023',
        holder: '',
        number: ''
    };

    const { watch, handleSubmit, control, reset, getValues, setFocus, setError, formState } = useForm<IAccount>({
        mode: 'onChange', 
        reValidateMode: 'onSubmit', 
        shouldFocusError: true, 
        defaultValues
    });

    const [
        userid, 
        password, 
        watchAddressesFields, 
        watchPhonesFields, 
        watchEmailsFields,
        watchPassbooksFields,
    ] = watch(['userid', 'password', 'addresses', 'phones', 'emails', 'passbooks'])

    const addressFields = useFieldArray({
        control: control, 
        name: 'addresses'
    });

    const phoneFields = useFieldArray({
        control: control, 
        name: 'phones'
    });

    const emailFields = useFieldArray({
        control: control, 
        name: 'emails'
    });

    const passbookFields = useFieldArray({
        control: control, 
        name: 'passbooks'
    });

    useEffect(() => {
        if(uuid){
            AccountAPI.get(uuid, true).then((res) => {
                if(res.data.success){
                    const _account = res.data.message;

                    console.log(_account);

                    let resetValues: IAccount = {
                        roleName: _account.role.name,
                        levelName: _account.level.name,
                        userid: _account.userid,
                        password: '',
                        passwordConfirm: '',
                        password_withdraw: (_account.password_withdraw) ? _account.password_withdraw : '',
                        nickname: (_account.nickname) ? _account.nickname : '',
                        amount: _account.amount,
                        amount_bonus: _account.amount_bonus,
                        addresses: _account.addresses,
                        phones: _account.phones,
                        emails: _account.emails,
                        passbooks: _account.passbooks.map((passbook: any) => ({
                            default: passbook.default,
                            bankcode: passbook.bankcode,
                            //bankname: passbook.bankname,
                            holder: passbook.holder,
                            number: passbook.number
                        }))
                    }

                    if(Util.getBoolean(configState.signup_used_fullname)){
                        resetValues = {
                            ...resetValues, 
                            fullname: (_account.fullname) ? _account.fullname : ''
                        }
                    }else{
                        resetValues = {
                            ...resetValues, 
                            firstname: (_account.firstname) ? _account.firstname : '', 
                            lastname: (_account.lastname) ? _account.lastname : ''
                        }
                    }

                    reset(resetValues);

                    setPasswordRequired(false);
                }else{
                    modalAlert({
                        component: <>This account does not exist.</>,
                        title: 'Warning'
                    });
                }
            });
        }
    }, [location]);

    const handleOnSubmit: SubmitHandler<IAccount> = (formData) => {
        let { passwordConfirm, ...formParams} = formData

        if(Util.getBoolean(configState.signup_required_address) && watchAddressesFields.length <= 0){
            addressFields.append(addressesDefaultValues);
            //setError('addresses.0.address', { type: 'leastone', message: 'Please enter at least one address.' });

            modalAlert({
                component: <>Please enter at least one address.</>,
                title: '정보수정'
            })

            return false;
        }

        for( const _field of watchAddressesFields ){
            if(!_field.type || !_field.postalcode || !_field.address){
                modalAlert({
                    component: <>Please enter the correct address.</>,
                    title: '정보수정'
                });

                return false;
            }
        }

        if(Util.getBoolean(configState.signup_required_phone) && watchPhonesFields.length <= 0){
            phoneFields.append(phonesDefaultValues);
            //setError('phones.0.number', { type: 'leastone', message: 'Please enter at least one mobile phone number.' });

            modalAlert({
                component: <>Please enter at least one mobile phone number.</>,
                title: '정보수정'
            })

            return false;
        }

        for( const _field of watchPhonesFields ){
            if(!_field.type || !_field.dialin || !_field.number){
                modalAlert({
                    component: <>Please enter the correct mobile phone number.</>,
                    title: '정보수정'
                });

                return false;
            }
        }

        if(Util.getBoolean(configState.signup_required_email) && watchEmailsFields.length <= 0){
            emailFields.append(emailsDefaultValues);
            //setError('emails.0.email', { type: 'leastone', message: 'Please enter at least one email.' });

            modalAlert({
                component: <>Please enter at least one email.</>,
                title: '정보수정'
            })

            return false;
        }

        for( const _field of watchEmailsFields ){
            if(!_field.type || !_field.email){
                modalAlert({
                    component: <>Please enter the correct email.</>,
                    title: '정보수정'
                });

                return false;
            }
        }

        if(watchPassbooksFields.length <= 0){
            passbookFields.append({ ...passbooksDefaultValues, default: true });
            //setError('passbooks.0.bankcode', { type: 'leastone', message: 'Please enter at least one passbook.' });

            modalAlert({
                component: <>Please enter at least one passbook.</>,
                title: '정보수정'
            })

            return false;
        }

        for( const _field of watchPassbooksFields ){
            if(!_field.bankcode || !_field.holder || !_field.number){
                modalAlert({
                    component: <>Please enter the correct passbook.</>,
                    title: '정보수정'
                });

                return false;
            }
        }

        if(uuid){
            formParams = {...formParams, uuid}

            AccountAPI.update(formParams).then(( res ) => {
                if(res.data.success){
                    modalAlert({
                        component: <>처리되었습니다.</>,
                        title: '정보수정'
                    });

                    reset(formParams);
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '정보수정'
                    });
                }
            }).catch((error) => {
                const res = error.response;

                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '정보수정'
                    });
                }
            })
        }
    }
    
    return (
        <div className="account-form">
            <div className="title">
                <div>정보수정</div>
            </div>
            <Form onSubmit={handleSubmit(handleOnSubmit)}>
                <Row>
                    <Col lg="6">
                        <Row>
                            <Col>
                                <Card className="card-form">
                                    <CardBody>
                                        <Row className="mb-3">
                                            <Col className="pe-1">
                                                <Label>회원등급</Label>
                                                <InputGroup className="w-100">
                                                    <InputControl 
                                                        control={control}
                                                        type="text"
                                                        name="roleName"
                                                        disabled={true}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col className="ps-1">
                                                <Label>회원레벨</Label>
                                                <InputGroup className="w-100">
                                                    <InputControl 
                                                        control={control}
                                                        type="text"
                                                        name="levelName"
                                                        disabled={true}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        name="userid"
                                                        label="아이디"
                                                        placeholder="아이디"
                                                        rules={{
                                                            required: true,
                                                            pattern: /^[a-z,0-9]{3,30}$/
                                                            //pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                                                        }}
                                                        disabled={!!uuid}
                                                    />
                                                    <FormFeedback>
                                                        Please input a correct userid!
                                                    </FormFeedback>
                                                    {formState.errors.userid && <p>{formState.errors.userid.message}</p>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pe-1">
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        type="password"
                                                        name="password"
                                                        label="비밀번호"
                                                        placeholder="비밀번호"
                                                        rules={{
                                                            required: passwordRequired,
                                                            minLength: 6
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col className="ps-1">
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        type="password"
                                                        name="passwordConfirm"
                                                        label="비밀번호 확인"
                                                        placeholder="비밀번호 확인"
                                                        rules={{
                                                            required: passwordRequired,
                                                            minLength: 6,
                                                            validate: {value: (value: string) => value == password}
                                                        }}
                                                    />
                                                    <FormFeedback>
                                                        Passwords do not match!
                                                    </FormFeedback>
                                                    <FormFeedback valid>
                                                        Passwords match!
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {Util.getBoolean(configState.signup_used_fullname)
                                            ?
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <InputControl 
                                                            control={control}
                                                            name="fullname"
                                                            label="이름"
                                                            placeholder="이름"
                                                            rules={{
                                                                required: Util.getBoolean(configState.signup_required_name)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            :
                                            <Row>
                                                <Col className="pe-1">
                                                    <FormGroup>
                                                        <InputControl 
                                                            control={control}
                                                            name="firstname"
                                                            label="이름"
                                                            placeholder="이름"
                                                            rules={{
                                                                required: Util.getBoolean(configState.signup_required_name)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col className="ps-1">
                                                    <FormGroup>
                                                        <InputControl 
                                                            control={control}
                                                            name="lastname"
                                                            label="이름 (성)"
                                                            placeholder="이름 (성)"
                                                            rules={{
                                                                required: Util.getBoolean(configState.signup_required_name)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        }

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        name="nickname"
                                                        label="닉네임"
                                                        placeholder="닉네임"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        name="password_withdraw"
                                                        label="출금 비밀번호 (숫자 4자리 이상)"
                                                        placeholder="출금 비밀번호"
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: '출금 비밀번호를 입력해주세요.'
                                                            },
                                                            pattern: {
                                                                value: /^[0-9]{4,}$/,
                                                                message: '형식에 맞지 않는 출금 비밀번호입니다.'
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {uuid && 
                                            <>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <Label>보유머니</Label>
                                                        <InputGroup className="w-100">
                                                            <InputControl 
                                                                control={control}
                                                                type="text"
                                                                name="amount"
                                                                disabled={true}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col>
                                                        <Label>보유보너스</Label>
                                                        <InputGroup className="w-100">
                                                            <InputControl 
                                                                control={control}
                                                                name="amount_bonus"
                                                                disabled={true}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <PhonesForm control={control} watch={watch} errors={formState.errors} phoneFields={phoneFields} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PassboosForm control={control} watch={watch} errors={formState.errors} passbookFields={passbookFields}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6">
                        <Row>
                            <Col>
                                <AddressesForm control={control} watch={watch} errors={formState.errors} addressFields={addressFields}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <EmailsForm control={control} watch={watch} errors={formState.errors} emailFields={emailFields} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center py-2">
                        <Button
                            className="btn-round"
                            type="submit"
                            color="primary"
                            disabled={formState.isSubmitting} 
                        >
                            정보수정
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default AccountForm