import React from "react";
import SignForm from "./SignForm";

const Sign = () => {
    return (
        <div className="contents">
            <div className="sign">
                <SignForm />
            </div>
        </div>
    )
}

export default Sign