import { IBetCart, IBetCartItem } from "../../interfaces/Bet";

const SET_CART_AMOUNT_TYPE = 'bet_cart/SET_CART_AMOUNT_TYPE' as const;
const SET_CART_AMOUNT = 'bet_cart/SET_CART_AMOUNT' as const;
const SET_CART_ITEM = 'bet_cart/SET_CART_ITEM' as const;
const SET_CART_ITEMS = 'bet_cart/SET_CART_ITEMS' as const;
const REMOVE_CART = 'bet_cart/REMOVE_CART' as const;
const RESET_CART = 'bet_cart/RESET_CART' as const;

export const setCartAmountType = (amountType: string) => ({ type: SET_CART_AMOUNT_TYPE, amountType });
export const setCartAmount = (amount: number) => ({ type: SET_CART_AMOUNT, amount });
export const setCartItem = (item: IBetCartItem) => ({ type: SET_CART_ITEM, item });
export const setCartItems = (items: any[]) => ({ type: SET_CART_ITEMS, items });
export const removeCart = (uid: string) => ({ type: REMOVE_CART, uid });
export const resetCart = () => ({ type: RESET_CART });

type BetCartAction = 
    | ReturnType<typeof setCartAmountType>
    | ReturnType<typeof setCartAmount>
    | ReturnType<typeof setCartItem>
    | ReturnType<typeof setCartItems>
    | ReturnType<typeof removeCart>
    | ReturnType<typeof resetCart>;

const defaultState: IBetCart = {
    amountType: 'amount',
    amount: 0,
    items: []
}

const betCartReducer = (state: IBetCart = defaultState, action: BetCartAction): IBetCart => {
    let targetItems = [ ...state.items ];
    let filterItems = [];

    switch(action.type){
        case SET_CART_AMOUNT_TYPE:
            return {
                ...state,
                amountType: action.amountType
            }
        case SET_CART_AMOUNT:
            return {
                ...state,
                amount: action.amount
            }
        case SET_CART_ITEM:
            targetItems.push(action.item);

            return {
                ...state,
                items: targetItems
            }
        case SET_CART_ITEMS:
            return {
                ...state,
                items: action.items
            }
        case REMOVE_CART:
            filterItems = targetItems.filter((item) => item.uid != action.uid);

            return {
                ...state,
                items: filterItems
            }
        case RESET_CART:
            return {
                ...state,
                amountType: 'amount',
                amount: 0,
                items: []
            }
        default:
            return state;
    }
}

export default betCartReducer;