import { IBetItemSport } from "../../interfaces/Bet";

const SET_SPORTS = 'bet/SET_SPORTS' as const;
const SET_INPLAY = 'bet/SET_INPLAY' as const;
const SET_UPCOMING = 'bet/SET_UPCOMING' as const;

interface IBetReducer {
    targetType?: string,
    targetSportUid?: string,
    targetSports?: IBetItemSport[],
    targetEventUid?: string,
    targetEvent?: any
}

interface ISportsReducer extends IBetReducer {
    targetSportUid?: string,
    targetSports?: IBetItemSport[]
}

interface IEventReducer extends IBetReducer {
    targetType?: string,
    targetEventUid: string,
    targetEvent: any
}

export const setSports = (state: ISportsReducer) => ({ type: SET_SPORTS, state });
export const setInplay = (state: IEventReducer) => ({ type: SET_INPLAY, state });
export const setUpcoming = (state: IEventReducer) => ({ type: SET_UPCOMING, state });

type BetAction =
    | ReturnType<typeof setSports>
    | ReturnType<typeof setInplay>
    | ReturnType<typeof setUpcoming>;

const defaultState: IBetReducer = {
    targetType: '',
    targetSportUid: '',
    targetSports: [],
    targetEventUid: '',
    targetEvent: {}
};

const betReducer = (state: IBetReducer = defaultState, action: BetAction): IBetReducer => {
    switch(action.type){
        case SET_SPORTS:
            return {
                ...state,
                ...action.state
            };
        case SET_INPLAY:
            return {
                ...state,
                ...action.state
            };
        case SET_UPCOMING:
            return {
                ...state,
                ...action.state
            };
        default:
            return state;
    }
}

export default betReducer;