import React, { useState, useEffect } from "react";

import AmountWithdraw from "./AmountWithdrawForm";

const AmountWithdrawView = () => {
    
    return (
        <div className="contents">
            <div className="amount">
                <AmountWithdraw />
            </div>
        </div>
    )
}

export default AmountWithdrawView