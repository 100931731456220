import { TRole } from "../../types/Config";

const SET_ROLES = 'config_roles/SET_CONFIG_ROLES' as const;

export const setRoles = (roles: TRole[]) => ({ type: SET_ROLES, roles });

type RolesAction =
    | ReturnType<typeof setRoles>;

const defaultState: TRole[] = [
    {
        idx: 0, 
        role_key: 'guest', 
        role_name: 'Guest'
    }
]

const rolesReducer = (state: TRole[] = defaultState, action: RolesAction): TRole[] => {
    switch(action.type){
        case SET_ROLES:
            return [
                ...state,
                ...action.roles
            ]
        default:
            return state;
    }
}

export default rolesReducer;