import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button } from 'reactstrap';

import moment from "moment";
import Pagination from "../common/Pagination";
import useModal from "../../hooks/GlobalModals";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";

import BoardPostAPI from "../../modules/BoardPostAPI";
import PostFormModal from "./PostFormModal";

const PostsView = () => {
    const { key_board } = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalForm } = useModal();

    const [ list, setList ] = useState({
        total: 0,
        posts: []
    });

    const [ title, setTitle ] = useState<string>('게시판');
    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    useEffect(() => {
        switch(key_board){
            case 'notice':
                setTitle('공지사항');
                break;
            case 'event':
                setTitle('이벤트');
                break;
            case 'qna':
                setTitle('고객센터');
                break;
        }

        if(key_board){
            BoardPostAPI.getPosts(key_board, 1, 5).then(( res ) => {
                if(res.data.success){
                    const data = res.data.message;
                    
                    setList(data);
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '게시판'
                    });
                }
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '게시판'
                });
            });
        }else{
            modalAlert({
                component: <>존재하지 않는 게시판입니다.</>,
                title: '게시판',
                handleConfirm: () => {
                    navigate(-1);
                }
            });
        }
    }, [location])

    useEffect(() => {
        if(key_board){
            BoardPostAPI.getPosts(key_board, 1, 5).then(( res ) => {
                if(res.data.success){
                    const data = res.data.message;
                    
                    setList(data);
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '게시판'
                    });
                }
            }).catch(( error ) => {
                modalAlert({
                    component: <>{error.message}</>,
                    title: '게시판'
                });
            });
        }else{
            modalAlert({
                component: <>존재하지 않는 게시판입니다.</>,
                title: '게시판',
                handleConfirm: () => {
                    navigate(-1);
                }
            });
        }
    }, [page, limit]);

    const handleEdit = (uid: string = '') => {
        modalForm({
            formId: 'modal-form-post',
            component: <PostFormModal key_board={key_board} uid_post={uid} />,
            title: (!uid) ? '게시글 쓰기' : '게시글 수정'
        });
    }

    return (
        <div className="contents">
            <div className="posts">
                <div className="posts-form">
                    <div className="title">
                        <div>{title}</div>
                    </div>
                    {key_board == 'qna' && 
                        <div className="mb-2 d-flex justify-content-end">
                            <Button
                                className="btn-round"
                                type="button"
                                color="primary" 
                                onClick={(event) => handleEdit()}
                            >
                                <i className="fa fa-plus"></i> {key_board == 'qna' ? '문의하기' : '게시글 쓰기'}
                            </Button>
                        </div>
                    }
                    <Card className="card-form">
                        <CardBody>
                            <table className="table">
                                <colgroup>
                                    <col width="8%" />
                                    {key_board == 'qna' && <col  width="10%" />}
                                    <col />
                                    <col  width="15%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">번호</th>
                                        {key_board == 'qna' && <th scope="col">답변</th>}
                                        <th scope="col">제목</th>
                                        <th scope="col">등록일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.posts.map(( post: any, key ) => 
                                        <tr className="align-middle text-center" key={key}>
                                            <th scope="row">{key + 1}</th>
                                            {key_board == 'qna' && <td>{!post.received ? '답변대기':'답변완료'}</td>}
                                            <td>
                                                <Link to={`/board/${key_board}/post/${post.uid}`}>{post.subject}</Link>
                                            </td>
                                            <td>{moment(post.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                        </tr>
                                    )}

                                    {list.posts.length <= 0 && 
                                        <tr className="align-middle">
                                            <td colSpan={key_board == 'qna' ? 4 : 3} className="py-5 text-center">게시글이 없습니다.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <Pagination 
                                total={list.total}
                                limit={limit}
                                page={page}
                                setPage={setPage}
                                setLimit={setLimit}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default PostsView