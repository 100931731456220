import React, { useRef, useState, useEffect, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { removeCart, setCartItem, setCartItems } from "../../redux/reducers/betCartReducer";
import { Card, CardBody, Input, Button, Row, Col, Container } from 'reactstrap';

import useModal from "../../hooks/GlobalModals";
import useSpinner from "../../hooks/GlobalSpinner";

import { IInplayMarketsItem, IInplayMarketsItemProps, IItemOdds } from "../../interfaces/Inplay";

import BetAPI from "../../modules/BetAPI";
import BetAPI_V2 from "../../modules/BetAPI.v2";

const InplayMarketsItem = (props: IInplayMarketsItemProps) => {
    const { item } = props;

    const authState = useSelector((state: RootState) => state.authReducer);
    const betState = useSelector((state: RootState) => state.betReducer);
    const betCartState = useSelector((state: RootState) => state.betCartReducer);

    const { modalAlert } = useModal();
    const { spinnerVisible } = useSpinner();

    const [ inplayMarketsItem, setInplayMarketsItem ] = useState<IInplayMarketsItem | null>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        const betCartItems = [ ...betCartState.items ];
        const betCartItemUids = betCartItems.map((betCartItem) => betCartItem.uid);

        const filterMarketGroupName = item.name_kr ? item.name_kr : item.name;

        let filterItem = { ...item };

        if(item.markets && item.markets.length > 0){
            let filterMarkets = item.markets.map((market: any) => {
                if(market.odds && market.odds.length > 0){
                    const filterMarketName = market.name_kr ? market.name_kr : market.name;

                    let filterOdds = market.odds.map((odd: any) => {
                        let isActive = betCartItemUids.includes(odd.uid);
        
                        return { ...odd, market_group_name: filterMarketGroupName, market_name: filterMarketName, active: isActive }
                    });

                    return { ...market, odds: filterOdds}
                }else{
                    return market;
                }
            })

            filterItem = { ...filterItem, markets: filterMarkets };
        }else{
            if(item.odds && item.odds.length > 0){
                let filterOdds = item.odds.map((odd: any) => {
                    let isActive = betCartItemUids.includes(odd.uid);
    
                    return { ...odd, market_group_name: filterMarketGroupName, market_name: '', active: isActive }
                });

                filterItem = { ...filterItem, odds: filterOdds };
            }
        }

        //console.log('filterItem', filterItem)

        setInplayMarketsItem({ ...filterItem })
        
    }, [item, betCartState])

    const renderMarket = (market: any, key: any) => {
        const filterMarketName = market.name_kr ? market.name_kr : market.name;

        if(market.style_participant === '_a' && market.style == 'dcs'){
            return (
                <React.Fragment key={key} />
            )
        }else if(market.style_participant === 'dt' || market.style_participant === '_d' || market.style_participant === '_D' || market.style_participant === '_c' || market.style_participant === '_C' || market.style_participant === '_e'){
            return (
                <div className="market" key={key} data-key={market.topic_id}>
                    {filterMarketName && 
                        <div className="name">
                            {(filterMarketName && filterMarketName != ' ') ? filterMarketName : '\u00A0'}{/* / {market.key} / {market.channel} / {market.style_participant} / {market.style} */}
                        </div>
                    }

                    {renderOdds(market.channel, market.style_participant, market.style, market.odds)}
                </div>
            )
        }else if(market.style_participant === 'da' || market.style_participant === 'de' || market.style_participant === 'dn' || market.style_participant === 'is' || market.style_participant === 'dc' || market.style_participant === 'db'){
            return (
                <div className={`market ${(['da', 'dc', 'daa', 'db'].includes(market.style)) ? 'hd':''}`} key={key} data-key={market.topic_id}>
                    <div className="name">
                        {(filterMarketName && filterMarketName != ' ') ? filterMarketName : '\u00A0'}{/* / {market.key} / {market.channel} / {market.style_participant} / {market.style} */}
                    </div>

                    {renderOdds(market.channel, market.style_participant, market.style, market.odds)}
                </div>
            )
        }else if(market.style_participant === 'ib'){
            return (
                <div className="market" key={key} data-key={market.topic_id}>
                    {filterMarketName && 
                        <div className="name">
                            {(filterMarketName && filterMarketName != ' ') ? filterMarketName : '\u00A0'}{/* / {market.key} / {market.channel} / {market.style_participant} / {market.style} */}
                        </div>
                    }

                    {renderOdds(market.channel, market.style_participant, market.style, market.odds)}
                </div>
            )
        }else{
            return (
                <div className="market" key={key} data-key={market.topic_id}>
                    {filterMarketName && 
                        <div className="name">
                            {(filterMarketName && filterMarketName != ' ') ? filterMarketName : '\u00A0'}{/* / {market.key} / {market.channel} / {market.style_participant} / {market.style} */}
                        </div>
                    }

                    {renderOdds(market.channel, market.style_participant, market.style, market.odds)}
                </div>
            )
        }
    }

    const renderOdds = (channel: number, style_participant: string, style: string, odds: any[]) => {
        const _onClick = (e: any) => {
            spinnerVisible(true)

            if(!authState.uuid){
                modalAlert({
                    component: <>회원만 이용 가능합니다.</>,
                    title: '알림'
                });

                //spinnerVisible(false);
            }else{ 
                if(betState.targetEventUid && betState.targetEventUid){
                    const key = e.currentTarget.dataset.key;
                    const targetOdd = odds.find((odd) => odd.uid == key);

                    let betCartItems = [ ...betCartState.items ];

                    if(targetOdd.active){
                        dispatch(removeCart(key));

                        betCartItems = betCartItems.filter((item) => item.uid != key);
                    }

                    const targetEvent = betState.targetEvent;
                    const targetSport = targetEvent.sport;
                    
                    const duplMarketGroupItem = betCartItems.find((item) => {
                        return ((item.uid_event == targetEvent.uid) && (item.uid_market_group == targetOdd.uid_market_group))
                    })

                    if(duplMarketGroupItem) 
                        betCartItems = betCartItems.filter((item) => item.uid != duplMarketGroupItem.uid);
                        //dispatch(removeCart(duplMarketGroupItem.uid));

                    let filterBetCartItems = betCartItems.map((betCartItem) => ({
                        status: betCartItem.status,
                        uid_sport: betCartItem.uid_sport, 
                        uid_event: betCartItem.uid_event, 
                        uid: betCartItem.uid, 
                        rate: betCartItem.rate 
                    }));

                    if(!targetOdd.active){
                        let filterBetCartItem = { 
                            status: '', 
                            uid_sport: targetSport.uid, 
                            uid_event: targetEvent.uid, 
                            uid: targetOdd.uid, 
                            rate: targetOdd.rate 
                        };

                        filterBetCartItems.push(filterBetCartItem);
                    }

                    if(filterBetCartItems.length > 0){
                        BetAPI_V2.setCart({ target: 'inplay', amountType: betCartState.amountType, amount: betCartState.amount, items: filterBetCartItems }).then((res) => {
                            if(res.data.success){
                                filterBetCartItems = res.data.message;

                                console.log('filterBetCartItems', filterBetCartItems)
                                
                                dispatch(setCartItems(filterBetCartItems));
                            }else{
                                modalAlert({
                                    component: <>{res.data.message}</>,
                                    title: '베팅슬립'
                                });
                            }

                            spinnerVisible(false);
                        }).catch(( error ) => {
                            spinnerVisible(false);
                            
                            modalAlert({
                                component: <>{error.message}</>,
                                title: '베팅슬립'
                            });
                        });
                    }else{
                        spinnerVisible(false);
                    }
                }
            }
            /*
            const key = event.currentTarget.dataset.key;
            const isActive = event.currentTarget.classList.contains('active');

            const targetOdd = odds.find((odd) => odd.topic_id == key);

            console.log('targetOdd', targetOdd)

            if(isActive){
                event.currentTarget.classList.remove('active');

                //deleteCartItem(key);
            }else{
                event.currentTarget.classList.add('active');

                dispatch(setCart({ ...targetOdd }))

                //setCartItem(key);
            }
            */
        }

        if(style == '_a' || style == 'ibm'){
            return (
                <div className="odds channel">
                    {odds.map((odd, key) => {
                        const filterOddName = odd.name_kr ? odd.name_kr : odd.name;
                        const filterOddHandicapFormatted = odd.handicap_formatted_kr ? odd.handicap_formatted_kr : odd.handicap_formatted;

                        return (
                            <div className={`odd-target type-2 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} key={key} {...(!odd.suspended && {onClick: _onClick})} data-key={odd.uid}>
                                <div className="odd">
                                    <div className="name">{filterOddName ? filterOddName : ''} {!filterOddHandicapFormatted ? '' : filterOddName ? '\u00A0' + filterOddHandicapFormatted : filterOddHandicapFormatted}</div>
                                    <div className="rate">
                                        {odd.suspended 
                                            ? <i className="fas fa-lock"></i>
                                            : (odd.rate != null && odd.rate != '0') ? (odd.rate).toFixed(2) : '-'
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            );
        }else if(style == 'ib'){
            return (
                <div className="odds">
                    {odds.map((odd, key) => {
                        const filterOddName = odd.name_kr ? odd.name_kr : odd.name;

                        return (
                            <div className={`odd-target type-3 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} key={key} {...(!odd.suspended && {onClick: _onClick})} data-key={odd.uid}>
                                <div className="odd">
                                    <div className="name">{filterOddName}</div>
                                    <div className="rate">
                                        {odd.suspended 
                                            ? <i className="fas fa-lock"></i>
                                            : (odd.rate != null && odd.rate != '0') ? (odd.rate).toFixed(2) : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            );
        }else if(style =='dc' || style =='dg'){
            if(style_participant == '_e'){
                return (
                    <div className="odds">
                        {odds.map((odd, key) => {
                            const filterOddName = odd.name_kr ? odd.name_kr : odd.name;
                            const filterOddHandicapFormatted = odd.handicap_formatted_kr ? odd.handicap_formatted_kr : odd.handicap_formatted;

                            return (
                                <div className={`odd-target type-2 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} key={key} {...(!odd.suspended && {onClick: _onClick})} data-key={odd.uid}>
                                    <div className="odd">
                                        <div className="name">{filterOddName ? filterOddName : filterOddHandicapFormatted}2</div>
                                        <div className="rate">
                                            {odd.suspended 
                                                ? <i className="fas fa-lock"></i>
                                                : (odd.rate != null && odd.rate != '0') ? (odd.rate).toFixed(2) : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                );
            }else{
                return (
                    <div className="odds">
                        {odds.map((odd, key) => {
                            const filterOddName = odd.name_kr ? odd.name_kr : odd.name;

                            return (
                                <div className={`odd-target type-1 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} key={key} {...(!odd.suspended && {onClick: _onClick})} data-key={odd.uid}>
                                    <div className="odd">
                                        {(!filterOddName && (odd.rate == null || odd.rate == '0')) && <div>&nbsp;</div>}
                                        {filterOddName && <div className="name">{filterOddName}</div>}
                                        {(odd.rate != null && odd.rate != '0') && 
                                            <div className="rate">
                                                {odd.suspended 
                                                    ? <i className="fas fa-lock"></i>
                                                    : (odd.rate != '0') ? (odd.rate).toFixed(2) : '\u00A0'
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                );
            }
        }else if(style =='da' || style =='db'){
            if(style_participant == '_d'){
                return (
                    <div className="odds">
                        {odds.map((odd, key) => {
                            const filterOddName = odd.name_kr ? odd.name_kr : odd.name;

                            return (
                                <div className={`odd-target type-1 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} key={key} {...(!odd.suspended && {onClick: _onClick})} data-key={odd.uid}>
                                    <div className="odd">
                                        {(!filterOddName && (odd.rate == null || odd.rate == '0')) && <div>&nbsp;</div>}
                                        {filterOddName && <div className="name">{filterOddName}</div>}
                                        {(odd.rate != null && odd.rate != '0') && 
                                            <div className="rate">
                                                {odd.suspended 
                                                    ? <i className="fas fa-lock"></i>
                                                    : (odd.rate != '0') ? (odd.rate).toFixed(2) : '\u00A0'
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                );
            }else{
                return (
                    <div className="odds">
                        {odds.map((odd, key) => {
                            const filterOddName = odd.name_kr ? odd.name_kr : odd.name;

                            return (
                                <div className={`odd-target type-2 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} key={key} {...(!odd.suspended && {onClick: _onClick})} data-key={odd.uid}>
                                    <div className="odd">
                                        <div className="name">{filterOddName ? filterOddName : odd.handicap_formatted}</div>
                                        {
                                            (odd.result_text == null || odd.result_text == '') 
                                                ? <div className="rate">
                                                    {odd.suspended 
                                                        ? <i className="fas fa-lock"></i>
                                                        : (odd.rate != null && odd.rate != '0') ? (odd.rate).toFixed(2) : ''
                                                    }
                                                </div> 
                                                : <div className="desc">{odd.result_text}</div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                );
            }
        }else{
            return ( // ??
                <div className="odds">
                    {odds.map((odd, key) => {
                        const filterOddName = odd.name_kr ? odd.name_kr : odd.name;

                        return (
                            <div className={`odd-target type-1 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} key={key} {...(!odd.suspended && {onClick: _onClick})} data-key={odd.uid}>
                                <div className="odd">
                                    {(!filterOddName && (odd.rate == null || odd.rate == '0')) && <div>&nbsp;</div>}
                                    {filterOddName && <div className="name">{filterOddName}</div>}
                                    {(odd.rate != null && odd.rate != '0') && 
                                        <div className="rate">
                                            {odd.suspended 
                                                ? <i className="fas fa-lock"></i>
                                                : (odd.rate != '0') ? (odd.rate).toFixed(2) : '\u00A0'
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            );
        }
    }

    const toggleMarketGroup = (event: MouseEvent) => event.currentTarget.parentElement!.classList.toggle('fold');

    return (
        <React.Fragment>
            {inplayMarketsItem != null &&
                <div className={`market-group ${inplayMarketsItem.folded ? 'fold':''}`}>
                    <div className="name" onClick={toggleMarketGroup}>
                        <div>{inplayMarketsItem.name_kr ? inplayMarketsItem.name_kr : inplayMarketsItem.name}</div>
                        <div className="toggle">
                            <i className="fas fa-chevron-down"></i>
                            <i className="fas fa-chevron-up"></i>
                        </div>
                    </div>
                    {inplayMarketsItem.result_text != '' &&
                        <div className="result-text">
                            <div>{inplayMarketsItem.result_text}</div>
                        </div>
                    }
                    <div className="markets">
                        {inplayMarketsItem.markets && inplayMarketsItem.markets.map((market, key) => {
                            return renderMarket(market, key);
                        })}

                        {!inplayMarketsItem.markets && (() => {
                            const market = inplayMarketsItem;

                            return renderMarket(market, market.uid);
                        })()}
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default InplayMarketsItem
