import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button, Row, Col } from 'reactstrap';
import * as DOMPurify from 'dompurify';

import moment from "moment";
import Pagination from "../common/Pagination";
import useModal from "../../hooks/GlobalModals";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";

import MemosAPI from "../../modules/MemosAPI";
import MemoAPI from "../../modules/MemoAPI";

const PostsView = () => {
    const navigate = useNavigate();

    const location = useLocation();

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm } = useModal();

    const [ list, setList ] = useState({
        total: 0,
        posts: []
    });

    const [ viewIndex, setViewIndex ] = useState<number | null>(null)
    const [ data, setData ] = useState({
        total: 0,
        memos: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    useEffect(() => {
        MemosAPI.get(page, limit, '', '', '').then(( res ) => {
            const { total, memos } = res.data.message;

            let filterMemos: any = [];

            for( const memo of memos ){
                let filterMemo = {
                    ...memo
                }

                filterMemos.push(filterMemo);
            }

            setData({ total, memos: filterMemos });

        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '쪽지 목록'
            });
        });
    }, [page, limit]);

    const handleDelete = (idx: number) => {
        modalConfirm({
            component: <>정말 삭제하시겠습니까?</>,
            title: '쪽지 삭제',
            handleConfirm: () => {
                MemoAPI.delete(idx).then(( res ) => {
                    if(res.data.success){
                        modalAlert({
                            component: <>삭제되었습니다.</>,
                            title: '쪽지 삭제'
                        });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '쪽지 삭제'
                        });
                    }
                }).catch(( error ) => {
                    modalAlert({
                        component: <>{error.message}</>,
                        title: '쪽지 삭제'
                    });
                });
            }
        })
    }

    const handleViewIndex = (index: number, idx: number) => {
        MemoAPI.read(idx).then(( res )=> {
            if(res.data.success){
                setViewIndex((_viewIndex) => {
                    if(_viewIndex == index){
                        return null
                    }else{
                        return index;
                    }
                })

                setData(({ total, memos }: any) => {
                    let filterMemos = memos.map((memo: any) => (memo.idx == idx) ? { ...memo, is_read: true } : memo)

                    return { total, memos: filterMemos }
                })
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '쪽지 읽기'
                });
            }
        }).catch(( error ) => {
            modalAlert({
                component: <>{error.message}</>,
                title: '쪽지 읽기'
            });
        });
    }

    const handleViewAll = () => {
        modalConfirm({
            component: <>정말 전체읽기하시겠습니까?</>,
            title: '쪽지 전체읽기',
            handleConfirm: () => {
                MemoAPI.readAll().then(( res ) => {
                    if(res.data.success){
                        setData(({ total, memos }: any) => {
                            let filterMemos = memos.map((memo: any) => ({ ...memo, is_read: true }))
        
                            return { total, memos: filterMemos }
                        })

                        modalAlert({
                            component: <>처리되었습니다.</>,
                            title: '쪽지 전체읽기'
                        });
                    }else{
                        modalAlert({
                            component: <>{res.data.message}</>,
                            title: '쪽지 전체읽기'
                        });
                    }
                }).catch(( error ) => {
                    modalAlert({
                        component: <>{error.message}</>,
                        title: '쪽지 전체읽기'
                    });
                });
            }
        })
    }

    return (
        <div className="contents">
            <div className="memos">
                <div className="memos-form">
                    <div className="title">
                        <div>쪽지목록</div>
                    </div>
                    <Card className="card-form">
                        <CardBody>
                            <Row className="mb-3">
                                <Col>
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            className="btn-round"
                                            type="button"
                                            color="primary" 
                                            onClick={(event) => handleViewAll()}
                                        >
                                            <i className="fas fa-check"></i> 전체읽기
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <table className="table">
                                <colgroup>
                                    <col width="80px"/>
                                    <col width="100px"/>
                                    <col />
                                    <col width="200px"/>
                                    <col width="100px"/>
                                </colgroup>
                                <thead>
                                    <tr className="align-middle">
                                        <th scope="col">번호</th>
                                        <th scope="col">상태</th>
                                        <th scope="col">제목</th>
                                        <th scope="col">등록일</th>
                                        <th scope="col">관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.memos.map(( memo: any, key ) => 
                                        <React.Fragment key={key}>
                                            <tr className={`align-middle text-center ${(key % 2 == 1) ? 'even':''}`}>
                                                <th  scope="row">{key + 1}</th>
                                                <td >{memo.is_read ? '읽음' : '안읽음'}</td>
                                                <td ><span onClick={(event) => handleViewIndex(key, memo.idx)} className="text-primary text-decoration-underline cursor-pointer">{memo.subject}</span></td>
                                                <td >{moment.utc(memo.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                <td >
                                                    <Button
                                                        className="btn-table-icon"
                                                        type="button"
                                                        color="danger"
                                                        onClick={(event) => handleDelete(memo.idx)}
                                                    >
                                                        <i className="fas fa-trash" />
                                                    </Button>
                                                </td>
                                            </tr>
                                            <tr className={`${(viewIndex == key) ? 'd-table-row' : 'd-none'}`}>
                                                <td colSpan={5}>
                                                    <div 
                                                        dangerouslySetInnerHTML={{ 
                                                            __html: DOMPurify.sanitize(memo.contents) 
                                                        }}
                                                        className="p-3"
                                                        style={{minHeight: '150px', backgroundColor: '#1c3934'}}
                                                    >
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )}

                                    {data.total <= 0 && 
                                        <tr className="align-middle">
                                            <td colSpan={5} className="py-5 text-center">해당되는 내역이 없습니다.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <Pagination 
                                total={list.total}
                                limit={limit}
                                page={page}
                                setPage={setPage}
                                setLimit={setLimit}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default PostsView