import { IDefaultPopup } from "../interfaces/GlobalPopups";

export type TGlobalPopup = IDefaultPopup;

export type TGlobalPopups = {
    popupsVisible: boolean;
    items: TGlobalPopup[];
};

export enum TGlobalPopupsEnum {
    DEFAULT = "default"
}