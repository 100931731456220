import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from '../../redux/reducers/rootReducer';
import moment from "moment";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    Button
} from 'reactstrap';


import useModal from "../../hooks/GlobalModals";
import useSpinner from "../../hooks/GlobalSpinner";

import BetAPI_V2 from "../../modules/BetAPI.v2";
import Util from "../../modules/Util";

import SearchForm from './SearchForm';
import Pagination from "../common/Pagination";

const BettingsForm = () => {

    const configState = useSelector((state: RootState) => state.configReducer);

    const { modalAlert, modalConfirm } = useModal();
    const { spinnerVisible } = useSpinner();

    const [ data, setData ] = useState<{total: number, accountBets: any[]}>({
        total: 0,
        accountBets: []
    });

    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(10);

    const [ { target, result_type, amount_type }, setSearchForms ] = useState({
        target: 'upcoming',
        result_type: '',
        amount_type: ''
    });

    useEffect(() => {
        spinnerVisible(true);

        BetAPI_V2.getBets(target, page, limit, result_type, amount_type).then(( res ) => {
            if(res.data.success){
                const { total, accountBets } = res.data.message;

                let filterAccountBets: any = [];

                for( const accountBet of accountBets ){
                    let filterAccountBet = { 
                        ...accountBet, 
                        username: (configState.signup_used_fullname == 'true') ? accountBet.account_detail.fullname : `${accountBet.account_detail.firstname} ${accountBet.account_detail.lastname}`
                    };

                    filterAccountBets.push(filterAccountBet);
                }

                setData({ total, accountBets: filterAccountBets });
            }else{
                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '베팅내역'
                });
            }

            spinnerVisible(false);
        }).catch(( error ) => {
            spinnerVisible(false);

            modalAlert({
                component: <>{error.message}</>,
                title: '베팅내역'
            });
        });
    }, [page, limit]);

    useEffect(() => {
        if(page != 1) setPage(1);

        const delay = setTimeout(() => {
            spinnerVisible(true);

            BetAPI_V2.getBets(target, 1, limit, result_type, amount_type).then(( res ) => {
                if(res.data.success){
                    const { total, accountBets } = res.data.message;

                    let filterAccountBets: any = [];

                    for( const accountBet of accountBets ){
                        let filterAccountBet = { 
                            ...accountBet, 
                            username: (configState.signup_used_fullname == 'true') ? accountBet.account_detail.fullname : `${accountBet.account_detail.firstname} ${accountBet.account_detail.lastname}`
                        };

                        filterAccountBets.push(filterAccountBet);
                    }

                    setData({ total, accountBets: filterAccountBets });
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '베팅관리'
                    });
                }

                spinnerVisible(false);
            }).catch(( error ) => {
                spinnerVisible(false);

                modalAlert({
                    component: <>{error.message}</>,
                    title: '베팅관리'
                });
            });
        }, 300);

        return () => clearTimeout(delay)
    }, [target, result_type, amount_type]);
    
    return (
        <div className="bettings-form">
            <div className="title">
                <div>베팅내역</div>
            </div>
            <Row>
                <Col>
                    <SearchForm setSearchForms={setSearchForms} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="card-form">
                        <CardBody>
                            <table className="table">
                                <thead>
                                    <tr className="align-middle">
                                        <th scope="col">코드</th>
                                        <th scope="col" rowSpan={2}>폴더</th>
                                        <th scope="col" rowSpan={2}>금액타입</th>
                                        <th scope="col" rowSpan={2}>금액</th>
                                        <th scope="col" rowSpan={2}>배당</th>
                                        <th scope="col" rowSpan={2}>예상당첨금액</th>
                                        <th scope="col">등록일</th>
                                    </tr>
                                    <tr className="align-middle">
                                        <th scope="col">상태</th>
                                        <th scope="col">처리일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.accountBets.map(( accountBet: any, key ) => 
                                        <React.Fragment key={key}>
                                            <tr className="align-middle text-center">
                                                <td>{accountBet.uid}</td>
                                                <td rowSpan={2}>{Util.getNumberFormat(accountBet.picks.length) + ' 폴더'}</td>
                                                <td rowSpan={2}>{accountBet.amount_type_kr}</td>
                                                <td rowSpan={2}>{Util.getNumberFormat(accountBet.amount)}</td>
                                                <td rowSpan={2}>{Number(accountBet.rate).toFixed(2)}</td>
                                                <td rowSpan={2}>{Util.getNumberFormat(accountBet.rate * accountBet.amount)}</td>
                                                <td>{moment(accountBet.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                            </tr>
                                            <tr className="align-middle text-center">
                                                <td>{accountBet.result_kr}</td>
                                                <td>{accountBet.processed_at ? moment(accountBet.processed_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                            </tr>
                                        </React.Fragment>
                                    )}

                                    {data.total <= 0 && 
                                        <tr className="align-middle">
                                            <td colSpan={7} className="py-5 text-center">해당되는 내역이 없습니다.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <Pagination 
                                total={data.total}
                                limit={limit}
                                page={page}
                                setPage={setPage}
                                setLimit={setLimit}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default BettingsForm