import { AxiosPromise } from "axios";
import API from "./API";
import { IParamAmount } from "../interfaces/Amount";

const AmountsAPI = {
    get: (page: number = 1, limit: number = 10, type: string, category: string, keyword: string): AxiosPromise => {
        let _query = (page <= 1) ? '' : `&page=${page}`;
        _query += (limit <= 1) ? '&limit=1' : `&limit=${limit}`;
        _query += (type) ? `&type=${type}` : '';
        _query += (category) ? `&category=${category}` : '';
        _query += (keyword) ? `&keyword=${keyword}` : '';

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_AMOUNTS_URL + `?${_query}`
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    }
}

export default AmountsAPI;