import React, { useRef, useState, useEffect, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../redux/reducers/rootReducer';
import { Card, CardBody, Input, Button, Row, Col } from 'reactstrap';

import useModal from "../../hooks/GlobalModals";

import BetAPI from "../../modules/BetAPI";
import APISocket from "../../modules/APISocket";
import { useNavigate, useParams } from "react-router-dom";
import { IInplayItem, IInplayItemProps, ITargetInplay } from "../../interfaces/Inplay";
import { setInplay } from "../../redux/reducers/betReducer";

import InplayItemTimer from "./InplayItemTimer";
import InplayItemGrounds from "./InplayItemGrounds";
import InplayItemPlayers from "./InplayItemPlayers";

const InplayItem = (props: IInplayItemProps) => {
    const { item } = props;

    const betState = useSelector((state: RootState) => state.betReducer);
    
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [ targetInplay, setTargetInplay ] = useState<ITargetInplay | null>(null);

    useEffect(() => {
        setTargetInplay(item)
    }, [item]);

    const toggleItem = (event: MouseEvent) => event.currentTarget.parentElement!.classList.toggle('fold');
    const handleOnClick = (targetEvent: any) => {
        dispatch(setInplay({ 
            targetEventUid: targetEvent.uid,
            targetEvent 
        }))
    }

    return (
        <>
            {targetInplay != null && 
                <div className="inplay-item">
                    <div className="name" onClick={toggleItem}>
                        <div>{targetInplay.league.name}</div>
                        <div className="toggle">
                            <i className="fas fa-chevron-down"></i>
                            <i className="fas fa-chevron-up"></i>
                        </div>
                    </div>
                    <div className="events">
                        {targetInplay.events.map((targetEvent, key) => 
                            <Card className="card-inplay" onClick={(event) => handleOnClick(targetEvent)} key={key}>
                                <CardBody>
                                    {<div>{targetEvent.uid} / {targetEvent.key} / {targetEvent.key_fi} / {targetEvent.status}</div>}
                                    {targetEvent.sport.name == 'Baseball' 
                                        ? 
                                            <InplayItemGrounds inings={targetEvent.inings} grounds={targetEvent.grounds}/>
                                        :
                                            <InplayItemTimer timer={targetEvent.timer} />
                                    }
                                    
                                    <InplayItemPlayers home={targetEvent.home} away={targetEvent.away} scores={targetEvent.scores} images={targetEvent.images} />
                                    <div className="markets">+{targetEvent.markets}</div>
                                    {/*<div className="folder">
                                        <div className="odds left">
                                            <div className="name">{inplayItem.home}</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                        <div className="draw">VS</div>
                                        <div className="odds right">
                                            <div className="rate">0.00</div>
                                            <div className="name">{inplayItem.away}</div>
                                        </div>
                                    </div>
                                    <div className="folder type-col">
                                        <div className="hd">언오버</div>
                                        <div className="odds disable">
                                            <div className="name">Over 1.5</div>
                                            <div className="rate"><i className="fas fa-lock"></i></div>
                                        </div>
                                        <div className="odds">
                                            <div className="name">Under 1.5</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                    </div>
                                    <div className="folder type-col">
                                        <div className="hd">핸디캡</div>
                                        <div className="odds">
                                            <div className="name">Over 1.5</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                        <div className="odds">
                                            <div className="name">Under 1.5</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                    </div>
                                    <div className="folder type-col">
                                        <div className="odds">
                                            <div className="name">{inplayItem.home}</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                        <div className="odds">
                                            <div className="name">Draw</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                        <div className="odds">
                                            <div className="name">{inplayItem.away}</div>
                                            <div className="rate">0.00</div>
                                        </div>
                                    </div>*/}
                                </CardBody>
                            </Card>
                        )}
                    </div>
                </div>
            }
        </>
    )
}

export default InplayItem
