const SHOW_SPINNER = 'spinner/SHOW_SPINNER' as const;
const HIDE_SPINNER = 'spinner/HIDE_SPINNER' as const;
const TOGGLE_SPINNER = 'spinner/TOGGLE_SPINNER' as const;

export const showSpinner = () => ({ type: SHOW_SPINNER });
export const hideSpinner = () => ({ type: HIDE_SPINNER });
export const toggleSpinner = () => ({ type: TOGGLE_SPINNER });

type SpinnerAction =
    | ReturnType<typeof showSpinner>
    | ReturnType<typeof hideSpinner>
    | ReturnType<typeof toggleSpinner>;

const defaultState: boolean = false;

const spinnerReducer = (state: boolean = defaultState, action: SpinnerAction): boolean => {
    switch(action.type){
        case SHOW_SPINNER:
            return true;
        case HIDE_SPINNER:
            return false;
        case TOGGLE_SPINNER:
            return !!state;
        default:
            return state;
    }
}

export default spinnerReducer;