import React, { useState, useEffect } from "react";

import BettingsForm from "./BettingsForm";

const BettingsView = () => {
    
    return (
        <div className="contents">
            <div className="bettings">
                <BettingsForm />
            </div>
        </div>
    )
}

export default BettingsView