import React, { useState, useEffect } from "react";
import { Control, UseFormWatch, UseFieldArrayUpdate, UseFieldArrayReturn, FieldErrors } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    FormGroup,
    Button,
} from 'reactstrap';

import InputControl from '../common/InputControl';
import RadioControlBox from '../common/RadioArrayControlBox';

const InputAddresses = (props: { control: Control<any>, controlUpdate: UseFieldArrayUpdate<any, any>, index: number, field: Record<'id', string>, errors: FieldErrors<any>}) => {
    const { control, controlUpdate, index, field, errors } = props;
    /*
    const value = useWatch({
        control,
        name: 'phones'
    });
    */

    const typeOptions = [
        {value: 'home', label: '집'},
        {value: 'work', label: '직장'},
        {value: 'private', label: '개인'},
        {value: 'public', label: '공용'},
        {value: 'other', label: '기타'}
    ]

    const renderError = (fieldName: string, index:number, errors: FieldErrors<any>) => {
        if(
            errors && 
            Array.isArray(errors.addresses) && 
            errors.addresses[index][fieldName]
        ){
            return (
                <div className="form-feedback-custom invalid mb-2">
                    {errors.addresses[index][fieldName].message}
                </div>
            )
        }
    }

    return (
        <Col>
            <RadioControlBox
                control={control}
                controlUpdate={controlUpdate}
                name={`addresses.${index}.default`}
            >
                <Row>
                    <Col className="pe-1">
                        <FormGroup>
                            <InputControl 
                                control={control}
                                type="select"
                                name={`addresses.${index}.type`}
                                options={typeOptions}
                            />
                            {renderError('type', index, errors)}
                        </FormGroup>
                    </Col>
                    <Col className="ps-1">
                        <FormGroup>
                            <InputControl 
                                control={control}
                                name={`addresses.${index}.postalcode`}
                                placeholder="우편번호"
                            />
                            {renderError('postalcode', index, errors)}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="pe-1">
                        <FormGroup>
                            <InputControl 
                                control={control}
                                name={`addresses.${index}.city`}
                                placeholder="시/군/구"
                            />
                        </FormGroup>
                    </Col>
                    <Col className="ps-1">
                        <FormGroup>
                            <InputControl 
                                control={control}
                                name={`addresses.${index}.country`}
                                placeholder="국가"
                            />
                            {renderError('country', index, errors)}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputControl 
                            control={control}
                            name={`addresses.${index}.address`}
                            placeholder="주소"
                        />
                        {renderError('address', index, errors)}
                    </Col>
                </Row>
            </RadioControlBox>
        </Col>
    )
};

const AddressesForm = (props: { control: Control<any, any>, watch: UseFormWatch<any>, errors: FieldErrors<any>, addressFields: UseFieldArrayReturn<any, "addresses", "id"> }) => {
    let { control, watch, errors, addressFields } = props;

    //const [errorLeastone, setErrorLeastone] = useState('');

    let addressesDefaultValues = {
        default: false,
        type: 'home',
        postalcode: '',
        city: '',
        country: '',
        address: ''
    };

    const watchAddressFields = watch('addresses');

    const fieldAppend = () => {
        if(watchAddressFields.length <= 0)
            addressesDefaultValues = { ...addressesDefaultValues, default: true }

        if(watchAddressFields.length < 5)
            addressFields.append(addressesDefaultValues);
    }

    const fieldRemove = (index: number) => {                    
        addressFields.remove(index);
    }

    useEffect(() => {
        /*
        if(watchAddressFields.length <= 0)
            addressFields.append(addressesDefaultValues);
        */
    }, [watchAddressFields])

    /*
    useEffect(() => {
        if(
            errors && 
            errors.addresses && 
            Array.isArray(errors.addresses) && 
            errors.addresses[0].address && 
            errors.addresses[0].address.type == 'leastone'
        ){
            setErrorLeastone(errors.addresses[0].address.message);
        }
    },  [errors])
    */

    return (
        <Card className="card-form">
            <CardHeader>
                <CardTitle tag="h5">주소 설정</CardTitle>
            </CardHeader>
            <CardBody>
                {addressFields.fields.map(( field, index ) => {
                    return(
                        <React.Fragment key={index}>
                            {/*
                                errorLeastone && 
                                <div className="form-feedback-custom invalid mb-2">
                                    {errorLeastone}
                                </div>
                            */}
                            <Row className="align-items-center mb-3">
                                <InputAddresses key={field.id} control={control} controlUpdate={addressFields.update} index={index} field={field} errors={errors}/>
                                <Col className="ps-1" md="2">
                                    <Button className="d-block w-100 btn-round" onClick={() => { fieldRemove(index) }}>
                                        <i className="fa fa-times"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                })}

                {addressFields.fields.length <= 0 &&
                    <Row>
                        <Col className="text-center py-5">
                            "주소 추가" 버튼으로 주소를 추가할 수 있습니다.
                        </Col>
                    </Row>
                }

                <Row>
                    <Col className="text-center py-2">
                        <Button
                            className="btn-round"
                            color="secondary"
                            type="button"
                            onClick={fieldAppend}
                        >
                            <i className="fa fa-plus"></i> 주소 추가
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default AddressesForm