import { AxiosPromise } from "axios";
import API from "./API";
import { IConfig, IParamNavigations, IParamPermissions, IParamPermissionBridges, IParamRoles } from "../interfaces/Config";
import { ROLES_KEY_DEFAULT, TRoleKey } from "../types/Config";

const ConfigAPI = {
    getModifires: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_MODIFIRES_URL
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getCountries: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_COUNTRIES_URL
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getRole: (ruid: string): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_ROLE_URL + '/' + ruid
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getRoles: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_ROLES_URL
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getNavigationRoles: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_NAVIGATION_ROLES_URL,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getNavigations: (ruid: string = '', keyword: string = ''): AxiosPromise => {
        let _query = (ruid) ? `&ruid=${ruid}` : '';
        _query += (keyword) ? `&keyword=${keyword}` : '';

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_NAVIGATIONS_URL + `?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    },
    getBanks: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_CONFIG_BANKS_URL,
        }).then(( res ) => {
            return res;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }
}

export default ConfigAPI;