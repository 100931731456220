import { AxiosPromise } from "axios";
import API from "./API";
import { IBetCartItem } from "../interfaces/Bet";

const BetAPIToken = '161988-4J1mmHOECYCFhy';

const BetAPI_V2 = {
    getConfigs: (target: string, target_config: String): AxiosPromise => {
        let _query = `target_config=${target_config}`;

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_V2_BET_URL + `/configs/${target}?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getUpcomings: (sport: string = '', page: number = 1, limit: number = 10): AxiosPromise => {
        let _query = (!sport) ? '' : `&sport=${sport}`;
        _query += (page <= 1) ? '' : `&page=${page}`;
        _query += (limit <= 1) ? '&limit=1' : `&limit=${limit}`;

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_V2_UPCOMING_URL + `?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getUpcomingSports: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_UPCOMING_URL + `/sports`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getUpcomingEvents: (sport: string = '', uid: string = ''): AxiosPromise => {
        let _query = (!sport) ? '' : `&sport=${sport}`;

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_V2_UPCOMING_URL + `/events/${uid}?${_query}`,
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getSports: (): AxiosPromise => {
        return API({
            method: 'get',
            url: process.env.REACT_APP_API_V2_BET_URL + `/sports`
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    setCart: (params: IBetCartItem): AxiosPromise => {
        return API({
            method: 'patch',
            url: process.env.REACT_APP_API_V2_BET_URL + `/cart`,
            data: params
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    setBet: (params: IBetCartItem): AxiosPromise => {
        return API({
            method: 'post',
            url: process.env.REACT_APP_API_V2_BET_URL + `/bet`,
            data: params
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    },
    getBets: (target: string, page: number = 1, limit: number = 10, result_type: string, amount_type: string): AxiosPromise => {
        let _query = (page <= 1) ? '' : `&page=${page}`;
        _query += (limit <= 1) ? '&limit=1' : `&limit=${limit}`;
        _query += (result_type) ? `&result_type=${result_type}` : '';
        _query += (amount_type) ? `&amount_type=${amount_type}` : '';

        return API({
            method: 'get',
            url: process.env.REACT_APP_API_V2_BET_URL + `/${target}/bets` + `?${_query}`,
            data: {
                result_type
            }
        }).then(( res ) => {
            return res;
        }).catch(( error ) => {
            return Promise.reject(error);
        });
    }
}

export default BetAPI_V2;
