import React, { useState, useEffect } from "react";

import AccountForm from "./AccountForm";

const AccountView = () => {
    
    return (
        <div className="contents">
            <div className="account">
                <AccountForm />
            </div>
        </div>
    )
}

export default AccountView