import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/reducers/rootReducer';
import { setAuth } from '../../redux/reducers/authReducer';
import { useForm, SubmitHandler } from "react-hook-form";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import { 
    Button, 
    Input, 
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Label,
    Row,
    Col
} from 'reactstrap';

import useModal from '../../hooks/GlobalModals';
import useSpinner from "../../hooks/GlobalSpinner";
import useNotify from "../../hooks/GlobalNotifies";

import { ISign } from "../../interfaces/SignX";

import InputControl from '../common/InputControl';

import SignXAPI from "../../modules/SignXAPI";
import BetAPI_V2 from "../../modules/BetAPI.v2";

import { removeCart, setCartItems, resetCart, setCartAmount, setCartAmountType } from '../../redux/reducers/betCartReducer';
import Util from '../../modules/Util';

const BetSlip = ({ ...props }: { [key: string]: any }) => {
    const navigate = useNavigate();

    const authState = useSelector((state: RootState) => state.authReducer);
    const betState = useSelector((state: RootState) => state.betReducer);
    const betCartState = useSelector((state: RootState) => state.betCartReducer);

    const dispatch = useDispatch();

    const [statusText, setStatusText] = useState<string>('');
    const [calcCart, setCalcCart] = useState<any>({
        betAmount: 0,
        totalRate: 0,
        totalAmount: 0
    });
    const [activeSlip, setActiveSlip] = useState<boolean>(false);

    const { modalAlert } = useModal();
    const { spinnerVisible } = useSpinner();
    const { notifyDefault } = useNotify();

    let defaultValues: ISign = {
        userid: '',
        password: ''
    };

    const { watch, handleSubmit, control, reset, formState: { errors, isSubmitting } } = useForm({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        defaultValues
    });

    const userid = watch('userid');

    const handleOnSubmit: SubmitHandler<any> = (data) => {
        SignXAPI.sign('in', data).then(( res ) => {
            if(res.data.success){
                const _message = res.data.message;

                dispatch(setAuth(_message));

                if(_message.memo_new > 0)
                    notifyDefault({
                        notifyKey: 'NewMemo',
                        component: <>읽지 않은 쪽지가 있습니다.</>
                    })

                reset({userid: ''});
            }else{
                reset({userid: userid});

                modalAlert({
                    component: <>{res.data.message}</>,
                    title: '로그인'
                });
            }
        })
        .catch(( error ) => {
            reset({userid: userid, password: ''});

            modalAlert({
                component: <>{error.message}</>,
                title: '로그인'
            });
        });
    }

    const handleRemoveCartItem = (uid: string) => {
        spinnerVisible(true);

        dispatch(removeCart(uid));

        let betCartItems = [ ...betCartState.items ];

        betCartItems = betCartItems.filter((item) => item.uid != uid);

        let filterBetCartItems = betCartItems.map((betCartItem) => ({
            status: betCartItem.status,
            uid_sport: betCartItem.uid_sport, 
            uid_event: betCartItem.uid_event, 
            uid: betCartItem.uid, 
            rate: betCartItem.rate 
        }));

        // inplay, upcoming !!!!!!!!!!!!!!!!!!
        if(filterBetCartItems.length > 0){
            BetAPI_V2.setCart({ target: betState.targetType, amountType: betCartState.amountType, amount: betCartState.amount, items: filterBetCartItems }).then((res) => {
                if(res.data.success){
                    filterBetCartItems = res.data.message;
                    
                    dispatch(setCartItems(filterBetCartItems));
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '베팅슬립'
                    });
                }

                spinnerVisible(false);
            }).catch(( error ) => {
                spinnerVisible(false);
                
                modalAlert({
                    component: <>{error.message}</>,
                    title: '베팅슬립'
                });
            });
        }else{
            spinnerVisible(false);
        }
    }

    const handleCartBet = () => {
        spinnerVisible(true)

        if(!authState.uuid){
            modalAlert({
                component: <>회원만 이용 가능합니다.</>,
                title: '알림'
            });
        }else{
            if(!betCartState.amount || betCartState.amount <= 0){
                modalAlert({
                    component: <>베팅금액을 입력해주세요.</>,
                    title: '베팅슬립'
                });
            }else{
                if(betState.targetType && betState.targetEvent && betState.targetEventUid){
                    const betCartItems = [ ...betCartState.items ];
                    const errorCartItems = betCartItems.filter((item) => item.status);
    
                    if(errorCartItems.length > 0){
                        modalAlert({
                            component: <>베팅 불가능한 폴더가 있습니다.</>,
                            title: '베팅슬립'
                        });
                    }else{
                        let filterBetCartItems = betCartItems.map((betCartItem) => ({
                            status: betCartItem.status,
                            uid_sport: betCartItem.uid_sport, 
                            uid_event: betCartItem.uid_event, 
                            uid: betCartItem.uid, 
                            rate: betCartItem.rate 
                        }));
    
                        BetAPI_V2.setBet({ target: betState.targetType, amountType: betCartState.amountType, amount: betCartState.amount, items: filterBetCartItems }).then((res) => {
                            if(res.data.success){                                
                                dispatch(resetCart());

                                setCalcCart((calcCart: any) => ({
                                    ...calcCart,
                                    totalRate: 0,
                                    totalAmount: 0
                                }))
    
                                modalAlert({
                                    component: <>베팅이 완료되었습니다.</>,
                                    title: '베팅슬립'
                                });
                            }else{
                                filterBetCartItems = res.data.message;
                    
                                dispatch(setCartItems(filterBetCartItems));

                                /*
                                modalAlert({
                                    component: <>{res.data.message}</>,
                                    title: '베팅슬립'
                                });
                                */
                            }
    
                            spinnerVisible(false);
                        }).catch(( error ) => {
                            spinnerVisible(false);
                            
                            modalAlert({
                                component: <>{error.message}</>,
                                title: '베팅슬립'
                            });
                        });
                    }
                }
            }
        }

        spinnerVisible(false);
    }

    const handleCartReset = () => {
        modalAlert({
            component: <>현재는 작업중입니다.</>,
            title: '전체취소'
        });
    }

    useEffect(() => {
        const betCartItems = [ ...betCartState.items ];

        if(betCartItems.length > 0){
            let filterStatusText = '';
            let sumRate = 0;
            let calcAmount = 0;

            for( const betCartItem of betCartItems ){
                sumRate = sumRate + Number(betCartItem.rate);

                switch(betCartItem.status){
                    case 'Use denied':
                        filterStatusText = '베팅할 수 없는 픽이 존재합니다.';
                        break;
                    case 'Timeout':
                        filterStatusText = '베팅 마감시간이 지난 픽이 존재합니다.';
                        break;
                    case 'Deadline':
                        filterStatusText = '마감시간이 지난 픽이 존재합니다.';
                        break;
                    case 'Before':
                        filterStatusText = '경기가 시작 전인 픽이 존재합니다.';
                        break;
                    case 'Changed':
                        filterStatusText = '배당이 변경된 픽이 존재합니다.';
                        break;
                    case '':
                        filterStatusText = '';
                        break;
                    default:
                        filterStatusText = '알 수 없는 오류입니다.';
                }
            }

            setStatusText(filterStatusText);

            if(calcCart.betAmount && calcCart.betAmount > 0)
                calcAmount = Math.floor((sumRate * calcCart.betAmount) / 100) * 100;

            setCalcCart((calcCart: any) => ({
                ...calcCart,
                totalRate: sumRate,
                totalAmount: calcAmount,
            }))

            // check items!!!
            let filterItems = betCartItems.map((betCartItem) => ({
                uid: betCartItem.uid,
                rate: betCartItem.rate
            }));
        }else{
            setCalcCart((calcCart: any) => ({
                ...calcCart,
                totalRate: 0
            }))
        }
    }, [betCartState])

    useEffect(() => {
        setCalcCart((calcCart: any) => ({
            ...calcCart,
            totalAmount: Math.floor((calcCart.totalRate * calcCart.betAmount) / 100) * 100
        }))
    }, [calcCart.betAmount, calcCart.totalRate])

    return (
        <>
            <div className={`right-site-menu ${activeSlip ? 'active':''}`}>
                <div className="right-title">
                    <div>
                        <i className="fas fa-folder-open"></i> Slip
                    </div>
                    <div 
                        className="close"
                        onClick={() => {setActiveSlip(false)}}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="right-box">
                    {!authState.uuid ? <div className="slip">
                        <div className="sign-box">
                            <Form className="form" onSubmit={handleSubmit(handleOnSubmit)}>
                            <FormGroup>
                                    <InputControl 
                                        control={control}
                                        name="userid"
                                        label="아이디"
                                        placeholder="아이디"
                                        rules={{
                                            pattern: /^[a-z,0-9]{3,30}$/
                                            //pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                                        }}
                                    />
                                    <FormFeedback>
                                        형식에 맞는 아이디를 입력해주세요.
                                    </FormFeedback>
                                    {/*
                                    <FormFeedback valid>
                                        That's a tasty looking email you've got there.
                                    </FormFeedback>
                                    <FormText>아이디 또는 이메일을 입력하세요.</FormText>
                                    */}
                                </FormGroup>
                                <FormGroup>
                                    <InputControl 
                                        control={control}
                                        type="password"
                                        name="password"
                                        label="비밀번호"
                                        placeholder='비밀번호'
                                        rules={{
                                            required: true,
                                            minLength: 6
                                        }}
                                    />
                                </FormGroup>
                                <br/>
                                <Button 
                                    className="btn-round"
                                    type="submit" 
                                    color="primary"
                                    disabled={isSubmitting} 
                                    block
                                >
                                    로그인
                                </Button>
                                <br/>
                                <div className="text-center">
                                    <a href="#" className="font-size-small">
                                        비밀번호를 분실하셨나요?
                                    </a>
                                </div>
                            </Form>
                        </div>
                    </div> : <div className="slip">
                        <div className="user-items">
                            <div className="left-info">
                                <i className="fas fa-user"></i>
                                <span className="text-blabce">{authState.userid} 님 환영합니다.</span>
                            </div>
                            <div className="right-info"></div>
                        </div>
                        <div className="balance-items cursor-pointer" onClick={(event) => navigate('/memos')}>
                            <div className="left-balance">
                                <i className="fas fa-envelope"></i>
                                <span className="text-blabce">새로운 쪽지</span>
                            </div>
                            <div className="right-balance">
                                {Util.getNumberFormat(authState.memo_new)} 개
                            </div>
                        </div>
                        <div className="balance-items">
                            <div className="left-balance">
                                <i className="fas fa-wallet"></i>
                                <span className="text-blabce">보유머니</span>
                            </div>
                            <div className="right-balance">
                                {Util.getNumberFormat(authState.amount)} 원
                            </div>
                        </div>
                        <div className="bonus-items">
                            <div className="left-bonus">
                                <i className="fas fa-plus"></i>
                                <span className="text-blabce">보유보너스</span>
                            </div>
                            <div className="right-bonus">
                                {Util.getNumberFormat(authState.amount_bonus)} P
                            </div>
                        </div>
                        <div className="quick-items">
                            <Link to="/board/notice" className="right-quick">
                                <i className="fas fa-list-alt"></i>
                                <span className="text-bets">공지사항</span>
                            </Link>
                            <Link to="/board/event" className="right-quick">
                                <i className="fas fa-list-alt"></i>
                                <span className="text-bets">이벤트</span>
                            </Link>
                            <Link to="/bettings" className="left-quick">
                                <i className="fas fa-user-clock"></i>
                                <span className="text-bets">베팅내역</span>
                            </Link>
                            <Link to="/amounts" className="right-quick">
                                <i className="fas fa-list-alt"></i>
                                <span className="text-bets">입출금내역</span>
                            </Link>
                            <Link to="/amount/deposit" className="left-quick">
                                <i className="fas fa-plus-square"></i>
                                <span className="text-bets">입금신청</span>
                            </Link>
                            <Link to="/amount/withdraw" className="right-quick">
                                <i className="fas fa-minus-square"></i>
                                <span className="text-bets">출금신청</span>
                            </Link>
                        </div>
                        <div className="combo-box">
                            {betCartState.items.map((item, key) => 
                                <div className={`close-box ${item.status ? 'warning':''}`} key={key}>
                                    <div className="close-items">
                                        <div className="close-head">
                                            <span>{item.event_name}</span>
                                            <span className="close" onClick={(event) => handleRemoveCartItem(item.uid)}>
                                                <i className="fas fa-times"></i>
                                            </span>
                                        </div>
                                        <div className="match-fixing">
                                            <div className="match-items">
                                                <div className="match-items-left">
                                                    <div className="cont">
                                                        <span> 
                                                            {item.handicap_formatted ? item.handicap_formatted + ' ' : ''}
                                                            {item.odds_name? `${item.market_name} ${item.odds_name}` : item.market_name} 
                                                        </span>
                                                        <span className="winner">{item.market_group_name}</span>
                                                    </div>
                                                </div>
                                                <div className="match-items-right">
                                                    <div className="cont">
                                                        <span>{Number(item.rate).toFixed(2)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {statusText && 
                                <div className="status-items">{statusText}</div>
                            }

                            <div className="amount-items">
                                <div className='amount_type'>
                                    <Row>
                                        <Col>
                                            <div>
                                                <FormGroup check>
                                                    <Input
                                                        id="amount_type_1"
                                                        name="amount_type"
                                                        type="radio"
                                                        checked={(betCartState.amountType == 'amount')}
                                                        value="amount"
                                                        onChange={(event) => {
                                                            if(event.currentTarget.checked)
                                                                dispatch(setCartAmountType(event.currentTarget.value));
                                                        }}
                                                    />
                                                    {' '}
                                                    <Label check for="amount_type_1">
                                                        머니
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div>
                                                <FormGroup check>
                                                    <Input
                                                        id="amount_type_2"
                                                        name="amount_type"
                                                        type="radio"
                                                        checked={(betCartState.amountType == 'bonus')}
                                                        value="bonus"
                                                        onChange={(event) => {
                                                            if(event.currentTarget.checked)
                                                                dispatch(setCartAmountType(event.currentTarget.value));
                                                        }}
                                                    />
                                                    {' '}
                                                    <Label check for="amount_type_2">
                                                        보너스
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='amount'>
                                    <Input 
                                        type="number"
                                        placeholder="베팅금액"
                                        value={betCartState.amount}
                                        onChange={(event) => {
                                            dispatch(setCartAmount(Number(event.target.value)));

                                            setCalcCart((calcCart: any) => {
                                                return {
                                                    ...calcCart,
                                                    betAmount: Number(event.target.value)
                                                }
                                            });
                                        }}
                                    />
                                    <button 
                                        type="button"
                                        onClick={(event) => {
                                            let targetAmount = 0;

                                            switch(betCartState.amountType){
                                                case 'bonus':
                                                    targetAmount = Number(authState.amount_bonus);
                                                    break;
                                                default:
                                                    targetAmount = Number(authState.amount);
                                            }

                                            dispatch(setCartAmount(targetAmount));

                                            setCalcCart((calcCart: any) => {
                                                return {
                                                    ...calcCart,
                                                    betAmount: targetAmount
                                                }
                                            });
                                        }}
                                    >
                                        Max
                                    </button>
                                </div>
                            </div>
                            <div className="total-items">
                                <div className="total-item">
                                    <div className="left-balance">
                                        <span className="text-blabce">총 배당</span>
                                    </div>
                                    <div className="right-balance">
                                        {Number(calcCart.totalRate).toFixed(2)}
                                    </div>
                                </div>
                                <div className="total-item">
                                    <div className="left-balance">
                                        <span className="text-blabce">총 베팅금액</span>
                                    </div>
                                    <div className="right-balance">
                                        {Util.getNumberFormat(calcCart.betAmount)} 원
                                    </div>
                                </div>
                            </div>
                            <div className="possible-win">
                                <span>예상 적중금액</span>
                                <span className="amount">{Util.getNumberFormat(calcCart.totalAmount)} 원</span>
                            </div>
                            <Button
                                className="btn-round d-block w-100"
                                type="button" 
                                color="primary" 
                                onClick={(event) => handleCartBet()}
                            >
                                <i className="fas fa-coins"></i> 베팅하기
                            </Button>
                            <Button
                                className="btn-round d-block w-100"
                                type="button" 
                                color="secondary" 
                                onClick={(event) => handleCartReset()}
                            >
                                <i className="fas fa-times"></i> 전체취소
                            </Button>
                        </div>
                    </div>}
                </div>
            </div>
            <div className={`slip-toggle ${activeSlip ? 'active':''}`}>
                <Button
                    className="btn-round"
                    type="button" 
                    color="primary" 
                    onClick={() => {setActiveSlip(true)}}
                >
                    <i className="fas fa-folder-open"></i>
                </Button>
            </div>
            <div className={`slip-backdrop ${activeSlip ? 'active':''}`}></div>
        </>
    );
};

export default BetSlip