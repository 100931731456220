import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from '../../redux/reducers/rootReducer';
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { 
    Row, 
    Col, 
    Card, 
    CardHeader,
    CardTitle,
    CardBody,
    Form,
    FormFeedback,
    FormGroup,
    Button
} from 'reactstrap';

import useModal from "../../hooks/GlobalModals";
import AmountAPI from "../../modules/AmountAPI";

import InputControl from '../common/InputControl';

import { IAccount } from "../../interfaces/Account";

import profileDefaultImage from "../../assets/img/profile-default.svg";
import { IAmountWithdraw } from "../../interfaces/Amount";
import Util from "../../modules/Util";


const AmountWithdrawFrom = () => {
    const { uuid } = useParams();

    const navigate = useNavigate();

    const authState = useSelector((state: RootState) => state.authReducer)
    const configState = useSelector((state: RootState) => state.configReducer);

    const [roleOptions, setRoleOptions] = useState<{value: any, label: any}[]>([]);
    const [passwordRequired, setPasswordRequired] = useState<boolean>(true);    

    const { modalAlert } = useModal();

    const location = useLocation();

    let defaultValues: IAmountWithdraw = {
        amount: 0,
        password: ''
    };

    const { watch, handleSubmit, control, reset, getValues, setFocus, setError, formState } = useForm<IAmountWithdraw>({
        mode: 'onChange', 
        reValidateMode: 'onSubmit', 
        shouldFocusError: true, 
        defaultValues
    });

    const [
        amount,
        password
    ] = watch(['amount', 'password']);

    useEffect(() => {
        
    }, [location]);

    const handleAddAmount = (value: number) => {
        const formValues = getValues();

        reset({ ...formValues, amount: formValues.amount + value });
    }

    const handleResetAmount = () => {
        const formValues = getValues();

        reset({ ...formValues, amount: 0, password: '' });
    }

    const handleOnSubmit: SubmitHandler<IAmountWithdraw> = (formData) => {
        let { amount, password, ...formParams} = formData

        if(amount <= 0){
            modalAlert({
                component: <>출금액을 정확하게 입력해주세요.</>,
                title: '출금신청'
            })
        }else if(!password){
            modalAlert({
                component: <>출금 비밀번호를 정확하게 입력해주세요.</>,
                title: '출금신청'
            })
        }else{
            AmountAPI.requestWithdraw({ amount, password }).then((res) => {
                if(res.data.success){
                    modalAlert({
                        component: <>출금신청이 완료되었습니다.</>,
                        title: '출금신청'
                    });

                    navigate('/amounts');
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '출금신청'
                    });
                }
            }).catch((error) => {
                const res = error.response;
    
                if(res){
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '출금신청'
                    });
                }
            })
        }
    }
    
    return (
        <div className="amount-form">
            <div className="title">
                <div>출금신청</div>
            </div>
            <div className="guide">
                <div>
                    <ul>
                        <li>- 출금은 최소 10,000원이상 출금신청 해주시기 바랍니다.</li>
                        <li>- 계정정보에 설정된 계좌로만 출금이 가능합니다.</li>
                    </ul>
                </div>
            </div>
            <Form onSubmit={handleSubmit(handleOnSubmit)}>
                <Row>
                    <Col>
                        <Card className="card-form">
                            <CardBody>
                                <table className="table">
                                    <colgroup>
                                        <col width="30%"/>
                                        <col />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th scope="row">보유머니</th>
                                            <td>{Util.getNumberFormat(authState.amount)} 원</td>
                                        </tr>
                                        <tr className="align-middle">
                                            <th scope="row">출금 비밀번호</th>
                                            <td>
                                                <InputControl 
                                                    control={control}
                                                    type="password"
                                                    name="password"
                                                    placeholder="출금 비밀번호"
                                                />
                                            </td>
                                        </tr>
                                        <tr className="align-middle">
                                            <th scope="row">출금액</th>
                                            <td>
                                                <FormGroup>
                                                    <InputControl 
                                                        control={control}
                                                        type="number"
                                                        name="amount"
                                                        placeholder="출금신청 금액"
                                                    />
                                                </FormGroup>
                                                <div>
                                                    <Button
                                                        type="button" 
                                                        color="secondary" 
                                                        onClick={(event) => handleAddAmount(10000)}
                                                    >
                                                        1만원
                                                    </Button>
                                                    <Button
                                                        type="button" 
                                                        color="secondary" 
                                                        onClick={(event) => handleAddAmount(50000)}
                                                    >
                                                        5만원
                                                    </Button>
                                                    <Button
                                                        type="button" 
                                                        color="secondary" 
                                                        onClick={(event) => handleAddAmount(100000)}
                                                    >
                                                        10만원
                                                    </Button>
                                                    <Button
                                                        type="button" 
                                                        color="secondary" 
                                                        onClick={(event) => handleAddAmount(500000)}
                                                    >
                                                        50만원
                                                    </Button>
                                                    <Button
                                                        type="button" 
                                                        color="secondary" 
                                                        onClick={(event) => handleAddAmount(1000000)}
                                                    >
                                                        100만원
                                                    </Button>
                                                    <Button
                                                        type="button" 
                                                        color="secondary" 
                                                        onClick={(event) => handleAddAmount(5000000)}
                                                    >
                                                        500만원
                                                    </Button>
                                                    <Button
                                                        type="button" 
                                                        color="tertiary" 
                                                        onClick={(event) => handleResetAmount()}
                                                    >
                                                        정정하기
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <Row>
                                    <Col className="text-center pt-4 pb-4">
                                        <Button
                                            className="btn-round"
                                            type="submit"
                                            color="primary"
                                            disabled={formState.isSubmitting} 
                                        >
                                            출금신청
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default AmountWithdrawFrom