import React from "react";
import { useSelector } from "react-redux";
import { Container, Row } from "reactstrap";

import { RootState } from "../../redux/reducers/rootReducer";

import sponsor_logo_1 from "../../assets/img/Evolution.png";
import sponsor_logo_2 from "../../assets/img/Best.png";
import sponsor_logo_3 from "../../assets/img/Dream.png";
import sponsor_logo_4 from "../../assets/img/Sexy.png";
import sponsor_logo_5 from "../../assets/img/Asia.png";
import sponsor_logo_6 from "../../assets/img/Vivo.png";
import sponsor_logo_7 from "../../assets/img/Haba.png";
import sponsor_logo_8 from "../../assets/img/Micro.png";
import sponsor_logo_9 from "../../assets/img/Oriental.png";
import sponsor_logo_10 from "../../assets/img/Play.png";
import sponsor_logo_11 from "../../assets/img/Prag.png";
import sponsor_logo_12 from "../../assets/img/RTG.png";
import sponsor_logo_13 from "../../assets/img/Evo.png";
import sponsor_logo_14 from "../../assets/img/Soft.png";
import sponsor_logo_15 from "../../assets/img/Taishan.png";
import sponsor_logo_16 from "../../assets/img/Game.png";

const Footer = () => {
    const configState = useSelector((state: RootState) => state.configReducer);

    return (
        <>
            <div className="sponsor-section">
                <div>
                    <img src={sponsor_logo_1} alt="Evolution" />
                </div>
                <div>
                    <img src={sponsor_logo_2} alt="Best" />
                </div>
                <div>
                    <img src={sponsor_logo_3} alt="Dream" />
                </div>
                <div>
                    <img src={sponsor_logo_4} alt="Sexy" />
                </div>
                <div>
                    <img src={sponsor_logo_5} alt="Asia" />
                </div>
                <div>
                    <img src={sponsor_logo_6} alt="Vivo" />
                </div>
                <div>
                    <img src={sponsor_logo_7} alt="Haba" />
                </div>
                <div>
                    <img src={sponsor_logo_8} alt="Micro" />
                </div>
                <div>
                    <img src={sponsor_logo_10} alt="Oriental" />
                </div>
                <div>
                    <img src={sponsor_logo_9} alt="Play" />
                </div>
                <div>
                    <img src={sponsor_logo_11} alt="Prag" />
                </div>
                <div>
                    <img src={sponsor_logo_12} alt="RTG" />
                </div>
                <div>
                    <img src={sponsor_logo_13} alt="Evo" />
                </div>
                <div>
                    <img src={sponsor_logo_14} alt="Soft" />
                </div>
                <div>
                    <img src={sponsor_logo_15} alt="Taishan" />
                </div>
                <div>
                    <img src={sponsor_logo_16} alt="Game" />
                </div>
            </div>
            <footer className="footer-section">
                <div className="container">
                    <div className="footer-wrapper">
                        <p>
                            Copyright 2022 <a href="#0" className="text-base">{configState.site_name}</a> All Rights Reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer