import { IDefaultNotify } from "../interfaces/GlobalNotifies";

export type TGlobalNotify = IDefaultNotify;

export type TGlobalNotifies = {
    notifiesVisible: boolean;
    items: TGlobalNotify[];
};

export enum TGlobalNotifiesEnum {
    DEFAULT = "default"
}