import { useDispatch } from "react-redux";

import { setDefault } from "../redux/reducers/notifyReducer";

import { IDefaultNotifyProps } from "../interfaces/GlobalNotifies";

const useNotify = () => {
    const dispatch = useDispatch();

    const notifyDefault = (props: IDefaultNotifyProps) => {
        dispatch(setDefault(true, props));
    };

    return { notifyDefault };
}

export default useNotify;