import React, { useRef, useState, useEffect, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { removeCart, setCartItem, setCartItems } from "../../redux/reducers/betCartReducer";
import { Card, CardBody, Input, Button, Row, Col, Container } from 'reactstrap';

import useModal from "../../hooks/GlobalModals";
import useSpinner from "../../hooks/GlobalSpinner";

import { IUpcomingMarketsItem, IUpcomingMarketsItemProps } from "../../interfaces/Upcoming";
import BetAPI from "../../modules/BetAPI";
import BetAPI_V2 from "../../modules/BetAPI.v2";

const UpcomingMarketsItem = (props: IUpcomingMarketsItemProps) => {
    const { item } = props;

    const authState = useSelector((state: RootState) => state.authReducer);
    const betState = useSelector((state: RootState) => state.betReducer);
    const betCartState = useSelector((state: RootState) => state.betCartReducer);

    const { modalAlert } = useModal();
    const { spinnerVisible } = useSpinner();

    const [ upcomingMarketsItem, setUpcomingMarketsItem ] = useState<IUpcomingMarketsItem | null>(null);
    const [ activeMarketItems, setActiveMarketItems ] = useState<string[]>([]);

    const dispatch = useDispatch();

    useEffect(() => {
        const betCartItems = [ ...betCartState.items ];
        const betCartItemUids = betCartItems.map((betCartItem) => betCartItem.uid);

        const filterMarketGroupName = item.name_kr ? item.name_kr : item.name;

        let filterItem = { ...item };

        if(item.markets && item.markets.length > 0){
            let filterMarkets = item.markets.map((market: any) => {
                if(market.odds && market.odds.length > 0){
                    const filterMarketName = market.name_kr ? market.name_kr : market.name;

                    let filterOdds = market.odds.map((odd: any) => {
                        let isActive = betCartItemUids.includes(odd.uid);
        
                        return { ...odd, market_group_name: filterMarketGroupName, market_name: filterMarketName, active: isActive }
                    });

                    return { ...market, odds: filterOdds}
                }else{
                    return market;
                }
            })

            filterItem = { ...filterItem, markets: filterMarkets };
        }else{
            if(item.odds && item.odds.length > 0){
                let filterOdds = item.odds.map((odd: any) => {
                    let isActive = betCartItemUids.includes(odd.uid);
    
                    return { ...odd, market_group_name: filterMarketGroupName, market_name: '', active: isActive }
                });

                filterItem = { ...filterItem, odds: filterOdds };
            }
        }

        //console.log('filterItem', filterItem)

        setUpcomingMarketsItem({ ...filterItem })

    }, [item, betCartState])

    const renderMarket = (market_group_channel: number, market: any, key: any) => {
        const filterMarketName = market.name_kr ? market.name_kr : market.name;

        if(market.style_participant === '_a' && market.style == 'dcs'){
            return (
                <React.Fragment key={key} />
            )
        }else if(market.style_participant === 'dt' || market.style_participant === '_d' || market.style_participant === '_D' || market.style_participant === '_c' || market.style_participant === '_C' || market.style_participant === '_e'){
            if(filterMarketName && market.odds.length > 0)
                return (
                    <div className="market" key={key}>
                        {filterMarketName && 
                            <div className="name">
                                {(filterMarketName && filterMarketName != ' ') ? filterMarketName : '\u00A0'}{/* / {market.uid} / {market.key} / {market.channel} / {market.style_participant} / {market.style} */}
                            </div>
                        }

                        {renderOdds(market_group_channel, market.channel, market.style_participant, market.style, market.odds)}
                    </div>
                )
        }else if(market.style_participant === 'da' || market.style_participant === 'de' || market.style_participant === 'dn' || market.style_participant === 'is' || market.style_participant === 'dc' || market.style_participant === 'db'){
            return (
                <div className={`market ${(['da', 'dc', 'daa', 'db'].includes(market.style)) ? 'hd':''}`} key={key}>
                    <div className="name">
                        {(filterMarketName && filterMarketName != ' ') ? filterMarketName : '\u00A0'}{/* / {market.uid} / {market.key} / {market.channel} / {market.style_participant} / {market.style} */}
                    </div>

                    {renderOdds(market_group_channel, market.channel, market.style_participant, market.style, market.odds)}
                </div>
            )
        }else if(market.style_participant === 'ib'){
            return (
                <div className="market" key={key}>
                    {filterMarketName && 
                        <div className="name">
                            {(filterMarketName && filterMarketName != ' ') ? filterMarketName : '\u00A0'}{/* / {market.uid} / {market.key} / {market.channel} / {market.style_participant} / {market.style} */}
                        </div>
                    }

                    {renderOdds(market_group_channel, market.channel, market.style_participant, market.style, market.odds)}
                </div>
            )
        }else{
            return (
                <div className="market" key={key}>
                    {filterMarketName && 
                        <div className="name">
                            {(filterMarketName && filterMarketName != ' ') ? filterMarketName : '\u00A0'}{/* / {market.uid} / {market.key} / {market.channel} / {market.style_participant} / {market.style} */}
                        </div>
                    }

                    {renderOdds(market_group_channel, market.channel, market.style_participant, market.style, market.odds)}
                </div>
            )
        }
    }

    const renderOdds = (market_group_channel: number, market_channel: number, style_participant: string, style: string, odds: any[]) => {
        const _onClick = (e: any) => {
            spinnerVisible(true)

            if(!authState.uuid){
                modalAlert({
                    component: <>회원만 이용 가능합니다.</>,
                    title: '알림'
                });

                spinnerVisible(false);
            }else{ 
                if(betState.targetEvent && betState.targetEventUid){
                    const key = e.currentTarget.dataset.key;
                    const targetOdd = odds.find((odd) => odd.uid == key);

                    let betCartItems = [ ...betCartState.items ];

                    if(targetOdd.active){
                        dispatch(removeCart(key));

                        betCartItems = betCartItems.filter((item) => item.uid != key);
                    }

                    const targetEvent = betState.targetEvent;
                    const targetSport = targetEvent.sport;
                    
                    const duplMarketGroupItem = betCartItems.find((item) => {
                        return ((item.uid_event == targetEvent.uid) && (item.uid_market_group == targetOdd.uid_market_group))
                    })

                    if(duplMarketGroupItem) 
                        betCartItems = betCartItems.filter((item) => item.uid != duplMarketGroupItem.uid);
                        //dispatch(removeCart(duplMarketGroupItem.uid));

                    let filterBetCartItems = betCartItems.map((betCartItem) => ({
                        status: betCartItem.status,
                        uid_sport: betCartItem.uid_sport, 
                        uid_event: betCartItem.uid_event, 
                        uid: betCartItem.uid, 
                        rate: betCartItem.rate 
                    }));

                    if(!targetOdd.active){
                        let filterBetCartItem = { 
                            status: '', 
                            uid_sport: targetSport.uid, 
                            uid_event: targetEvent.uid, 
                            uid: targetOdd.uid, 
                            rate: targetOdd.rate 
                        };

                        filterBetCartItems.push(filterBetCartItem);
                    }

                    if(filterBetCartItems.length > 0){
                        BetAPI_V2.setCart({ target: 'upcoming', amountType: betCartState.amountType, amount: betCartState.amount, items: filterBetCartItems }).then((res) => {
                            if(res.data.success){
                                filterBetCartItems = res.data.message;

                                console.log('filterBetCartItems', filterBetCartItems)
                                
                                dispatch(setCartItems(filterBetCartItems));
                            }else{
                                modalAlert({
                                    component: <>{res.data.message}</>,
                                    title: '베팅슬립'
                                });
                            }

                            spinnerVisible(false);
                        }).catch(( error ) => {
                            spinnerVisible(false);
                            
                            modalAlert({
                                component: <>{error.message}</>,
                                title: '베팅슬립'
                            });
                        });
                    }else{
                        spinnerVisible(false);
                    }
                }
            }

            //spinnerVisible(false)
        }

        if(style == '_a' || style == 'ibm' || style == 'dz' || style == 'mgs'){
            if(!style_participant || style_participant == 'du' || style_participant == 'dk' || style_participant == 'DK' || style_participant == 'dl' || style_participant == '_f'){
                if(market_group_channel != 1){
                    return (
                        <div className="odds channel">
                            {odds.map((odd, key) => {
                                const filterOddName = odd.name_kr ? odd.name_kr : odd.name;
                                const filterOddHandicapFormatted = odd.handicap_formatted_kr ? odd.handicap_formatted_kr : odd.handicap_formatted;

                                return (
                                    <div key={key} className={`odd-target type-2 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} {...((odd.rate != '0.00' && !odd.suspended) && {onClick: _onClick})} data-key={odd.uid}>
                                        <div className="odd">
                                            <div className="name">{filterOddName ? filterOddName : ''} {!filterOddHandicapFormatted ? '' : filterOddName ? '\u00A0' + filterOddHandicapFormatted : filterOddHandicapFormatted}</div>
                                            <div className="rate">
                                                {odd.suspended 
                                                    ? <i className="fas fa-lock"></i>
                                                    : (odd.rate != null && odd.rate != '0.00') ? (odd.rate).toFixed(2) : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    );
                }else{
                    return (
                        <div className="odds">
                            {odds.map((odd, key) => {
                                const filterOddName = odd.name_kr ? odd.name_kr : odd.name;
                                const filterOddHandicapFormatted = odd.handicap_formatted_kr ? odd.handicap_formatted_kr : odd.handicap_formatted;

                                return (
                                    <div key={key} className={`odd-target type-2 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} {...((odd.rate != '0.00' && !odd.suspended) && {onClick: _onClick})} data-key={odd.uid}>
                                        <div className="odd">
                                            <div className="name">{filterOddName ? filterOddName : ''} {!filterOddHandicapFormatted ? '' : filterOddName ? '\u00A0' + filterOddHandicapFormatted : filterOddHandicapFormatted}</div>
                                            <div className="rate">
                                                {odd.suspended 
                                                    ? <i className="fas fa-lock"></i>
                                                    : (odd.rate != null && odd.rate != '0.00') ? (odd.rate).toFixed(2) : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    );
                }
            }else{
                return (
                    <div className="odds">
                        {odds.map((odd, key) => {
                            const filterOddName = odd.name_kr ? odd.name_kr : odd.name;
                            const filterOddHandicapFormatted = odd.handicap_formatted_kr ? odd.handicap_formatted_kr : odd.handicap_formatted;

                            return (
                                <div key={key} className={`odd-target type-2 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} {...((odd.rate != '0.00' && !odd.suspended) && {onClick: _onClick})} data-key={odd.uid}>
                                    <div className="odd">
                                        <div className="name">{filterOddName ? filterOddName : ''} {!filterOddHandicapFormatted ? '' : filterOddName ? '\u00A0' + filterOddHandicapFormatted : filterOddHandicapFormatted}</div>
                                        <div className="rate">
                                            {odd.suspended 
                                                ? <i className="fas fa-lock"></i>
                                                : (odd.rate != null && odd.rate != '0.00') ? (odd.rate).toFixed(2) : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                );
            }
        }else if(style == 'ib'){
            return (
                <div className="odds">
                    {odds.map((odd, key) => {
                        const filterOddName = odd.name_kr ? odd.name_kr : odd.name;

                        return (
                            <div key={key} className={`odd-target type-3 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} {...((odd.rate != '0.00' && !odd.suspended) && {onClick: _onClick})} data-key={odd.uid}>
                                <div className="odd">
                                    <div className="name">{filterOddName}</div>
                                    <div className="rate">
                                        {odd.suspended 
                                            ? <i className="fas fa-lock"></i>
                                            : (odd.rate != null && odd.rate != '0.00') ? (odd.rate).toFixed(2) : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            );
        }else if(style =='dc' || style =='dg'){
            if(style_participant == '_e'){
                return (
                    <div className="odds">
                        {odds.map((odd, key) => {
                            const filterOddName = odd.name_kr ? odd.name_kr : odd.name;
                            const filterOddHandicapFormatted = odd.handicap_formatted_kr ? odd.handicap_formatted_kr : odd.handicap_formatted;

                            return (
                                <div key={key} className={`odd-target type-2 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} {...((odd.rate != '0.00' && !odd.suspended) && {onClick: _onClick})} data-key={odd.uid}>
                                    <div className="odd">
                                        <div className="name">{filterOddName ? filterOddName : ''} {!filterOddHandicapFormatted ? '' : filterOddName ? '\u00A0' + filterOddHandicapFormatted : filterOddHandicapFormatted}</div>
                                        <div className="rate">
                                            {odd.suspended 
                                                ? <i className="fas fa-lock"></i>
                                                : (odd.rate != null && odd.rate != '0') ? (odd.rate).toFixed(2) : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                );
            }else{
                return (
                    <div className="odds">
                        {odds.map((odd, key) => {
                            const filterOddName = odd.name_kr ? odd.name_kr : odd.name;

                            return (
                                <div key={key} className={`odd-target type-1 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} {...((odd.rate != '0.00' && !odd.suspended) && {onClick: _onClick})} data-key={odd.uid}>
                                    <div className="odd">
                                        {(!filterOddName && (odd.rate == null || odd.rate == '0')) && <div>&nbsp;</div>}
                                        {filterOddName && <div className="name">{filterOddName}</div>}
                                        {(odd.rate != null && odd.rate != '0.00') && 
                                            <div className="rate">
                                                {odd.suspended 
                                                    ? <i className="fas fa-lock"></i>
                                                    : (odd.rate != '0.00') ? (odd.rate).toFixed(2) : '\u00A0'
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                );
            }
        }else if(style =='da' || style =='db' || style =='db' || style =='dx'){
            if(style_participant == '_d'){
                return (
                    <div className="odds">
                        {odds.map((odd, key) => {
                            const filterOddName = odd.name_kr ? odd.name_kr : odd.name;

                            return (
                                <div key={key} className={`odd-target type-1 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} {...((odd.rate != '0.00' && !odd.suspended) && {onClick: _onClick})} data-key={odd.uid}>
                                    <div className="odd">
                                        {(!filterOddName && (odd.rate == null || odd.rate == '0')) && <div>&nbsp;</div>}
                                        {filterOddName && <div className="name">{filterOddName}</div>}
                                        {(odd.rate != null && odd.rate != '0.00') && 
                                            <div className="rate">
                                                {odd.suspended 
                                                    ? <i className="fas fa-lock"></i>
                                                    : (odd.rate != '0.00') ? (odd.rate).toFixed(2) : '\u00A0'
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                );
            }else{
                return (
                    <div className="odds">
                        {odds.map((odd, key) => {
                            const filterOddName = odd.name_kr ? odd.name_kr : odd.name;
                            const filterOddHandicapFormatted = odd.handicap_formatted_kr ? odd.handicap_formatted_kr : odd.handicap_formatted;

                            return (
                                <div key={key} className={`odd-target type-2 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} {...((odd.rate != '0.00' && !odd.suspended) && {onClick: _onClick})} data-key={odd.uid}>
                                    <div className="odd">
                                        <div className="name">{filterOddName ? filterOddName : ''} {!filterOddHandicapFormatted ? '' : filterOddName ? '\u00A0' + filterOddHandicapFormatted : filterOddHandicapFormatted}</div>
                                        {
                                            (odd.result_text == null || odd.result_text == '') 
                                                ? <div className="rate">
                                                    {odd.suspended 
                                                        ? <i className="fas fa-lock"></i>
                                                        : (odd.rate != null && odd.rate != '0.00') ? (odd.rate).toFixed(2) : ''
                                                    }
                                                </div> 
                                                : <div className="desc">{odd.result_text}</div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                );
            }
        }else if(style == '_v'){
            return (
                <div className="odds">
                    {odds.map((odd, key) => {
                        const filterOddName = odd.name_kr ? odd.name_kr : odd.name;

                        return (
                            <div key={key} className={`odd-target type-1 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} {...((odd.rate != '0.00' && !odd.suspended) && {onClick: _onClick})} data-key={odd.uid}>
                                <div className="odd">
                                    {(!filterOddName && (odd.rate == null || odd.rate == '0')) && <div>&nbsp;</div>}
                                    {filterOddName && <div className="name">{filterOddName}</div>}
                                    {(odd.rate != null && odd.rate != '0.00') && 
                                        <div className="rate">
                                            {odd.suspended 
                                                ? <i className="fas fa-lock"></i>
                                                : (odd.rate != '0.00') ? (odd.rate).toFixed(2) : '\u00A0'
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            );
        }else{
            return ( // ??
                <div className="odds">
                    {odds.map((odd, key) => {
                        const filterOddName = odd.name_kr ? odd.name_kr : odd.name;

                        return (
                            <div key={key} className={`odd-target type-1 ${odd.active ? 'active':''} ${odd.suspended ? 'disable':''}`} {...((odd.rate != '0.00' && !odd.suspended) && {onClick: _onClick})} data-key={odd.uid}>
                                <div className="odd">
                                    {(!filterOddName && (odd.rate == null || odd.rate == '0')) && <div>&nbsp;</div>}
                                    {filterOddName && <div className="name">{filterOddName}</div>}
                                    {(odd.rate != null && odd.rate != '0') && 
                                        <div className="rate">
                                            {odd.suspended 
                                                ? <i className="fas fa-lock"></i>
                                                : (odd.rate != '0') ? (odd.rate).toFixed(2) : '\u00A0'//.toFixed(2) : '\u00A0'
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            );
        }
    }

    const toggleMarketGroup = (event: MouseEvent) => event.currentTarget.parentElement!.classList.toggle('fold');

    return (
        <React.Fragment>
            {upcomingMarketsItem != null &&
                <div className={`market-group ${upcomingMarketsItem.folded ? 'fold':''}`}>
                    <div className="name" onClick={toggleMarketGroup}>
                        <div>{upcomingMarketsItem.name_kr ? upcomingMarketsItem.name_kr : upcomingMarketsItem.name}</div>
                        <div className="toggle">
                            <i className="fas fa-chevron-down"></i>
                            <i className="fas fa-chevron-up"></i>
                        </div>
                    </div>
                    <div className="markets">
                        {upcomingMarketsItem.markets && upcomingMarketsItem.markets.map((market, key) => {
                            const marketGroupChannel = upcomingMarketsItem.channel ? upcomingMarketsItem.channel : 0;

                            return renderMarket(marketGroupChannel, market, key);
                        })}

                        {!upcomingMarketsItem.markets && (() => {
                            const marketGroupChannel = upcomingMarketsItem.channel ? upcomingMarketsItem.channel : 0;
                            const market: any = upcomingMarketsItem;

                            return (
                                <div className="market">
                                    {renderOdds(marketGroupChannel, market.channel, market.style_participant, market.style, market.odds)}
                                </div>
                            );
                        })()}
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default UpcomingMarketsItem
