import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner } from 'reactstrap';
import moment from "moment";

import { RootState } from '../../redux/reducers/rootReducer';

import { setSports, setUpcoming } from "../../redux/reducers/betReducer";

import useModal from "../../hooks/GlobalModals";

import UpcomingItem from "./UpcomingItem";
import UpcomingMarketsItem from "./UpcomingMarketsItem";

import BetAPI from "../../modules/BetAPI";
import BetAPI_V2 from "../../modules/BetAPI.v2";

import { ITargetUpcoming, IUpcomingItem } from "../../interfaces/Upcoming";
import { IBetItemSport } from "../../interfaces/Bet";
import { resetCart } from "../../redux/reducers/betCartReducer";

const UpcomingsView = () => {
    const authState = useSelector((state: RootState) => state.authReducer);
    const configState = useSelector((state: RootState) => state.configReducer);
    const betState = useSelector((state: RootState) => state.betReducer);

    const navigate = useNavigate();

    const { modalAlert } = useModal();

    const dispatch = useDispatch();

    const [ isLoadingEvents, setIsLoadingEvents ] = useState<boolean>(false);
    const [ isLoadingMarkets, setIsLoadingMarkets ] = useState<boolean>(false);

    const [ upcomings, setUpcomings ] = useState<{ [key: string]: ITargetUpcoming }>({});
    const [ targetUpcomings, setTargetUpcomings ] = useState<ITargetUpcoming[]>([]);
    const [ targetEventUid, setTargetEventUid ] = useState<string>('');
    const [ targetEvent, setTargetEvent ] = useState<any>(null);
    const [ targetMarkets, setTargetMarkets ] = useState<any[]>([]);

    const [ total, setTotal ] = useState<number>(0);
    const [ page, setPage ] = useState<number>(1);
    const [ limit, setLimit ] = useState<number>(50);

    const [ { prevKeyword, keyword }, setSearchForms ] = useState({
        prevKeyword: '',
        keyword: ''
    });

    const default_image = `/images/${betState.targetSportUid}.svg`;

    /*
    useEffect(() => {
        console.log('betState 2', betState)
    }, [betState])
    */

    useEffect(() => {
        if(!authState.uuid && configState['upcoming_bet_used_signin']){
            modalAlert({
                component: <>회원만 이용 가능합니다.</>,
                title: '알림'
            });

            navigate('/', {replace: true});
        }

        setIsLoadingEvents(true);
        setIsLoadingMarkets(true);

        if(prevKeyword != keyword && page <= 1) setPage(1);

        const delay = setTimeout(() => {
            if(betState.targetSportUid){
                BetAPI_V2.getUpcomings(betState.targetSportUid, 1, limit).then(( res ) => {
                    const { total: upcomingTotal, events: upcomingData} = res.data.message;

                    //console.log('upcomingData', upcomingData)

                    if(upcomingData){                        
                        const sportKeys = Object.keys(upcomingData);

                        if(sportKeys.length <= 0){
                            // Nothing Data
                        }else{
                            let tempUpcomings: { [key: string]: ITargetUpcoming } = {};
                            let tempLeagueOrder = 0;

                            for( const sportKey of sportKeys ){
                                if(sportKey == betState.targetSportUid){
                                    for( const upcomingKey in upcomingData[sportKey] ){
                                        const upcoming = upcomingData[sportKey][upcomingKey];

                                        const home = (upcoming.team_home.name_kr) ? upcoming.team_home.name_kr : upcoming.team_home.name;
                                        const away = (upcoming.team_away.name_kr) ? upcoming.team_away.name_kr : upcoming.team_away.name;

                                        const { order: targetSportOrder, ...targetSport } = upcoming.sport;
                                        const { order: targetLeagueOrder, ...targetLeague } = upcoming.league;

                                        const filterEvent = { 
                                            uid: upcoming.uid, 
                                            key: upcoming.key, 
                                            sport: upcoming.sport, 
                                            league: upcoming.league, 
                                            time: moment.unix(upcoming.start_time).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss'), 
                                            score: upcoming.scores, 
                                            home, 
                                            away, 
                                            images: [ upcoming.team_home.image_id, upcoming.team_away.image_id ], 
                                            markets: upcoming.markets, 
                                            order: upcoming.order 
                                        }
                                        
                                        if(!tempUpcomings[targetLeague.uid]){
                                            tempUpcomings[targetLeague.uid] = { 
                                                sport: targetSport,
                                                league: targetLeague, 
                                                order: tempLeagueOrder, //targetLeagueOrder,
                                                events: [] 
                                            };

                                            tempLeagueOrder++;
                                        }

                                        tempUpcomings[targetLeague.uid].events.push({ ...filterEvent });
                                    }

                                    break;
                                }
                            }

                            //console.log('tempUpcomings', tempUpcomings)

                            setUpcomings(() => ({ ...tempUpcomings }));

                            setIsLoadingEvents(false);
                        }
                    }
                }).catch(( error ) => {
                    setIsLoadingEvents(false);

                    modalAlert({
                        component: <>{error.message}</>,
                        title: '스포츠'
                    });
                });
            }
        }, 300);

        setSearchForms((searchForms) => ({ ...searchForms, prevKeyword: keyword }));

        return () => {
            clearTimeout(delay);

            setTargetUpcomings([]);

            setTargetEventUid('');
            setTargetEvent(null);
            setTargetMarkets([]);

            dispatch(resetCart());

            dispatch(setUpcoming({
                targetType: '',
                targetEventUid: '',
                targetEvent: {}
            }));
        };
    }, [configState, betState.targetSportUid, page, limit, keyword]);

    useEffect(() => {
        if(!betState.targetSportUid || Object.keys(upcomings).length <= 0){
            // Nothing
            setIsLoadingMarkets(false);
        }else{
            let filterUpcomings: ITargetUpcoming[] = [];

            Object.keys(upcomings).map((targetUpcomingKey, index) => {
                const targetUpcoming = upcomings[targetUpcomingKey];

                if(targetUpcoming && targetUpcoming.sport.uid === betState.targetSportUid){
                    if(!targetEventUid && targetUpcoming.order == 0){ 
                        setTargetEventUid(targetUpcoming.events[0].uid);
                        setTargetEvent({ ...targetUpcoming.events[0] });

                        dispatch(setUpcoming({
                            targetType: 'upcoming',
                            targetEventUid: targetUpcoming.events[0].uid,
                            targetEvent: targetUpcoming.events[0]
                        }))
                    }

                    if(!filterUpcomings[targetUpcoming.order])
                        filterUpcomings[targetUpcoming.order] = { ...targetUpcoming };
                }
            });

            setTargetUpcomings([ ...filterUpcomings ]);
        }
    }, [upcomings])

    useEffect(() => {
        //setIsLoadingMarkets(true);

        if(betState.targetType != 'upcoming' || !betState.targetSportUid || !betState.targetEventUid){
            // Nothing
        }else{
            BetAPI_V2.getUpcomingEvents(betState.targetSportUid, betState.targetEventUid).then(( res ) => {
                if(res.data.success){
                    const upcomingEventData = res.data.message;

                    if(upcomingEventData){
                        const { info, marketGroups, markets, columns, odds } = upcomingEventData;

                        let filterEvent: IUpcomingItem | null = null;
    
                        let home = '';
                        let away = '';

                        if(info){
                            home = (info.team_home.name_kr) ? info.team_home.name_kr : info.team_home.name;
                            away = (info.team_away.name_kr) ? info.team_away.name_kr : info.team_away.name;

                            filterEvent = {
                                uid: info.uid, 
                                key: info.key, 
                                sport: info.sport, 
                                league: info.league, 
                                time: moment.unix(info.start_time).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss'), 
                                score: info.scores, 
                                home, 
                                away, 
                                images: [ info.team_home.image_id, info.team_away.image_id ], 
                                //order: info.order
                            };
                        }

                        setTargetEvent(() => ({ ...filterEvent }));

                        let filterMarkets: any = [];
    
                        for( const marketGroupKey in marketGroups ){
                            let targetMarketGroup = marketGroups[marketGroupKey];

                            targetMarketGroup.name = targetMarketGroup.name.replaceAll('[:home]', home);
                            targetMarketGroup.name = targetMarketGroup.name.replaceAll('[:away]', away);

                            if(targetMarketGroup.name_kr){
                                targetMarketGroup.name_kr = targetMarketGroup.name_kr.replaceAll('[:home]', home);
                                targetMarketGroup.name_kr = targetMarketGroup.name_kr.replaceAll('[:away]', away);
                            }

                            if(targetMarketGroup.result_text){
                                const resultTextMatches = targetMarketGroup.result_text.matchAll(/\[:([^,]+)\]/g);
                                const resultTextArray = Array.from(resultTextMatches, (match: string[]) => match[1]);

                                targetMarketGroup.result_text = resultTextArray.join(',');
                            }

                            if(!filterMarkets[targetMarketGroup.order])
                                filterMarkets[targetMarketGroup.order] = { ...targetMarketGroup, markets: [] };

                            for( const marketKey in markets ){
                                let targetMarket = markets[marketKey];

                                if(marketGroupKey != targetMarket.uid_market_group){
                                    continue;
                                }else{
                                    if(targetMarket.name){
                                        targetMarket.name = targetMarket.name.replaceAll('[:home]', home);
                                        targetMarket.name = targetMarket.name.replaceAll('[:away]', away);
                                    }
    
                                    if(targetMarket.name_kr){
                                        targetMarket.name_kr = targetMarket.name_kr.replaceAll('[:home]', home);
                                        targetMarket.name_kr = targetMarket.name_kr.replaceAll('[:away]', away);
                                    }

                                    if(!filterMarkets[targetMarketGroup.order].markets[targetMarket.order])
                                        filterMarkets[targetMarketGroup.order].markets[targetMarket.order] = { ...targetMarket, columns: [], odds: [] };

                                    let columnsForCount = 0;
                                    let oddsForCount = 0;

                                    for( const columnKey in columns ){
                                        let targetColumn = columns[columnKey];

                                        if(marketKey != targetColumn.uid_market){
                                            continue;
                                        }else{
                                            if(!filterMarkets[targetMarketGroup.order].markets[targetMarket.order].columns[targetColumn.order]){
                                                filterMarkets[targetMarketGroup.order].markets[targetMarket.order].columns[targetColumn.order] = { ...targetColumn, odds: [] };

                                                columnsForCount++;
                                            }

                                            for( const oddsKey in odds ){
                                                let targetOdds = odds[oddsKey];

                                                if(targetOdds.name){
                                                    targetOdds.name = targetOdds.name.replaceAll('[:home]', home);
                                                    targetOdds.name = targetOdds.name.replaceAll('[:away]', away);
                                                }

                                                if(targetOdds.name_kr){
                                                    targetOdds.name_kr = targetOdds.name_kr.replaceAll('[:home]', home);
                                                    targetOdds.name_kr = targetOdds.name_kr.replaceAll('[:away]', away);
                                                }

                                                if(columnKey != targetOdds.uid_column){
                                                    continue;
                                                }else{
                                                    filterMarkets[targetMarketGroup.order].markets[targetMarket.order].columns[targetColumn.order].odds[targetOdds.order] = targetOdds;

                                                    oddsForCount++;
                                                }
                                            }
                                        }
                                    }

                                    if(columnsForCount <= 0){
                                        for( const oddsKey in odds ){
                                            let targetOdds = odds[oddsKey];

                                            if(targetOdds.name){
                                                targetOdds.name = targetOdds.name.replaceAll('[:home]', home);
                                                targetOdds.name = targetOdds.name.replaceAll('[:away]', away);
                                            }

                                            if(targetOdds.name_kr){
                                                targetOdds.name_kr = targetOdds.name_kr.replaceAll('[:home]', home);
                                                targetOdds.name_kr = targetOdds.name_kr.replaceAll('[:away]', away);
                                            }

                                            if(marketKey != targetOdds.uid_market){
                                                continue;
                                            }else{
                                                filterMarkets[targetMarketGroup.order].markets[targetMarket.order].odds[targetOdds.order] = targetOdds;

                                                oddsForCount++;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        console.log('filterMarkets', filterMarkets)

                        setTargetMarkets([ ...filterMarkets ]);
                    }
                }else{
                    modalAlert({
                        component: <>{res.data.message}</>,
                        title: '스포츠'
                    });
                }

                setIsLoadingMarkets(false);
            }).catch(( error ) => {
                setIsLoadingMarkets(false);

                modalAlert({
                    component: <>{error.message}</>,
                    title: '스포츠'
                });
            });
        }

    }, [betState.targetEventUid])

    /*
    useEffect(() => {
        setIsLoadingMarkets(true);

        if(!betState.targetEventUid){
            // Nothing
        }else{
            let existFlag = false;

            Object.keys(upcomings).map((targetUpcomingKey, index) => {
                const targetUpcoming = upcomings[targetUpcomingKey];

                if(!existFlag && targetUpcoming && targetUpcoming.events.length > 0){
                    const targetEvent = targetUpcoming.events.find((tempEvent) => tempEvent.uid == betState.targetEventUid);

                    if(targetEvent){
                        existFlag = true;

                        setTargetEvent({ ...targetEvent });
                    }
                }
            });

            if(!existFlag){
                // Nothing
            }else{
                BetAPI.getUpcomingEvents(betState.targetEventUid).then(( res ) => {
                    if(res.data.success){
                        const { info: upcomingEventInfo, odds: upcomingEventData } = res.data.message;
    
                        let filterMarketGroupKeys: string[] = [];
                        let filterMarkets: any = [];
                        let tempMarkets: any = [];

                        let home = (upcomingEventInfo.team_home.name_kr) ? upcomingEventInfo.team_home.name_kr : upcomingEventInfo.team_home.name;
                        let away = (upcomingEventInfo.team_away.name_kr) ? upcomingEventInfo.team_away.name_kr : upcomingEventInfo.team_away.name;
    
                        for( const odds of upcomingEventData ){ 
                            let { 
                                marketGroup: targetMarketGroup, 
                                market: targetMarket, 
                                multi_market: targetMultiMarket, 
                                odds_market: targetOddsMarket, 
                                ...targetOdds 
                            } = odds; 

                            if(targetOdds.name){
                                targetOdds.name = targetOdds.name.replaceAll('[:home]', home);
                                targetOdds.name = targetOdds.name.replaceAll('[:away]', away);
                            }

                            if(targetOdds.name_kr){
                                targetOdds.name_kr = targetOdds.name_kr.replaceAll('[:home]', home);
                                targetOdds.name_kr = targetOdds.name_kr.replaceAll('[:away]', away);
                            }
    
                            if(targetMarketGroup){
                                targetMarketGroup.name = targetMarketGroup.name.replaceAll('[:home]', home);
                                targetMarketGroup.name = targetMarketGroup.name.replaceAll('[:away]', away);

                                if(targetMarketGroup.name_kr){
                                    targetMarketGroup.name_kr = targetMarketGroup.name_kr.replaceAll('[:home]', home);
                                    targetMarketGroup.name_kr = targetMarketGroup.name_kr.replaceAll('[:away]', away);
                                }
    
                                let filterMarketGroupIndex = filterMarketGroupKeys.indexOf(targetMarketGroup.uid);
    
                                if(filterMarketGroupIndex < 0){
                                    filterMarketGroupKeys.push(targetMarketGroup.uid);
                                    filterMarketGroupIndex = filterMarketGroupKeys.indexOf(targetMarketGroup.uid);
                                }
    
                                if(!tempMarkets[filterMarketGroupIndex])
                                    tempMarkets[filterMarketGroupIndex] = { ...targetMarketGroup, _markets: new Map(), odds: []}
    
                                if(targetMarket){
                                    if(targetMarket.name){
                                        targetMarket.name = targetMarket.name.replaceAll('[:home]', home);
                                        targetMarket.name = targetMarket.name.replaceAll('[:away]', away);
                                    }
    
                                    if(targetMarket.name_kr){
                                        targetMarket.name_kr = targetMarket.name_kr.replaceAll('[:home]', home);
                                        targetMarket.name_kr = targetMarket.name_kr.replaceAll('[:away]', away);
                                    }

                                    if(!tempMarkets[filterMarketGroupIndex]._markets.has(targetMarket.uid))
                                        tempMarkets[filterMarketGroupIndex]._markets.set(targetMarket.uid, { ...targetMarket, odds: [] });
    
                                    tempMarkets[filterMarketGroupIndex]._markets.forEach((value: any, key: any) => {
                                        if(key == targetMarket.uid){
                                            value.odds.push(targetOdds);
    
                                            tempMarkets[filterMarketGroupIndex]._markets.set(key, value);
                                        }
                                    })
                                }else{
                                    tempMarkets[filterMarketGroupIndex].odds.push(targetOdds);
                                }
                            }
                        }
    
                        for( const tempMarket of tempMarkets ){
                            if(tempMarket._markets.size > 0){
                                let filterArray: any = tempMarket._markets.values();
    
                                filterMarkets.push({ ...tempMarket, markets: [ ...filterArray ]});
                            }else{
                                filterMarkets.push({ ...tempMarket });
                            }
                        }
    
                        //console.log('filterMarkets', filterMarkets)
    
                        setTargetMarkets([ ...filterMarkets ]);
                    }else{
                        modalAlert({
                            component: <>{res.data.message} {betState.targetEventUid}</>,
                            title: '스포츠'
                        });
                    }
    
                    setIsLoadingMarkets(false);
                }).catch(( error ) => {
                    setIsLoadingMarkets(false);
    
                    modalAlert({
                        component: <>{error.message}</>,
                        title: '스포츠'
                    });
                });
            }
        }

        return() => {}
    }, [betState.targetEventUid])
    */

    useEffect(() => {console.log('isLoadingMarkets', isLoadingMarkets)}, [isLoadingMarkets])

    return (
        <div className="upcomings">
            <div className="title">
                <div>스포츠</div>
            </div>
            <div>
                <div className="upcoming-items">
                    {isLoadingEvents && 
                        <div className="upcoming-spinner">
                            <div>
                                <Spinner type="grow" style={{ width: '3rem', height: '3rem' }} />
                                <div className="guide">경기를 불러오는 중입니다...</div>
                            </div>
                        </div>
                    }

                    {targetUpcomings.map(( targetUpcoming, key ) => {
                        if(targetUpcoming)
                            return <UpcomingItem key={key} index={key} item={targetUpcoming} />
                    })}

                    {(!isLoadingEvents && targetUpcomings.length <= 0) && 
                        <div className="upcoming-empty">
                            <div>
                                <div className="guide">이용 가능한 경기가 없습니다.</div>
                            </div>
                        </div>
                    }
                </div>

                <div className="upcoming-markets">
                    {isLoadingMarkets && 
                        <div className="upcoming-spinner">
                            <div>
                                <Spinner type="grow" style={{ width: '3rem', height: '3rem' }} />
                                <div className="guide">마켓을 불러오는 중입니다...</div>
                            </div>
                        </div>
                    }

                    {!isLoadingMarkets &&
                        <>
                            {(targetEvent && targetMarkets.length > 0) && 
                                <div className="upcoming-video">
                                    <div className="players">
                                        <div className="player">
                                            <div className="team">
                                                <div className="image" style={{backgroundImage: `url('https://assets.open-888.com/images/team/m/${targetEvent.images[0]}.png'), url('${default_image}')`}}></div>
                                                <div className="name">{targetEvent.home}</div>
                                            </div>
                                            <div className="score">{targetEvent.score[0].home}</div>
                                        </div>
                                        <div className="vs">VS</div>
                                        <div className="player">
                                            <div className="score">{targetEvent.score[0].away}</div>
                                            <div className="team">
                                                <div className="image" style={{backgroundImage: `url('https://assets.open-888.com/images/team/m/${targetEvent.images[1]}.png'), url('${default_image}')`}}></div>
                                                <div className="name">{targetEvent.away}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {targetMarkets.map((targetMarketsItem, key) => {
                                if(targetMarketsItem && targetMarketsItem.markets.length > 0)
                                    return (<UpcomingMarketsItem key={key} item={targetMarketsItem} />)
                            })}

                            {targetMarkets.length <= 0 && 
                                <div className="upcoming-empty">
                                    <div>
                                        <div className="guide">이용 가능한 마켓이 없습니다.</div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default UpcomingsView
