import React from "react";
import { useDispatch } from 'react-redux';
import {
    Modal,
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button
} from 'reactstrap';

import { hideModal, toggleModal } from "../../../redux/reducers/modalReducer";

const ConfirmModal = ({ modalVisible, component, title='', confirmText = 'OK', cancelText = 'Cancel', handleConfirm, handleClose }: any) => {

    title = (title == '') ? 'Confirm' : title;

    const dispatch = useDispatch();

    const handleHideModal = () => dispatch(hideModal());
    const handleToggleModal = () => dispatch(toggleModal());

    const onConfirm = async () => {
        if (handleConfirm) {
            await handleConfirm();
        }

        handleHideModal();
    };

    const onClose = () => {
        if (handleClose) {
            handleClose();
        }

        handleHideModal();
    };    

    return (
        <Modal isOpen={modalVisible} toggle={handleToggleModal} centered>
            <ModalHeader toggle={handleToggleModal}>{title}</ModalHeader>
            <ModalBody>
                {component}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onConfirm}>
                    {confirmText}
                </Button>
                <Button color="secondary" onClick={onClose}>
                    {cancelText}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmModal;
