import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Label, Input, Nav, NavItem, NavLink } from 'reactstrap';
import useModal from "../../hooks/GlobalModals";

interface ISearchFormProps {
    searchForms: {
        //method: string,
        type: string,
        category: string,
        keyword: string
    };
    setSearchForms: Dispatch<SetStateAction<any>>;
}

const SearchForm = (props: ISearchFormProps) => {
    const { searchForms, setSearchForms } = props;
    
    const { modalAlert } = useModal();

    const methodOptions = [
        { value: '', label: '전체' },
        { value: 'increase', label: '증액' },
        { value: 'decrease', label: '감액' }
    ]

    const typeOptions = [
        { value: '', label: '전체' },
        { value: 'amount', label: '머니' },
        { value: 'bonus', label: '보너스' }
    ]

    const categoryOptions = [
        { value: '', label: '전체' },
        { value: 'deposit', label: '입금' },
        { value: 'withdraw', label: '출금' },
        { value: 'payment', label: '지급' },
        { value: 'recovery', label: '회수' },
        { value: 'transform', label: '전환' },
        { value: 'bet', label: '베팅' }
    ]

    return (
        <div className="search-wrapper">
            <Card className="card-form-search">
                <CardBody>
                    <Row>
                        <Col md="2">
                            <Label for="type">타입</Label>
                            <Input
                                type="select"
                                name="type"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, type: e.target.value} })}
                            >
                                {typeOptions.map(( option, index ) => {
                                    return (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col md="2">
                            <Label for="category">카테고리</Label>
                            <Input
                                type="select"
                                name="category"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, category: e.target.value} })}
                            >
                                {categoryOptions.map(( option, index ) => {
                                    return (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    )
                                })}
                            </Input>
                        </Col>
                        <Col>
                            <Label for="keyword">검색어</Label>
                            <Input
                                name="keyword"
                                placeholder="검색어 ( 설명 ... )"
                                bsSize="sm"
                                onChange={(e) => setSearchForms((searchForms: any) => { return {...searchForms, keyword: e.target.value} })}
                            />
                        </Col>
                    </Row>
                    
                </CardBody>
            </Card>
        </div>
    )
}

export default SearchForm