import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/reducers/rootReducer';
import { setAuth } from '../../redux/reducers/authReducer';
import { setConfig } from '../../redux/reducers/configReducer';

import routes from '../../routes';
import useModal from "../../hooks/GlobalModals";
import useNotify from "../../hooks/GlobalNotifies";

import GlobalSpinner from  '../common/GlobalSpinner';
import GlobalModals from '../common/Modal/GlobalModals';
import GlobalPopups from '../common/Popup/GlobalPopups';
import GlobalNotifies from '../common/Notify/GlobalNotifies';

import MultiRoutes from '../common/MultiRoutes';

import { TAuth } from '../../types/Auth';
import CommonAPI from '../../modules/CommonAPI';

const Root = (props: any) => {
    const [ Initialized, setInitialized ] = useState<boolean>(false);

    const configState = useSelector((state: RootState) => state.configReducer);
    const spinnerState = useSelector((state: RootState) => state.spinnerReducer);
    const modalState = useSelector((state: RootState) => state.modalReducer);
    const popupState = useSelector((state: RootState) => state.popupReducer);
    const notifyState = useSelector((state: RootState) => state.notifyReducer);
    const betState = useSelector((state: RootState) => state.betReducer);

    const { modalAlert } = useModal();
    const { notifyDefault } = useNotify();

    const dispatch = useDispatch();

    const location = useLocation();
    const navigate = useNavigate();

    const authDefaultValues: TAuth = {
        modifire: 'PUBLIC',
        uuid: '',
        ruid: '',
        userid: '',
        name: '',
        amount: 0,
        amount_bonus: 0,
        memo_new: 0,
        expires: 0
    }

    useEffect(() => {
        //let expiresTimeout: NodeJS.Timeout | undefined = undefined;

        CommonAPI.getCommon(location.pathname).then(( res ) => {
            if(res.data.success){
                const common = res.data.message;

                console.log('common', common)

                dispatch(setAuth(common.user));

                if(common.user.memo_new > 0)
                    notifyDefault({
                        notifyKey: 'NewMemo',
                        component: <>읽지 않은 쪽지가 있습니다.</>
                    })

                let configs = common.config;

                if(common.betConfig)
                    configs = {
                        ...configs,
                        ...common.betConfig
                    }
                
                dispatch(setConfig(configs));

                /*
                const { expires } = common.user;

                if(expires > 0) 
                    expiresTimeout = setTimeout(() => {
                        SignXAPI.signOut().then(( res ) => {
                            if(!res.data.success){
                                modalAlert({
                                    component: <>{res.data.message}</>,
                                    title: 'Sign Out'
                                });
                            }else{
                                dispatch(setAuth(authDefaultValues));
                            }
                        }).catch(( error ) => {
                            modalAlert({
                                component: <>{error.message}</>,
                                title: 'Sign Out'
                            });

                            dispatch(setAuth(authDefaultValues));
                        });

                        navigate('/signx');
                    }, expires);
                */

                setInitialized(true);
            }else{
                modalAlert({
                    component: <>Unable to Initialize Application.</>,
                    title: 'Warning'
                }); 
            }
        })
        .catch(( error ) => {
            dispatch(setAuth(authDefaultValues));

            const req = error.request;
            const res = error.response;

            if (res) {
                console.log('[ ' + res.status + ' ] Error Message:', res.data.message);
            } else if (req) {
                console.log(req);
            } else {
                console.log('Error', error.message);
            }

            navigate('/', { replace: true });
        });

        //return () => { clearTimeout(expiresTimeout) }
    }, [location]);

    useEffect(() => {
        document.title = configState.site_name;
    }, [configState]);

    return (
        <>
            <GlobalSpinner visible={spinnerState} />
            <div className="wrapper">
                {modalState.modalVisible && <GlobalModals {...modalState} />}
                {popupState.popupsVisible && <GlobalPopups {...popupState} />}
                {notifyState.notifiesVisible && <GlobalNotifies {...notifyState} />}
                {Initialized && <MultiRoutes routes={routes} />}
            </div>
        </>
    );
};

export default Root