import React from "react";

import { TGlobalModals, TGlobalModalsEnum } from "../../../types/GlobalModals";

import AlertModal from "./AlertModal";
import ConfirmModal from "./ConfirmModal";
import FormModal from "./FormModal";

const MODAL_COMPONENTS: any = {
    [TGlobalModalsEnum.ALERT]: AlertModal,
    [TGlobalModalsEnum.CONFIRM]: ConfirmModal,
    [TGlobalModalsEnum.FORM]: FormModal
};

const GlobalModal = ({ modalVisible, modalType, modalProps }: TGlobalModals) => {
    const renderComponent = () => {
        if (!modalType) {
            return null;
        }

        const ModalComponent = MODAL_COMPONENTS[modalType];

        return <ModalComponent modalVisible={modalVisible} {...modalProps} />;
    };

    return <>{renderComponent()}</>;
};

export default GlobalModal;